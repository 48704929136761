import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const SubscriptionIndicators = (props) => {
  const {} = props;

  const style = {
    width: 16,
    marginRight: 5
  };

  const subscriptions = [
    {name: 'circle', class: 'text-secondary', title: 'Not Subscribed'},
    {name: 'circle-check', class: 'text-success', title: 'Valid Subscription'},
    {name: 'circle-xmark', class: 'text-danger', title: 'Expired Subscription'}
  ];

  const getRandomSubscription = () => {
    const idx = Math.floor(Math.random() * 3);
    const subscription = subscriptions[idx];
    return (<Icon title={subscription.title} name={subscription.name} className={subscription.class} style={style}/>);
  };

  return (
    <React.Fragment>
      {getRandomSubscription()}
      {getRandomSubscription()}
      {getRandomSubscription()}
    </React.Fragment>
  );
};

export default SubscriptionIndicators;
