import axios from 'axios';
import { getFormattedValue } from 'jsx/components/core/form/lib/metricFormat';
import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { connect } from 'react-redux';
import { fetchReportMetric } from '../../actions/reports';

class ReportMatrixMetricCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      lastReloadValue: null,
      metric: { id: null },
    };
  }

  componentDidMount() {
    if (!this.state.lastReloadValue) this.setState({ lastReloadValue: moment().valueOf() });
  }

  componentDidUpdate() {
    const { currentReloadValue, metricId } = this.props;
    const { fetching, lastReloadValue } = this.state;

    let reload = false;
    if (!isNil(currentReloadValue) && lastReloadValue !== currentReloadValue) {
      reload = true;
      this.setState({ lastReloadValue: currentReloadValue });
    }

    const { from_date, to_date } = this.props.enterprises.selectedRanges;
    const { local_timezone } = this.props.enterprises;
    const { isLivestockAssumptionsToggled } = this.props.livestocks;

    const params = { id: metricId, from_date, to_date, local_timezone, isLivestockAssumptionsToggled };

    if (reload && !fetching && !isNil(metricId)) {
      this.loadMetric(params);
    }
  }

  loadMetric = async (params) => {
    const { filters } = this.props;
    const { hasFiltersChanged, abortController } = this.props.reports;
  
    let metric = {};
    if (params.id && hasFiltersChanged === false) {
      this.setState({ fetching: true });

      metric = await this.props.dispatch(
        fetchReportMetric({ ...filters, ...params }, true, abortController),
      );
    }

    this.setState({ metric, fetching: false });
  };

  render() {
    const { metric, fetching } = this.state;
    const { className, colSpan } = this.props;

    const { formattedValue, numericValue } = (metric ? getFormattedValue(metric, false) : {formattedValue: '--', numericValue: 0});

    return fetching ? (
      <td className={className} colSpan={colSpan}>
        <div className="d-flex justify-content-center">
          <HalfCircleSpinner size={25} color="green" />
        </div>
      </td>
    ) : (
      <td className={className} colSpan={colSpan}>
        {numericValue === 0 ? '--' : formattedValue}
      </td>
    );
  }
}

const mapStoreToProps = ({ enterprises, livestocks, reports}) => ({
  enterprises,
  livestocks,
  reports
});

export default connect(mapStoreToProps)(ReportMatrixMetricCell);
