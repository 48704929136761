import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: false
  },
  transaction_month: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'transaction_date',
    name: 'transaction_month',
    type: 'date-month',
    caption: 'Month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  category_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'category_id',
    type: 'select',
    caption: 'Category',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  category: {
    ...controlDefaults,
    placeholder: '',
    name: 'category',
    fieldName: 'category.name',
    type: 'select',
    caption: 'Category',
    validationRules: {},
    showInEditor: false,
    showInListview: true,
    includeEmptyOption: true,
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    controlType: 'interval_date',
    linkedWith: 'transaction_date',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: false,
    showInListview: false,
    sortColumn: 'created',
  },
  description: {
    ...controlDefaults,
    placeholder: '',
    name: 'description',
    fieldName: 'description',
    type: 'text',
    caption: 'Description',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListView: true,
  },
  value: {
    ...controlDefaults,
    placeholder: '',
    name: 'value',
    type: 'number',
    caption: 'Value ($)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeDollarSign: true,
      includeCommas: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
  },
  is_propertydev: {
    ...controlDefaults,
    placeholder: '',
    name: 'is_propertydev',
    fieldName: 'is_propertydev',
    type: 'switch',
    controlType: 'switch',
    caption: 'Property Development',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    defaultValue: false,
    validationRules: { isRequired: true },
    showInListview: false,
    showInEditor: true,
  },
};
