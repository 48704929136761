/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { Alert, Label, Button } from 'reactstrap';

import { controlDefaults } from 'jsx/components/core/form/lib/validateForm';
import { fetchAnalysisPeriods } from 'jsx/components/manage/actions/analysis_periods';
import GenericLsv from 'jsx/components/core/form/components/GenericLsv';
import FormBase from 'jsx/components/core/form/components/FormBase';
import ReactSelect from 'react-select';
import SearchToolbar from 'jsx/components/core/toolbars/SearchToolbar';

import { fetchAdminAdvisors } from 'jsx/components/manage/actions/admin';
import PageTitle from 'jsx/components/core/form/components/PageTitle';
import { controls as controlsTemplate } from '../../../manage/forms/property_analysis';
import {
  fetchPropertyAnalysis,
  setPropertyAnalysisParams,
  setPropertyAnalysisSelectedAnalysisPeriod,
} from '../actions/properties';

import { fetchAttributes } from '../../portrait/actions/attributes';

import { masqueradeRequest } from '../../../core/authentication/actions';

class PropertyAnalysis extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      selectedAdvisor: null,
      advisorOptions: [],
      rcsStatusOptions: [],
      searchValue: '',
    };

    this.masquerade = this.masquerade.bind(this);
    this.onAdvisorChange = this.onAdvisorChange.bind(this);
    this.onRCSStatusChange = this.onRCSStatusChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.filtersApplied = this.filtersApplied.bind(this);
  }

  async componentDidMount() {
    const { params } = this.props.properties;
    const { controls } = this.state;

    // Fetch global analysis periods
    const analysisPeriods = await this.props.dispatch(fetchAnalysisPeriods());

    if (analysisPeriods?.count === 0) return;

    const analysisPeriodOptions = analysisPeriods.rows.map((period) => ({
      value: period.id,
      label: period.description,
    }));

    let [selectedAnalysisPeriod] = analysisPeriodOptions;
    if (params.analysis_period_id) {
      selectedAnalysisPeriod = analysisPeriodOptions.find(option => option.value === params.analysis_period_id);
    }

    await this.setPropertyAnalysisParams(
      'analysis_period_id',
      selectedAnalysisPeriod?.value ?? null,
    );
    await this.props.dispatch(setPropertyAnalysisSelectedAnalysisPeriod(selectedAnalysisPeriod));

    // Setup advisor options
    const advisors = await this.props.dispatch(fetchAdminAdvisors());
    const advisorOptions = advisors.rows.map((advisor) => ({
      id: advisor.id,
      name: advisor.name,
    }));

    // Configure filters for advisor
    controls.advisor_id.options = advisorOptions;
    controls.advisor_id.handleChange = this.onAdvisorChange;

    // Setup RCS Status options
    const rcsStatuses = await this.props.dispatch(
      fetchAttributes({ type: 'analysis_period_rcs_statuses' }),
    );
    const rcsStatusOptions = rcsStatuses.rows.map((status) => ({
      id: status.id,
      name: status.name,
    }));

    // Configure filters for advisor
    controls.haveBenchmarkSubscription.options = [{id: '1', name: 'Show Benchmark Subscriptions Only'}];
    controls.haveBenchmarkSubscription.handleChange = this.onSubscriptionChange;

    this.setState({
      analysisPeriodOptions,
      advisorOptions,
      rcsStatusOptions,
    });

    // Fetch Property Analysis data
    this.refresh();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.refresh && this.props.refresh) this.refresh();
  };

  addQuarterControls = (controls, quarters = []) => {
    const updatedControls = cloneDeep(controls);
    const { rcsStatusOptions } = this.state;
    const { params } = this.props.properties;

    if (isEmpty(quarters)) {
      updatedControls.q1 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q2 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q3 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      updatedControls.q4 = { ...controlDefaults, caption: 'N/A', showInListview: true };
      return updatedControls;
    }

    quarters.forEach((quarter, index) => {
      const { value, caption } = quarter;
      const quarterIndex = index + 1;
      const controlName = `q${quarterIndex}`;
      updatedControls[controlName] = {
        ...controlDefaults,
        value,
        fieldName: `quarters.${index.toString()}.value`,
        caption,
        classesFromRowPath: `quarters.${index.toString()}.rowClasses`,
        listviewOrder: quarterIndex,
        showInListview: true,
      };
    });

    // Configure filter for RCS status
    updatedControls.q4.options = rcsStatusOptions;
    updatedControls.q4.handleChange = this.onRCSStatusChange;
    updatedControls.q4.filterSelection = params.rcs_status_id || null;

    return updatedControls;
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onSearchChange();
  };

  onAlertDismiss = () =>
    this.props.dispatch({ type: 'SET_PROPERTY_RESPONSE_MESSAGE', payload: null });

  onSubscriptionChange = async(selectedSubscription) =>{
    await this.setPropertyAnalysisParams('show_benchmark_subscription_only', selectedSubscription.checked);
    this.refresh();
  }

  onAdvisorChange = async (selectedAdvisor) => {
    await this.setPropertyAnalysisParams('advisor_id', selectedAdvisor?.value);
    this.refresh();
  };

  onAnalysisPeriodChange = async (selectedAnalysisPeriod) => {
    await this.props.dispatch(setPropertyAnalysisSelectedAnalysisPeriod(selectedAnalysisPeriod));
    await this.setPropertyAnalysisParams(
      'analysis_period_id',
      selectedAnalysisPeriod?.value ?? null,
    );
    this.refresh();
  };

  onRCSStatusChange = async (selectedRCSStatus) => {
    await this.setPropertyAnalysisParams('rcs_status_id', selectedRCSStatus?.value ?? null);
    this.refresh();
  };

  async resetFilters() {
    await this.setPropertyAnalysisParams('rcs_status_id', null);
    await this.setPropertyAnalysisParams('advisor_id', null);
    await this.setPropertyAnalysisParams('search_value', '');
    await this.setPropertyAnalysisParams('show_benchmark_subscription_only', true);

    this.refresh();
  };

  filtersApplied() {
    const { params } = this.props.properties;
    return (!!(params.search_value !== '' || params.advisor_id || params.rcs_status_id || !params.show_benchmark_subscription_only));
  }

  onEditProperty = (id) => this.props.history.push(`/home/portrait/property/${id}`);

  onSearchChange = async () => {
    const { searchValue } = this.state;
    await this.setPropertyAnalysisParams('search_value', searchValue);
    this.refresh();
  };

  refresh = () => {
    const { selectedAnalysisPeriod } = this.props.properties.propertyAnalysis;
    const { params } = this.props.properties;
    const { controls }  = this.state;

    if (!selectedAnalysisPeriod) return;

    controls.advisor_id.filterSelection = params.advisor_id;
    controls.haveBenchmarkSubscription.filterSelection = (params.show_benchmark_subscription_only ? '1' : null);

    this.props.dispatch(
      fetchPropertyAnalysis({
        ...this.props.properties.params,
        analysis_period_id: selectedAnalysisPeriod.value,
        advisor_id: params.advisor_id ?? null,
        rcs_status_id: params.rcs_status_id ?? null,
      }),
    );

    this.setState(controls);
  };

  setPropertyAnalysisParams = async (key, value) => {
    const { params } = this.props.properties;
    await this.props.dispatch(setPropertyAnalysisParams({ ...params, [key]: value }));
  };

  async masquerade(property_id) {
    const { analysisData } = this.props.properties;

    const property = analysisData.rows.find(row => row?.id === property_id);
    const { default_masquerade_user_id } = property.project.org;

    // Abort if default masquerade id is null
    if (!default_masquerade_user_id) {
      window.alert(
        'The masquerading functionality on this page requires that you have set a Default Masquerade User in the Client profile. None is set for this Client. Aborting...'
      );

      return false;
    }

    // Confirm masquerade
    const confirmed = window.confirm(
      'Masquerading selected user and logging into their instance of the portal as if you were them. Only Administrators are able to do this. Continue?',
    );

    if (confirmed) {
      const url = await this.props.dispatch(masqueradeRequest(default_masquerade_user_id, 'client'));
      window.open(url, '_blank');
    }

    return false;
  };

  render() {
    const { analysisPeriodOptions, searchValue, controls } = this.state;
    const { analysisData, responseMessage } = this.props.properties;
    const { selectedAnalysisPeriod } = this.props.properties.propertyAnalysis;

    const title='Property Analysis';
    const iconName='gauge';
    const description='';

    const areFiltersApplied = this.filtersApplied();

    return (
      <div className="p-2">
        <div className="mt-1 ml-2">
          <Alert
            isOpen={!isNil(responseMessage)}
            color="danger"
            className="py-4 text-center"
            toggle={this.onAlertDismiss}
          >
            <strong className="align-middle">{responseMessage}</strong>
          </Alert>
          {!isNil(responseMessage) && <div className="border-corporate border-top mt-4" />}
        </div>
        <div className="mt-1">
          <div className="d-flex justify-content-between bg-light rounded border border-lightgray p-1">
            <PageTitle title={title} description={description} iconName={iconName} />
            <div className="d-flex justify-content-end m-1">
              <SearchToolbar
                rows={analysisData}
                searchValue={searchValue}
                onChange={this.onSearchChange}
                handleSearchChange={this.handleSearchChange}
                countLabel="properties"
                addNewButton={false}
                size="sm"
              />

              <Label className="font-weight-bold mr-2 ml-2 mt-2">Analysis Period</Label>
              <ReactSelect
                options={analysisPeriodOptions}
                placeholder="Select Analysis Period"
                onChange={this.onAnalysisPeriodChange}
                value={selectedAnalysisPeriod}
                size="sm"
              />

              {areFiltersApplied && (
                <Button color="danger" onClick={this.resetFilters} className="ml-2" size="sm">Reset Filters</Button>
              )}

            </div>
          </div>

          <GenericLsv
            controls={this.addQuarterControls(
              controls,
              isEmpty(analysisData.rows) ? [] : analysisData.rows[0]?.quarters ?? [],
            )}
            actions={[
              { func: (id) => this.masquerade(id), iconName: 'mask', classes: 'text-primary mr-1' },
            ]}
            emptyCaption="No properties found for this filter"
            iconName="house"
            rows={analysisData.rows}
            onClick={this.onEditProperty}
            enableStickyHeaders={false}

          />
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({ admin, analysis_periods, properties }) => ({
  admin,
  analysis_periods,
  properties,
});

export default connect(mapStoreToProps)(PropertyAnalysis);
