import { controlDefaults } from '../../../core/form/lib/validateForm';
import { columnWidths } from '../constants/listviews';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: false
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  from_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'from_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  attribute_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'attribute_id',
    type: 'select',
    caption: 'Category',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListView: false,
  },
  is_paid: {
    ...controlDefaults,
    placeholder: '',
    name: 'is_paid',
    type: 'select',
    caption: 'Group',
    options: [
      { id: 'true', name: 'Paid Labour' },
      { id: 'false', name: 'Unpaid Labour' },
    ],
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  group: {
    ...controlDefaults,
    placeholder: '',
    name: 'attribute.parent.name',
    caption: 'Group',
    validationRules: {},
    showInEditor: false,
    showInListview: true,
    listviewOrder: 0,
    width: `${columnWidths.small + 10}px`,
  },
  attribute: {
    ...controlDefaults,
    placeholder: '',
    name: 'category_placeholder',
    caption: 'Category',
    validationRules: {},
    showInEditor: false,
    showInListview: true,
    listviewOrder: 1,
  },
  total_weeks_count: {
    ...controlDefaults,
    classes: 'border-left border-right text-right',
    placeholder: '',
    name: 'total_weeks_count',
    type: 'number',
    group: 'weeks',
    caption: 'Total Number of Weeks',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    disabled: true,
    showInListview: true,
    totals: true,
    width: `${columnWidths.small}px`,
  },
  total_value: {
    ...controlDefaults,
    classes: 'border-left border-right text-right',
    placeholder: '',
    name: 'total_value',
    type: 'number',
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
      includeDollarSign: true,
    },
    group: 'value',
    caption: 'Total',
    showInEditor: false,
    showInListview: false,
    totals: true,
    width: `${columnWidths.small}px`,
  },
  fte: {
    ...controlDefaults,
    classes: 'text-right',
    placeholder: '',
    name: 'fte',
    type: 'number',
    formattingRules: {
      includeDecimals: true,
    },
    caption: 'FTE',
    showInEditor: false,
    showInListview: false,
    totals: true,
    width: `${columnWidths.icon}px`,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    disabled: false,
    validationRules: {
      isArray: true,
    },
    showInListView: false,
    showInEditor: true,
  },
  distribution_default: {
    ...controlDefaults,
    classes: 'text-right',
    placeholder: '',
    name: 'Distribution',
    type: 'number',
    caption: 'Distribution #',
    disabled: true,
    group: 'value',
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInListview: false,
    showInEditor: false,
    totals: true,
    width: `${columnWidths.small - 15}px`,
  },
};
