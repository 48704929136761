/* eslint-disable no-console */
import axios from 'axios';
import errorResponse from './errorResponse';

// Helper functions
function calculateProgress(progressEvent) {
  let value;
  let suffix;
  if (progressEvent.lengthComputable) {
    value = `${Math.round((progressEvent.loaded * 100) / progressEvent.total)}`;
    suffix = '%';
  } else {
    value = progressEvent.loaded;
    suffix = 'b';
    // Kilobytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'kb';
    }

    // Megabytes
    if (value > 1000) {
      value /= 1000;
      suffix = 'mb';
    }
  }

  value = Math.round(value * 100) / 100;
  return `${value}${suffix}`;
}

export const get = async (
  dispatch,
  prefix,
  url,
  params = null,
  headers,
  inBackground = false,
  abortController = null,
) => {
  dispatch({ type: `${prefix}_PENDING`, meta: { inBackground } });

  const signal = abortController?.signal || null;

  try {
    const options = {
      method: 'GET',
      url,
      params,
      headers: headers === null ? undefined : headers,
      signal
    };

    const response = await axios(options);

    dispatch({
      type: `${prefix}_FULFILLED`,
      payload: response.data.result ?? response.data,
      meta: { inBackground },
    });
    return response.data.result ?? response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
    } else {
      errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    }
    return null;
  }
};

export const post = async (dispatch, prefix, url, data = null, inBackground = false) => {
  dispatch({
    type: `${prefix}_PENDING`,
    meta: { inBackground },
  });
  try {
    const response = await axios({
      method: 'POST',
      url,
      data,
    });

    dispatch({
      type: `${prefix}_FULFILLED`,
      payload: response.data.result,
      meta: { inBackground },
    });

    if (response.data !== undefined && response.data.result !== undefined)
      return response.data.result;
    return true;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return false;
  }
};

export const put = async (dispatch, prefix, url, data = null, inBackground = false) => {
  dispatch({ type: `${prefix}_PENDING`, meta: { inBackground } });
  try {
    const response = await axios({
      method: 'PUT',
      url,
      data,
    });

    dispatch({
      type: `${prefix}_FULFILLED`,
      payload: response.data.result,
      meta: { inBackground },
    });

    if (response.data !== undefined && response.data.result !== undefined)
      return response.data.result;
    return true;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return false;
  }
};

export const remove = async (dispatch, prefix, url, params = null, inBackground = false) => {
  dispatch({ type: `${prefix}_PENDING`, meta: { inBackground } });
  try {
    const response = await axios({
      method: 'DELETE',
      url,
      params,
    });

    dispatch({ type: `${prefix}_FULFILLED`, payload: response });
    return true;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return false;
  }
};

export const download = async (dispatch, prefix, url, onProgressChange, params = null) => {
  dispatch({ type: `${prefix}_DOWNLOADING` });
  try {
    const file = await axios.get(url, {
      params,
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        if (onProgressChange instanceof Function)
          onProgressChange(calculateProgress(progressEvent));
      },
    });

    dispatch({ type: `${prefix}_FULFILLED` });
    return file;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return null;
  }
};

export const downloadBlob = async (dispatch, prefix, url, params = null) => {
  dispatch({ type: `${prefix}_DOWNLOADING` });
  try {
    const file = await axios.get(url, {
      params,
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
      responseType: 'blob',
    });

    dispatch({ type: `${prefix}_FULFILLED` });
    return file;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return null;
  }
};

export const upload = async (dispatch, prefix, url, data, onProgressChange) => {
  dispatch({ type: `${prefix}_PENDING` });
  try {
    const response = await axios.put(url, data, {
      onUploadProgress: (progressEvent) => {
        if (onProgressChange instanceof Function)
          onProgressChange(calculateProgress(progressEvent));
      },
    });

    dispatch({ type: `${prefix}_FULFILLED`, payload: response ?? true });
    return true;
  } catch (error) {
    errorResponse(dispatch, error, `${prefix}_REJECTED`, url);
    return false;
  }
};
