/* eslint-disable no-alert */
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Button, Alert } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from 'jsx/components/core/form/components/FormBase';

import { fetchAnalysisPeriods } from 'jsx/components/manage/actions/analysis_periods';
import { fetchAdminAdvisors } from 'jsx/components/manage/actions/admin';

import { controls as controlsTemplate } from '../../../manage/forms/property_analysis';
import AnalysisDashboardLsv from '../components/analysis_dashboard/AnalysisDashboardLsv';
import SearchToolbar from 'jsx/components/core/toolbars/SearchToolbar';
import PageTitle from 'jsx/components/core/form/components/PageTitle';
import AnalysisDashboardSettingsPopover from '../components/analysis_dashboard/AnalysisDashboardSettingsPopover';

import {
  fetchPropertyAnalysis,
  setPropertyAnalysisParams,
  setPropertyAnalysisSelectedAdvisor,
  setPropertyAnalysisSelectedAnalysisPeriod,
  setPropertyAnalysisSelectedRCSStatus,
} from '../actions/properties';

import { fetchAttributes } from '../../portrait/actions/attributes';


class AnalysisDashboard extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(controlsTemplate),
      selectedAdvisor: null,
      advisorOptions: [],
      rcsStatusOptions: [],
      searchValue: '',
      upperHeaders: [],
      headers: [],
      lockEditing: false
    };

    this.setHeaders = this.setHeaders.bind(this);
    this.onToggleColumn = this.onToggleColumn.bind(this);
    this.onToggleLock = this.onToggleLock.bind(this);
    this.onAdvisorChange = this.onAdvisorChange.bind(this);
  }

  async componentDidMount() {
    const { params } = this.props.properties;

    // Fetch global analysis periods
    const analysisPeriods = await this.props.dispatch(fetchAnalysisPeriods());

    if (analysisPeriods?.count === 0) return;

    const analysisPeriodOptions = analysisPeriods.rows.map((period) => ({
      value: period.id,
      label: period.description,
    }));

    let [selectedAnalysisPeriod] = analysisPeriodOptions;
    if (params.analysis_period_id) {
      selectedAnalysisPeriod = analysisPeriodOptions.find(option => option.value === params.analysis_period_id);
    }

    await this.setPropertyAnalysisParams(
      'analysis_period_id',
      selectedAnalysisPeriod?.value ?? null,
    );
    await this.props.dispatch(setPropertyAnalysisSelectedAnalysisPeriod(selectedAnalysisPeriod));

    // Setup advisor options
    const advisors = await this.props.dispatch(fetchAdminAdvisors());
    const advisorOptions = advisors.rows.map((advisor) => ({
      value: advisor.id,
      label: advisor.name,
    }));

    // Setup RCS Status options
    const rcsStatuses = await this.props.dispatch(
      fetchAttributes({ type: 'analysis_period_rcs_statuses' }),
    );
    const rcsStatusOptions = rcsStatuses.rows.map((status) => ({
      value: status.id,
      label: status.name,
    }));

    // Fetch client statuses
    await this.props.dispatch(
      fetchAttributes({ type: 'analysis_period_client_statuses' }),
    );

    const { upperHeaders, headers } = await this.setHeaders();

    this.setState({
      analysisPeriodOptions,
      advisorOptions,
      rcsStatusOptions,
      upperHeaders,
      headers
    });

    // Fetch Property Analysis data
    this.refresh();
  }

  refresh = () => {
    const { selectedAnalysisPeriod, selectedAdvisor, selectedRCSStatus } =
      this.props.properties.propertyAnalysis;
    if (!selectedAnalysisPeriod) return;

    this.props.dispatch(
      fetchPropertyAnalysis({
        ...this.props.properties.params,
        analysis_period_id: selectedAnalysisPeriod.value,
        advisor_id: selectedAdvisor?.value ?? null,
        rcs_status_id: selectedRCSStatus?.value ?? null,
      }),
    );
  };

  onAdvisorChange = async (selectedAdvisor) => {
    console.log(selectedAdvisor)
    await this.props.dispatch(setPropertyAnalysisSelectedAdvisor(selectedAdvisor));
    await this.setPropertyAnalysisParams('advisor_id', selectedAdvisor?.value ?? null);
    this.refresh();
  };

  setPropertyAnalysisParams = async (key, value) => {
    const { params } = this.props.properties;
    await this.props.dispatch(setPropertyAnalysisParams({ ...params, [key]: value }));
  };

  onSearchChange = async () => {
    const { searchValue } = this.state;
    await this.setPropertyAnalysisParams('search_value', searchValue);
    this.refresh();
  };

  handleSearchChange = (event) => {
    // Update search value
    const { value: searchValue } = event.target;
    this.setState({ searchValue });

    const enterButtonCode = 13;
    if (event.keyCode === enterButtonCode) this.onSearchChange();
  };

  setHeaders() {
    const { periods } = this.props.analysis_periods;
    const { analysis_period_rcs_statuses, analysis_period_client_statuses } = this.props.attributes;
    const { advisors } = this.props.admin;
    const { selectedAdvisor } = this.props.properties.propertyAnalysis;

    // Demo hack
    const subscriptionOptions = [
      {id: 1, name:'Member Portal'},
      {id: 2, name:'Analytics'},
      {id: 3, name:'Benchmarks'}
    ];

    const upperHeaders = [
      { caption: null, cells: 5, classes: 'border-bottom-0' }
    ];

    let isTemplate = true;
    let headers = [
      {caption: '-', isTemplate, columnType:'core', hide: false, field: 'masq', classes:'text-center text-primary', style: {width: 16}},
      {caption: 'Client', isTemplate, columnType:'core', hide: false, field: 'client', classes:'text-left', style: {width: 180}},
      {caption: 'Subscriptions', columnType:'core', isTemplate, hide: false, field: 'subscriptions', classes:'text-center', style: {width: 100}, options: subscriptionOptions},
      {caption: 'Property', columnType:'core', isTemplate, hide: false, field: 'name', classes:'text-left'},
      {caption: 'Address', columnType:'core', isTemplate, hide: true, field: 'address', classes:'text-left'},
      {caption: 'Advisor', columnType:'core', isTemplate, hide: false, field: 'advisor', classes:'text-left', options: advisors.rows, handleChange: this.onAdvisorChange, optionSelections: selectedAdvisor}
    ];

    // Demo hack
    let headerBgClass = 'bg-green-60';
    let cellBgClass = 'bg-green-10';

    periods.rows?.slice(0,7).map(period => {
      const columnClass = `text-center ${cellBgClass}`;
      const columnClassLeft = `${columnClass} border-left border-left-gray`;
      const columnClassRight = `${columnClass} border-right border-right-gray`;

      const quarterHeaders = [
        {caption: 'RCS Status', columnType:'quarter', isTemplate,  shortCaption: 'RS', hide: false, abbrievateCaption: false, field: 'rcs_status', title: 'RCS Status', classes: columnClassLeft, style: {paddingRight: 2, paddingLeft: 2}, options: analysis_period_rcs_statuses},
        {caption: 'Client Status', columnType:'quarter', isTemplate, shortCaption: 'CS', hide: true, abbrievateCaption: true, field: 'client_status', title: 'Client Status', classes: columnClass, style: {paddingRight: 2, paddingLeft: 2}, options: analysis_period_client_statuses},
        {caption: 'DNC', columnType:'quarter', isTemplate, shortCaption: 'DNC', hide: true, abbrievateCaption: true, field: 'dnc', title: 'Do Not Contribute to benchmarks', classes: columnClass, style: {paddingRight: 2, paddingLeft: 2}},
        {caption: 'Probe', columnType:'quarter', isTemplate, shortCaption: 'PB', hide: true, abbrievateCaption: true, field: 'probe', title: 'Probe Exist', classes: columnClass, style: {paddingRight: 2, paddingLeft: 2}},
        {caption: 'Progress', columnType:'quarter', isTemplate, shortCaption: '%', hide: true, abbrievateCaption: true, showIcon: false, field: 'completed', title: 'Percent Complete', classes: columnClass, style: {paddingRight: 2, paddingLeft: 2}},
        {caption: 'Notification', columnType:'quarter', isTemplate, shortCaption: '!!', hide: true, abbrievateCaption: true, field: 'alert', title: 'Alert', classes: columnClassRight, style: {paddingRight: 2, paddingLeft: 2}},
      ];
      headers = headers.concat(quarterHeaders);

      upperHeaders.push(
        {
          caption: period.probe_period,
          cells: quarterHeaders.filter(header => !header.hide).length,
          classes: `text-center ${headerBgClass} border border-lightgray`,
        }
      );

      headerBgClass = (headerBgClass === 'bg-green-60' ? 'bg-gray-20' : 'bg-green-60');
      cellBgClass = (cellBgClass === 'bg-green-10' ? '' : 'bg-green-10');

      // Only first quarterHeader is a template
      isTemplate = false;
    });

    return {upperHeaders, headers};
  };

  onToggleColumn(field, attribute, value) {
    const newValue = (value ? false : true);
    const { headers, upperHeaders } = this.state;

    headers.map((header, index) => {
      if (header.field === field) headers[index][attribute] = newValue;
    });

    const visibleQuarterCount = headers.filter(header => !header.hide && header.columnType === 'quarter' && header.isTemplate).length;
    upperHeaders.filter(upperHeader => upperHeader.caption !== null).map(upperHeader => upperHeader.cells = visibleQuarterCount );

    const visibleCoreCount = headers.filter(header => !header.hide && header.columnType === 'core' && header.isTemplate).length;
    upperHeaders.filter(upperHeader => upperHeader.caption === null).map(upperHeader => upperHeader.cells = visibleCoreCount );

    this.setState({headers, upperHeaders});
  }

  onToggleLock() {
    this.setState({lockEditing: !this.state.lockEditing});
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { analysisPeriodOptions, advisorOptions, rcsStatusOptions, searchValue, lockEditing, upperHeaders, headers } = this.state;
    const { analysisData, responseMessage } = this.props.properties;
    const { selectedAnalysisPeriod, selectedAdvisor, selectedRCSStatus } = this.props.properties.propertyAnalysis;

    const iconName = 'gauge';
    const title = 'Analysis Dashboard';

    return (
      <div className="p-3">
        <div className="d-flex justify-content-between">
          <PageTitle title={title} iconName={iconName} />
          <div className="d-flex justify-content-end">
            <SearchToolbar
              rows={analysisData}
              searchValue={searchValue}
              onChange={this.onSearchChange}
              handleSearchChange={this.handleSearchChange}
              countLabel="properties"
              addNewButton={false}
            />
            <Button
              // id={controlId}
              type="button"
              color="link"
              size="sm"
              onClick={this.onToggleLock}
            >
              <Icon
                // id="lo"
                as="button"
                name={(lockEditing ? 'lock-solid' : 'lock-open-solid')}
                title="Unlock editing"
                className="ml-1 mr-1 p-0"
              />
            </Button>
            <AnalysisDashboardSettingsPopover headers={headers} onToggleColumn={this.onToggleColumn} periods={analysisPeriodOptions}/>
          </div>
        </div>

        <Alert color="danger">Please note that this page is DEMO ONLY utilising randomly generated data</Alert>
        <AnalysisDashboardLsv
          rows={analysisData.rows}
          periods={analysisPeriodOptions}
          rcsStatusOptions={rcsStatusOptions}
          upperHeaders={upperHeaders}
          headers={headers}
          lockEditing={lockEditing}
        />
      </div>
    );
  }
}

const mapStoreToProps = ({ admin, analysis_periods, properties, attributes }) => ({
  admin,
  analysis_periods,
  properties,
  attributes
});

export default connect(mapStoreToProps)(AnalysisDashboard);
