import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    showInListview: true,
    caption: 'Station No',
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Weather Station',
    showInListview: true,
  },
  start: {
    ...controlDefaults,
    placeholder: '',
    name: 'start',
    fieldName: 'start',
    type: 'text',
    caption: 'Start (Year)',
    showInListview: true,
  },
  end: {
    ...controlDefaults,
    placeholder: '',
    name: 'end',
    fieldName: 'end',
    type: 'text',
    caption: 'End (Year)',
    showInListview: true,
  },
  distance_km: {
    ...controlDefaults,
    placeholder: '',
    name: 'distance_km',
    fieldName: 'distance_km',
    type: 'number',
    caption: 'Distance (km)',
    showInListview: true,
  },
  elevation_m: {
    ...controlDefaults,
    placeholder: '',
    name: 'elevation_m',
    fieldName: 'elevation_m',
    type: 'number',
    caption: 'Elevation (m)',
    showInListview: true,
  },
  years: {
    ...controlDefaults,
    placeholder: '',
    name: 'years',
    fieldName: 'years',
    type: 'number',
    caption: 'Data (Years)',
    showInListview: true,
  }
};
