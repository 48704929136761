import React from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { Col } from 'reactstrap';
import { getFormattedValue } from 'jsx/components/core/form/lib/metricFormat';
import { noop } from 'lodash';

const WidgetTile = ({
  metric,
  onEdit = noop,
  colSize,
  onClearCell = noop,
  isLoading,
  displayResyncWarning = false,
  hasFiltersChanged
}) => {
  const { formattedValue } = metric && hasFiltersChanged === false ? getFormattedValue(metric) : { formattedValue: '-' };

  // Default colours
  const useStyleProps = metric?.widget_attributes?.color && !displayResyncWarning;

  let borderStyle = {};
  let borderClassName = '';
  const baseBottomProps = {
    className: 'p-1 text-white',
    style: {},
  };
  let bottomProps = {
    ...baseBottomProps,
  };
  let widgetColour = 'green';

  if (useStyleProps) {
    widgetColour = metric.widget_attributes.color;
    borderStyle = {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: widgetColour,
      borderRadius: '5px',
    };
    bottomProps = {
      ...bottomProps,
      style: {
        backgroundColor: widgetColour,
      },
    };
  } else {
    if (displayResyncWarning) widgetColour = 'warning';
    borderClassName = 'border border-warning border-radius-5';
    bottomProps = {
      ...bottomProps,
      className: `${baseBottomProps.className} bg-${widgetColour}`,
    };
  }

  const displayValue = isLoading ? (
    <HalfCircleSpinner size={100} color={widgetColour} className="my-3" />
  ) : (
    <h2 className="p-1">{formattedValue}</h2>
  );

  /**
   * Handle a failed metric - return a widget to indicate an error
   */
  if (metric?.data?.errorMessage) {
    widgetColour = 'red';
    borderClassName = 'border border-danger border-radius-5';
    borderStyle = {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: widgetColour,
      borderRadius: '5px',
    };
    bottomProps = {
      ...bottomProps,
      className: `${baseBottomProps.className} bg-${widgetColour}`,
    };
    return (
      <Col sm={colSize} className="p-1 text-center">
        <div className={borderClassName} style={borderStyle}>
          <div className="d-flex justify-content-center">
            <Icon
              name="triangle-exclamation-solid"
              className="ml-1 text-danger"
              title="Error generating metric!"
            />
            {metric?.data?.errorMessage}
          </div>
          <div {...bottomProps}></div>
        </div>
      </Col>
    );
  }

  return (
    <Col sm={colSize} className="p-1 text-center">
      <div className={borderClassName} style={borderStyle}>
        <Icon
          name="gear"
          className="mr-1"
          style={{ position: 'absolute', top: 10, right: 7 }}
          onClick={() => onEdit(metric.id)}
        />
        {displayResyncWarning && (
          <Icon
            name="triangle-exclamation-solid"
            className="ml-1 text-warning"
            style={{ position: 'absolute', top: 10, left: 7 }}
            title="Apply filters to update metric value"
          />
        )}

        {onClearCell && (
          <Icon
            name="trash"
            className="mr-1 text-danger"
            style={{ position: 'absolute', top: 10, right: 37 }}
            onClick={onClearCell}
          />
        )}
        <div className="d-flex justify-content-center">{displayValue}</div>
        <div {...bottomProps}>{metric?.name}</div>
      </div>
    </Col>
  );
};

export default WidgetTile;
