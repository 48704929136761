import { controlDefaults } from "../../../core/form/lib/validateForm";

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  first_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'first_name',
    fieldName: 'first_name',
    type: 'text',
    caption: 'First Name',
    validationRules: {},
    showInEditor: true
  },
  last_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'last_name',
    fieldName: 'last_name',
    type: 'text',
    caption: 'Last Name',
    validationRules: {},
    showInEditor: true
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Full Name',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    showInEditor: true,
    showInListview: true
  },
  email: {
    ...controlDefaults,
    placeholder: '',
    name: 'email',
    fieldName: 'email',
    type: 'text',
    caption: 'Email',
    validationRules: {
      minLength: 2,
      isRequired: true
    },
    showInEditor: true,
    showInListview: true
  },
  mobile: {
    ...controlDefaults,
    placeholder: '',
    name: 'mobile',
    fieldName: 'mobile',
    type: 'text',
    caption: 'Mobile',
    validationRules: {
      isRequired: true
    },
    showInEditor: true,
    showInListview: true
  }
};
