import { connect } from 'react-redux';
import {
  ComposedChart,
  Bar,
  Line,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { CustomInput, Label } from 'reactstrap';
import FormBase from '../../../core/form/components/FormBase';
import GenericLsv from '../../../core/form/components/GenericLsv';

class PropertyRainfallsBom extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      errorMessage: null,
      months: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
    };
  }

  // eslint-disable-next-line class-methods-use-this
  customisedLabel({x, y, stroke, value}) {
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {(value ? value.toFixed(0) : null)}
      </text>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  rollingRainfall(bom_rainfall) {
    bom_rainfall.map((rainfall, index) => {
      if (index + 12 < bom_rainfall.length) {
        const year_rainfall = bom_rainfall.slice(index, index + 12);
        const year_total = year_rainfall.reduce((accumulator, month) => accumulator + month.rainfall_mm, 0);

        // eslint-disable-next-line no-param-reassign
        bom_rainfall[index + 12].rolling_year_mm = year_total;
      } 
    });

    return bom_rainfall;
  }

  render() {
    const { weather_stations } = this.props.warehouse;
    const { months } = this.state;

    let bom_rainfall = [];

    const iconName = 'draw-polygon';

    let chart_title = '';
    if (weather_stations.length > 0) {
      chart_title = `${weather_stations[0].name} (${weather_stations[0].id}) Weather Station`;
      weather_stations[0].data.map(station_data => {
        bom_rainfall.push({name: `${station_data.year}-${months[station_data.month-1]}`, rainfall_mm: station_data.rainfall_mm});
      });
    } 

    bom_rainfall = this.rollingRainfall(bom_rainfall);
    const chartFontSize = 13;

    return (
      <>
        <div style={{height: 400, width: '100%', marginBottom: 40}}>
          <div className="d-flex justify-content-between">
            <div className="text-center mt-2 font-bolder">{chart_title}</div>
            <div className="m-1 p-1 d-flex justify-content-end">
              <Label className="m-1">Show Map</Label>
              <CustomInput
                type="switch"
                role="switch"
                id={'showMap'}
                name={'showMap'}
                checked={this.props.showMap}
                onChange={this.props.onShowMapChange}
                className="m-1"
              />

              {this.props.showMap && (
                <>
                  <Label className="m-1">Show Stations on Map</Label>
                  <CustomInput
                    type="switch"
                    role="switch"
                    id={'showOnMap'}
                    name={'showOnMap'}
                    checked={this.props.showStationsOnMap}
                    onChange={this.props.onShowStationsOnMapChange}
                    className="m-1"
                  />
                </>
              )}
            </div>
          </div>

          <ResponsiveContainer width="100%" height="95%" minWidth={400} minHeight={300}>
            <ComposedChart
              // width={500}
              height={300}
              data={bom_rainfall}
              margin={{
                  top: 5,
                  right: 20,
                  left: 20,
                  bottom: 5,
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" style={{fontSize: chartFontSize}}/>

              <YAxis style={{fontSize: chartFontSize}} type="number" label={{ value: 'Rainfall (mm)', angle: -90, position: 'left', offset: 10}} unit="mm"/>
              <YAxis yAxisId="right" style={{fontSize: chartFontSize}} type="number" orientation="right" label={{ value: 'Rolling Year (mm)', angle: 90, position: 'right', offset: 10 }}  unit="mm"/>

              <Tooltip unit="mm"/>

              <ReferenceLine y={0} stroke="#000" />
              <Brush dataKey="name" height={30} stroke="#8884d8" />

              <Bar dataKey="rainfall_mm" fill="#8884d8" />
              <Line yAxisId="right" type="monotone" dataKey="rolling_year_mm" fill="orange" label={this.customisedLabel}/>

            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <GenericLsv
          controls={this.props.stationControls}
          iconName={iconName}
          emptyCaption="No BOM stations found"
          onClick={() => {}}
          rows={weather_stations}
        />
        <p><b>Concept Note:</b> 
        <br/>* Weather station locations, metadata and rainfall is sourced from BOM site. Over 19,000 stations available (some closed) with many years worth of data on each. 
        <br/>* Limited functionality in the concept for the sake of quick visualisation. This concept is retricted to showing just Stuart Creek's paddocks and nearby weather stations. 
        <br/>* Weather data loading for Springdale station only.
        <br/>* Intention is to be able to click on map or listview to load chart with station data.
        {/* <br/>* Could we use this data combined with stock allocated to enterprise hectares to calcuate and visualise LSU Stock days Per Hectare per 100mm of rainfall? or would we need to introduce property paddocks for that to work? */}
        </p>

      </>
    );
  }
}

const mapStoreToProps = (store) => ({
  warehouse: store.warehouse,
});

export default connect(mapStoreToProps)(PropertyRainfallsBom);
