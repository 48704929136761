import { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Input} from 'reactstrap';
// import ColumnFilterModal from './ColumnFilterModal';

const ColumnHeader = (props) => {
  const { header } = props;

  const [selections, setSelections] = useState(null);
  const [iconName, setIconName] = useState('');
  // const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // setIsFilterModalOpen((prevState) => !prevState);
    setDropdownOpen((prevState) => !prevState);
  };

  const className = (selections ? 'text-corporate d-flex' : '');

  const handleChange = (event) => {
    if (header.handleChange) {
      const label = event.target.name;
      const value = event.target.id;
      const {checked} = event.target;

      header.handleChange({label, value, checked});

      const newValue = (checked ? value : null);
      setSelections(newValue);
    }
  };

  const renderOptions = () => header.options.map((option, index) => {
    const abbreviatedCaption = option?.name?.split(' ').reduce((accumulator, substring) => accumulator + substring[0], '');
    const name = `${option.name} (${abbreviatedCaption})`;
    const checked = (selections === option.id);

    return (
      <DropdownItem key={index} >
        <input className="mr-2" type="checkbox" onChange={handleChange} id={option.id} name={option.name} checked={checked}/>
        <span>{option.name}</span>
      </DropdownItem>
    );
  });

  if (selections !== header.filterSelection) setSelections(header.filterSelection);

  return (
    <>
      {/* {header?.options?.length > 0 && (
        <ColumnFilterModal
          toggle={toggle}
          isOpen={isFilterModalOpen}
          header={header}
          selections={selections}
          title="Filter Advisor"
          handleChange={handleChange}
        />
      )} */}

      {header?.options?.length > 0 && dropdownOpen && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="nav-link p-0"
            tag="div"
          >
            {header.shortCaption || header.caption}
            {iconName && (
              <Icon name={iconName} className="text-primary" style={{margin: 3}} onClick={handleChange} />
            )}
          </DropdownToggle>

          <DropdownMenu className="bg-lightgray">
            {header?.options?.length > 1 && (
              <>
                <DropdownItem key={-1}><input className="mr-2" type="checkbox" checked={!selections} onChange={handleChange}/>Show ALL</DropdownItem>
                <DropdownItem divider className="border-bottom border-corporate"/>
              </>
            )}

            {renderOptions(selections)}
          </DropdownMenu>
        </Dropdown>
      )}

      {(!header?.options || !dropdownOpen) && (
        <div className={className} style={header?.style} onContextMenu={toggle}>
          {header.shortCaption || header.caption}
          {selections && (
            <Icon name="filter-solid" className="text-success" style={{margin: 3, marginLeft: 4}}/>
          )}
        </div>
      )}
    </>
  );
};

export default ColumnHeader;
