import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import SubscriptionRow from '../clients/SubscriptionRow';

const AnalysisDashboardSubscriptionModal = (props) => {
  const {
    toggle,
    modal = false
  } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={false}
      fade={false}
      size="sm"
      trapFocus={false}
      // style={{maxWidth: 400}}
      // centered
  >
    <ModalHeader toggle={toggle}>Subscription Settings</ModalHeader>
    <ModalBody>
      <Table>
        <thead>
          <tr>
            <th className="w-200px">Subscription</th>
            <th className="w-100px">Enabled</th>
            <th className="w-100px">Expires</th>
            <th className="w-200px">On</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <SubscriptionRow
            type="Member Portal"
            caption="Member Portal"
            // orgId={orgId}
            recordUpdated={() => {}}
          />
          <SubscriptionRow
            type="Analytics"
            caption="Analytics"
            // orgId={orgId}
            recordUpdated={() => {}}
          />
          <SubscriptionRow
            type="Benchmarks"
            caption="Benchmarks"
            // orgId={orgId}
            recordUpdated={() => {}}
          />
        </tbody>
      </Table>
    </ModalBody>
    <ModalFooter className="d-flex justify-content-center">
      <Button color="primary" onClick={toggle} size="sm">
        Save Change
      </Button>{' '}
      <Button color="secondary" onClick={toggle} size="sm">
        Close
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default AnalysisDashboardSubscriptionModal;
