import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: false,
  },
  group_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'group_id',
    type: 'select',
    caption: 'Asset Group',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true,
  },
  asset_type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'asset_type_id',
    fieldName: 'asset_type_id',
    caption: 'Asset Type',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  name: {
    ...controlDefaults,
    name: 'name',
    fieldName: 'name',
    caption: 'Asset Name',
    type: 'text',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    caption: 'Distributions',
    type: 'json',
    validationRules: {
      isArray: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  distribution_default: {
    ...controlDefaults,
    placeholder: '',
    name: 'Distribution',
    fieldName: '',
    type: 'number',
    caption: 'Distribution #',
    disabled: true,
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInListview: false,
    showInEditor: false,
    classes: 'text-center',
  },
};
