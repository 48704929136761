
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    probeRows: [],
    probeGroups: [],
    probePropertyRows: [],
    probeGroupRows: [],
    probes: [],
    groups: [],
    groupsMinMax: [],
    userProbes: [],
    metrics: [],
    eligibles: [],
    distinct_properties: [],
    filters: {
      property_id: [],
      period_id: [],
      metric_id: [],
      group_id: []
    },
    weather_stations: [
      {id: '043008', name: 'Springdale Tm', lng: 148.6644, lat: -26.1683, elevation_m: 400, start: 2000, end: null, years: 23.5, distance_km: 25.11, data:[
        {year: 2020, month: 1, rainfall_mm: 79.0},
        {year: 2020, month: 2, rainfall_mm: 189.0},
        {year: 2020, month: 3, rainfall_mm: 42.4},
        {year: 2020, month: 4, rainfall_mm: 8.6},
        {year: 2020, month: 5, rainfall_mm: 15.2},
        {year: 2020, month: 6, rainfall_mm: 27.4},
        {year: 2020, month: 7, rainfall_mm: 6.6},
        {year: 2020, month: 8, rainfall_mm: 25.0},
        {year: 2020, month: 9, rainfall_mm: 14.4},
        {year: 2020, month: 10, rainfall_mm: 26.4},
        {year: 2020, month: 11, rainfall_mm: 0.2},
        {year: 2020, month: 12, rainfall_mm: 48.0},
        {year: 2021, month: 1, rainfall_mm: 65.5},
        {year: 2021, month: 2, rainfall_mm: 0},
        {year: 2021, month: 3, rainfall_mm: 117.8},
        {year: 2021, month: 4, rainfall_mm: 0},
        {year: 2021, month: 5, rainfall_mm: 0},
        {year: 2021, month: 6, rainfall_mm: 0},
        {year: 2021, month: 7, rainfall_mm: 0},
        {year: 2021, month: 8, rainfall_mm: 0},
        {year: 2021, month: 9, rainfall_mm: 0},
        {year: 2021, month: 10, rainfall_mm: 0},
        {year: 2021, month: 11, rainfall_mm: 0},
        {year: 2021, month: 12, rainfall_mm: 0},
        {year: 2022, month: 1, rainfall_mm: 0},
        {year: 2022, month: 2, rainfall_mm: 0},
        {year: 2022, month: 3, rainfall_mm: 62.8},
        {year: 2022, month: 4, rainfall_mm: 32.4},
        {year: 2022, month: 5, rainfall_mm: 66.4},
        {year: 2022, month: 6, rainfall_mm: 15.0},
        {year: 2022, month: 7, rainfall_mm: 31.6},
        {year: 2022, month: 8, rainfall_mm: 25.8},
        {year: 2022, month: 9, rainfall_mm: 86.0},
        {year: 2022, month: 10, rainfall_mm: 105.4},
        {year: 2022, month: 11, rainfall_mm: 66.0},
        {year: 2022, month: 12, rainfall_mm: 28.0},
        {year: 2023, month: 1, rainfall_mm: 43.2},
        {year: 2023, month: 2, rainfall_mm: 6.0},
        {year: 2023, month: 3, rainfall_mm: 32.2},
        {year: 2023, month: 4, rainfall_mm: 21.0},
        {year: 2023, month: 5, rainfall_mm: 12.8},
        {year: 2023, month: 6, rainfall_mm: 0},
        {year: 2023, month: 7, rainfall_mm: 44.4},
        {year: 2023, month: 8, rainfall_mm: 0},
        {year: 2023, month: 9, rainfall_mm: 0},
        {year: 2023, month: 10, rainfall_mm: 2.0},
        {year: 2023, month: 11, rainfall_mm: 71.2},
        {year: 2023, month: 12, rainfall_mm: 115.8},
        {year: 2024, month: 1, rainfall_mm: 125.4},
        {year: 2024, month: 2, rainfall_mm: 103.2},
        {year: 2024, month: 3, rainfall_mm: 113.6},
        {year: 2024, month: 4, rainfall_mm: 49.4},
        {year: 2024, month: 5, rainfall_mm: 5.2},
        {year: 2024, month: 6, rainfall_mm: 18.4},
        {year: 2024, month: 7, rainfall_mm: 9.4},
        {year: 2024, month: 8, rainfall_mm: 26.2},
        {year: 2024, month: 9, rainfall_mm: 10.8},
        {year: 2024, month: 10, rainfall_mm: 31.6},
        {year: 2024, month: 11, rainfall_mm: 167.4},
        {year: 2024, month: 12, rainfall_mm: 39.4},
        {year: 2025, month: 1, rainfall_mm: 44.8},
        {year: 2025, month: 2, rainfall_mm: 92.4},
      ]},
      {id: '043108', name: 'Bendoba Tm', lng: 148.3867, lat: -25.96, elevation_m: 470, start: 2000, end: null, years: 23.3, distance_km: 38.39, data:[
        {year: 2025, month: 1, rainfall_mm: 44.8},
        {year: 2025, month: 2, rainfall_mm: 92.4},
        {year: 2024, month: 1, rainfall_mm: 125.4},
        {year: 2024, month: 2, rainfall_mm: 103.2},
        {year: 2024, month: 3, rainfall_mm: 113.6},
        {year: 2024, month: 4, rainfall_mm: 49.4},
        {year: 2024, month: 5, rainfall_mm: 5.2},
        {year: 2024, month: 6, rainfall_mm: 18.4},
        {year: 2024, month: 7, rainfall_mm: 9.4},
        {year: 2024, month: 8, rainfall_mm: 26.2},
        {year: 2024, month: 9, rainfall_mm: 10.8},
        {year: 2024, month: 10, rainfall_mm: 31.6},
        {year: 2024, month: 11, rainfall_mm: 167.4},
        {year: 2024, month: 12, rainfall_mm: 39.4},
        {year: 2023, month: 1, rainfall_mm: 43.2},
        {year: 2023, month: 2, rainfall_mm: 6.0},
        {year: 2023, month: 3, rainfall_mm: 32.2},
        {year: 2023, month: 4, rainfall_mm: 21.0},
        {year: 2023, month: 5, rainfall_mm: 12.8},
        {year: 2023, month: 6, rainfall_mm: 0},
        {year: 2023, month: 7, rainfall_mm: 44.4},
        {year: 2023, month: 8, rainfall_mm: 0},
        {year: 2023, month: 9, rainfall_mm: 0},
        {year: 2023, month: 10, rainfall_mm: 2.0},
        {year: 2023, month: 11, rainfall_mm: 71.2},
        {year: 2023, month: 12, rainfall_mm: 115.8},
      ]},
      {id: '043033', name: 'Tindara Tm', lng: 148.8047, lat: -25.4689, elevation_m: 350, start: 2000, end: null, years: 22.3, distance_km: 38.96, data:[]},
      {id: '043091', name: 'Roma Airport', lng: 148.7710, lat: -26.5477, elevation_m: 307, start: 1985, end: null, years: 39.6, distance_km: 41.22, data:[]},
      {id: '043093', name: 'Waverley Downs', lng: 148.5372, lat: -26.6064, elevation_m: 335, start: 1950, end: null, years: 69.8, distance_km: 34.65, data:[]},
    ]
  },
  action,
) {
  const types = ['SCHEDULE', 'WAREHOUSE', 'BATCH', 'LOG'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };
  
  switch (action.type) {
    case 'FETCH_WAREHOUSE_DISTINCT_PROPERTIES_FULFILLED': {
      return {
        ...state,
        distinct_properties: action.payload,
        fetching: false
      };
    }
    case 'FETCH_WAREHOUSE_ELIGIBLE_PROPERTIES_FULFILLED': {
      return {
        ...state,
        eligibles: action.payload,
        fetching: false
      };
    }
  
    case 'FETCH_WAREHOUSE_METRICS_FULFILLED': {
      return {
        ...state,
        metrics: action.payload,
      };
    }

    case 'FETCH_WAREHOUSE_USER_PROBES_FULFILLED': {
      return {
        ...state,
        userProbes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBES_FULFILLED': {
      return {
        ...state,
        probes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROPERTY_FULFILLED': {
      return {
        ...state,
        probeRows: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBE_PROPERTY_FULFILLED': {
      return {
        ...state,
        probePropertyRows: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUPS_MINMAX_FULFILLED': {
      return {
        ...state,
        groupsMinMax: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUPS_FULFILLED': {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUP_FULFILLED': {
      return {
        ...state,
        probeGroups: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBE_GROUP_FULFILLED': {
      return {
        ...state,
        probeGroupRows: action.payload,
      };
    }
    case 'SET_WAREHOUSE_FILTER_FULFILLED': {
      return {
        ...state,
        filters: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
