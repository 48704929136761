import { Button, PopoverHeader, UncontrolledPopover, PopoverBody, Row, Col } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Pill from 'jsx/components/core/form/components/Pill';

import AnalysisDashboardSettingsColumnLsv from './AnalysisDashboardSettingsColumnLsv';

const AnalysisDashboardSettingsPopover = (props) => {
  const {
    headers = [],
    periods = [],
    onToggleColumn
  } = props;

  const controlId = `settings_popover`;

  const renderPeriods = () => {
    return periods.slice(0,7).map(period => (
      <Col sm={3}>
        <Pill caption={period.label} size="md" classes="bg-success m-2" onDelete={() => {}}/>
      </Col>));
  }

  return (
    <div>
      <Button
        id={controlId}
        type="button"
        color="link"
        size="sm"
      >
        <Icon
          id="gear"
          as="button"
          name="gear"
          title="Settings"
          className="ml-1 mr-1 p-0"
        />
      </Button>

      <UncontrolledPopover
        placement="bottom"
        target={controlId}
        trigger="legacy"
        className="bg-white"
      >
        <PopoverHeader className="bg-green-20">Settings</PopoverHeader>
        <PopoverBody className="bg-gray-20" >
          <div className="mt-2 border-bottom border-green d-flex justify-content-between">
            <div><small>Core Columns</small></div>
          </div>
          <AnalysisDashboardSettingsColumnLsv rows={headers} onToggleColumn={onToggleColumn} columnType='core'/>

          <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
            <small>Quarter Columns</small>
          </div>
          <AnalysisDashboardSettingsColumnLsv rows={headers} onToggleColumn={onToggleColumn} columnType='quarter'/>

          <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
            <small>Quarter Periods</small>
            <small>+</small>
          </div>
          <Row className="" >
            {renderPeriods()}
          </Row>
          {/* <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
            <small>Time</small>
            <small>+</small>
          </div> */}
          {/* <div className="mt-2 border-bottom border-green d-flex justify-content-between text-green">
            <small>Notes</small>
            <small>+</small>
          </div> */}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default AnalysisDashboardSettingsPopover;
