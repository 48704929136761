import React from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import { Button } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from '../../../../core/form/components/Listview';
import AdminScheduledJobLogsLsv from './AdminScheduleJobLogsLsv';
import { HollowDotsSpinner } from 'react-epic-spinners';

const AdminScheduledJobsLsv = ({ rows, logs, onClick = noop, selectedRow = {} }) => {
  const renderRows = (headers, record) => {
    let extraClass = '';

    const rootmetrics = `${record?.stats?.rootmetrics?.completed || 0} of ${record?.stats?.rootmetrics?.total || 0}`;
    const submetrics = `${record?.stats?.submetrics?.completed || 0} of ${record?.stats?.submetrics?.total || 0}`;

    const stats = record?.stats || {};
    stats.updated_difference = moment().diff(record.updated);
    if (stats && stats.status === 'InProgress' && stats.updated_difference > (60000 * 10) ) stats.status = 'Stale';

    let status = <div>{stats.status}</div>;
    let statusTitle = '';
    switch (stats?.status) {
      case 'InProgress': 
        extraClass = 'text-primary';
        status = <HollowDotsSpinner className="text-center align-self-center m-2" color="#4285f4" size={8} />;
        statusTitle = 'Task in progress.';
        break;
      case 'Completed': 
        extraClass = 'text-success';
        break;
      case 'Stale': 
        extraClass = 'text-gray';
        break;
      case 'Failed': 
      case 'Terminated': 
        extraClass = 'text-danger';
        break;
      default: break;
    }

    const duration = (record?.stats?.duration ? `${(record?.stats?.duration / 1000 / 60).toFixed(0)}m` : '-');

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'updated': {
          return (
            <td key={index} className={`${classes} ${extraClass}`} data-sort-value={record[sortColumn]}>
              {moment(record.updated).format('YYYY-MM-DD HH:mm') }
            </td>
          );
        }
        case 'created': {
          return (
            <td key={index} className={`${classes} ${extraClass}`} data-sort-value={record[sortColumn]}>
              {moment(record.created).format('YYYY-MM-DD HH:mm') }
            </td>
          );
        }
        case 'rootmetrics': {
          return (<td key={index} className={`${classes} ${extraClass}`} >{rootmetrics}</td>);
        }
        case 'submetrics': {
          return (<td key={index} className={`${classes} ${extraClass}`} >{submetrics}</td>);
        }
        case 'status': {
          return (<td key={index} className={`${classes} ${extraClass}`} >{status}</td>);
        }
        case 'duration': {
          return (<td key={index} className={`${classes} ${extraClass}`} >{duration}</td>);
        }
        case 'period': {
          return (<td key={index} className={`${classes} ${extraClass}`} >{record?.period?.probe_period}</td>);
        }
        default:
          return (
            <td key={index} className={`${classes} ${extraClass}`} style={styles}>{record[field]}</td>
          );
      }
    });
    return tableTd;
  };

  const iconName = 'timer';
  const emptyCaption = 'No Logs found';

  const headers = [
    { caption: 'Created', field: 'created', classes: 'text-left', styles: {width: '120px' }},
    { caption: 'Task', field: 'name', classes: 'text-left' },
    { caption: 'Probe Period', field: 'period', classes: 'text-left', styles: {width: '130px' }},

    { caption: 'Root Metrics Completed', field: 'rootmetrics', classes: 'text-center', styles: {width: '130px' }},
    { caption: 'Submetrics Completed', field: 'submetrics', classes: 'text-center', styles: {width: '130px' }},
    { caption: 'Status', field: 'status', classes: 'text-left', styles: {width: '100px' }},

    { caption: 'Duration', field: 'duration', classes: 'text-right', styles: {width: '100px' }},
    { caption: 'Updated', field: 'updated', classes: 'text-right', styles: {width: '120px' }},
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row, index) => {
      const selectedClass = (row.id === selectedRow.id ? 'bg-green-40': '');
      return (
        <React.Fragment key={index}>
          <tr style={{ cursor: 'pointer' }} onClick={() => onClick(row)} className={selectedClass}>
            {renderRows(headers, row)}
          </tr>
          {selectedClass && (
            <tr >
              <td colSpan={headers.length} className="p-0">
                <AdminScheduledJobLogsLsv rows={logs} darkTable/>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      enableStickyHeaders
    />
  );
};

export default AdminScheduledJobsLsv;
