import React from 'react';
import moment from 'moment';
import Icon from 'jsx/components/core/icons/Icon';
import Listview from "../../../core/form/components/Listview";

import DirectDownload from '../../../core/form/components/DirectDownload';

const LabRegisterRecordDocumentsLsv = (props) => {
  let {
    rows,
    checkAccess,
    handleDownload,
    handleDelete
  } = props;

  const renderRows = (headers, document) => {
    let rowColour = "text-success"

    const tableTd = headers.map((header, index) => {
      const classes = `${rowColour} ${header.classes}`;
      switch (header.field) {
        case "download": return (
          <td key={index} className={header.classes} style={{ cursor: 'pointer' }}>
            <DirectDownload
              classNames="p-0"
              buttonType="primary"
              handleDownload={(onProgressChange) => handleDownload(document.id, onProgressChange)}
              showLabel={true}
              showIcon={true}
            />
          </td>
        )
        case 'created': return (
          <td key={index} className={classes}>{moment.utc(document.created).local().fromNow()}</td>
        )
        case 'remove': return (
          <td key={index} className={classes} onClick={() => handleDelete(document.id)} >{checkAccess('labRecordDelete') && (<Icon name="trash" className="text-danger mt-1" />)}</td>
        )
        case 'file_type': return (
          <td key={index} className={classes}><Icon name={checkFileType(document.file_type)}/></td>
        )
        default: return (
          <td key={index} className={classes}>{document[header.field]}</td>
        )
      }
    });

    return tableTd;
  };

  const checkFileType = (file_type) => {
    switch (true) {
      case /^image/.test(file_type): return 'file-image';
      case /^video/.test(file_type): return 'file-video';
      case /^audio/.test(file_type): return 'file-video';
      case /excel/.test(file_type): return 'file-excel';
      case /word/.test(file_type): return 'file-word';
      case /pdf$/.test(file_type): return 'file-pdf';
      case /octet-stream$/.test(file_type): return 'file-code';
      default: return 'file';
    }
  }

  const headers = [
    { caption: 'Type', field: 'file_type', classes: 'text-center' },
    { caption: 'Name', field: 'key', classes: 'text-left' },
    { caption: 'Download', field: 'download', classes: 'text-center' },
    { caption: 'Uploaded', field: 'created', classes: 'text-center' },
    { caption: '', field: 'remove', classes: 'text-center' },
  ]

  const tableHeadTh = headers.map((header, index) =>
    <th key={index} className={header.classes}>{header.caption}</th>
  )

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  const iconName = 'flask';
  const emptyCaption = 'No documents found';

  return (
    <React.Fragment>
      <Listview rows={rows} tableHeadTh={tableHeadTh} tableBodyTr={tableBodyTr} iconName={iconName} emptyCaption={emptyCaption} />
    </React.Fragment>
  );
}

export default LabRegisterRecordDocumentsLsv;
