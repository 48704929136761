import { noop } from 'lodash';
import Listview from 'jsx/components/core/form/components/Listview';

const AnalysisDashboardSettingsColumnLsv = ({ rows, onToggleColumn = noop, columnType = null, onClick = noop, updateField = noop, allowColourEdit = true }) => {
  const renderRows = (headers, record) => {
    if (!record.isTemplate || columnType !== record.columnType) return null;

    const tableTd = headers.map(({ caption, field, classes, sortColumn, style }, index) => {
      switch (field) {
        case 'hide':
        case 'abbrievateCaption':
        case 'showIcon':
          return (
            <td className={`${classes} ${(record[field] ? 'text-success' : 'text-danger')}`} style={style} key={index} onClick={() => onToggleColumn(record.field, field, record[field])}>{record[field] ? 'Yes' : 'No'}</td>
          );
        default:
          return (
            <td key={index} className={classes} data-sort-value={record[sortColumn]}>
              {record[field]}
            </td>
          );
      }
    });

    return tableTd;
  };

  const iconName = 'chart-line';
  const emptyCaption = 'No Columns defined';

  const headers = [
    { caption: 'Column', field: 'caption', classes: 'text-left' },
    { caption: 'Hide Field', field: 'hide', classes: 'text-center' },
    { caption: 'Abbreivate Caption', field: 'abbrievateCaption', classes: 'text-center' },
    { caption: 'Show as Icon', field: 'showIcon', classes: 'text-center' },
  ];

  const tableHeadTh = headers.map(({ caption, classes }, index) => (
    <th key={index} className={classes}>
      {caption}
    </th>
  ));

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row) => (
      <tr key={row.id} onClick={() => onClick(row.id)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
      enableResizer={false}
      sortType="None"
    />
  );
};

export default AnalysisDashboardSettingsColumnLsv;
