import { columnWidths } from 'jsx/components/modules/portrait/constants/listviews';
import { controlDefaults } from '../../core/form/lib/validateForm';

export const controls = {
  org_id: {
    ...controlDefaults,
    name: 'org_id',
    fieldName: 'project.org.name',
    type: 'text',
    caption: 'Organisation',
    showInListview: true,
    width: `${columnWidths.common * 1.5}px`,
  },
  haveBenchmarkSubscription: {
    ...controlDefaults,
    name: 'org_subscriptions',
    fieldName: 'haveBenchmarkSubscription.value',
    type: 'text',
    caption: 'Subscription',
    showInListview: true,
    width: `${columnWidths.small}px`,
    classesFromRowPath: 'haveBenchmarkSubscription.classes',
  },
  property_id: {
    ...controlDefaults,
    name: 'property_id',
    fieldName: 'name',
    type: 'text',
    caption: 'Property',
    showInListview: true,
    width: `${columnWidths.common * 1.5}px`,
  },
  dnc: {
    ...controlDefaults,
    name: 'dnc',
    fieldName: 'dnc',
    type: 'checkbox',
    caption: 'DNC',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    showInListview: true,
    width: `${columnWidths.small}px`,
  },
  advisor_id: {
    ...controlDefaults,
    name: 'advisor_id',
    fieldName: 'advisor.name',
    type: 'text',
    caption: 'Advisor Responsible',
    showInListview: true,
    width: `${columnWidths.common}px`,
  },
  percentage_completed: {
    ...controlDefaults,
    name: 'percentage_completed',
    fieldName: 'percentageCompleted',
    type: 'text',
    caption: 'Percentage Completed',
    showInListview: true,
    width: `${columnWidths.common}px`,
  },
  client_status: {
    ...controlDefaults,
    name: 'client_status',
    fieldName: 'clientStatus.name',
    type: 'text',
    caption: 'Client Status',
    classesFromRowPath: 'clientStatus.classes',
    showInListview: true,
    width: '120px',
  },
  haveProbe: {
    ...controlDefaults,
    name: 'haveProbe',
    fieldName: 'haveProbe.value',
    type: 'text',
    caption: 'Probe',
    classesFromRowPath: 'haveProbe.classes',
    showInListview: true,
    width: '50px'
  },
  // Quarters controls are handled in PropertyAnalysis.jsx container due to dynamic header names from API.
};
