import { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';

const AnalysisDashboardPeriodAlert = (props) => {
  const { header, lockEditing } = props;

  // Random
  const arrayCount = 50;
  const biasCount = 10;
  const switchStates = new Array(arrayCount).fill({iconName: '', iconClass: 'text-success'});
  switchStates.fill({iconName: 'bell-solid', iconClass: 'text-danger'}, 0, biasCount);

  const idx = Math.floor(Math.random() * switchStates.length);

  const [iconName, setIconName] = useState(switchStates[idx].iconName);
  const [className, setIconClass] = useState(switchStates[idx].iconClass);

  const handleChange = () => {
    if (lockEditing) return false;
    setIconName((iconName === 'bell-solid' ? '' : 'bell-solid'));
    setIconClass((className === 'text-success' ? 'text-danger' : 'text-success'));
  };

  return (
    <Icon name={iconName} className={className} onClick={handleChange} />
  );
};

export default AnalysisDashboardPeriodAlert;
