import { controlDefaults } from '../../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_date_listview: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    type: 'date',
    caption: 'Date',
    showInListview: true,
    sortColumn: 'transaction_date',
  },
  enterprise_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'enterprise.name',
    name: 'enterprise_id',
    type: 'select',
    caption: 'Enterprise',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'property.name',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  animal_class_id: {
    ...controlDefaults,
    placeholder: '',
    group: 'enterprise',
    fieldName: 'animal_class.name',
    name: 'animal_class_id',
    type: 'select',
    caption: 'Class',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  transaction_type_id: {
    ...controlDefaults,
    disabled: true,
    placeholder: '',
    group: 'transaction',
    name: 'transaction_type_id',
    type: 'select',
    caption: 'Transaction Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    group: 'transaction',
    name: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    sortColumn: 'transaction_date',
  },
  weight: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    fieldName: 'weight',
    name: 'weight',
    unitType: 'kg',
    type: 'number',
    caption: 'Total Greasy Weight (kg)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
  },
  yield_pcnt: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'yield_pcnt',
    type: 'number',
    caption: 'Yield %',
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  total_clean_weight: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'total_clean_weight',
    type: 'number',
    caption: 'Total Clean Weight (kg)',
    formattingRules: {
      includeDecimals: true,
    },
    disabled: true,
    showInEditor: true,
    showInListview: false,
  },
  avg_micron: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'avg_micron',
    type: 'number',
    caption: 'Avg Micron',
    showInEditor: true,
    showInListview: true,
  },
  vm_pcnt: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'vm_pcnt',
    type: 'number',
    caption: 'VM %',
    formattingRules: {
      includeDecimals: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  amount: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'amount',
    type: 'number',
    caption: 'Total Amount ($)',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: false,
    },
    showInEditor: true,
    showInListview: true,
    user_entry: false,
  },
  total_amount_dollar_kg: {
    ...controlDefaults,
    placeholder: '',
    group: 'value',
    name: 'total_amount_dollar_kg',
    type: 'number',
    caption: 'Total Amount ($/kg)',
    formattingRules: {
      includeDecimals: true,
    },
    cols: {
      sm: 4,
      md: 4,
      lg: 4
    },
    disabled: true,
    showInEditor: true,
    showInListview: false,
  },
};
