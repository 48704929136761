import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    validationRules: {
      isRequired: true,
    },
    caption: 'Transaction Date',
    placeholder: '',
    showInEditor: true,
    showInListview: false,
    sortColumn: 'transaction_date',
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Transaction Date',
    type: 'date-month',
    showInListview: true,
    showInEditor: false,
    sortColumn: 'transaction_date',
  },
  category_id: {
    ...controlDefaults,
    name: 'category_id',
    placeholder: '',
    type: 'select',
    caption: 'Category',
    validationRules: {
      isRequired: false,
    },
    includeEmptyOption: true,
    showInEditor: true,
    showInListview: false,
  },
  type_id: {
    ...controlDefaults,
    name: 'type_id',
    fieldName: 'type_id',
    caption: 'Type',
    placeholder: '',
    type: 'select',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  type: {
    ...controlDefaults,
    name: 'type',
    fieldName: 'type.name',
    caption: 'Type',
    showInListview: true,
    showInEditor: false,
  },
  value: {
    ...controlDefaults,
    name: 'value',
    fieldName: 'value',
    caption: 'Value',
    type: 'number',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeCommas: true,
      includeDollarSign: true,
    },
    totals: true,
    showInEditor: true,
    showInListview: true,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    validationRules: {
      isArray: true,
    },
    showInListView: false,
    showInEditor: true,
  },
  distribution_default: {
    ...controlDefaults,
    placeholder: '',
    name: 'Distribution',
    fieldName: '',
    type: 'number',
    caption: 'Distribution #',
    disabled: true,
    validationRules: {},
    formattingRules: {
      includeDecimals: true,
    },
    showInListview: false,
    showInEditor: false,
  },
};
