import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  name: {
    ...controlDefaults,
    placeholder: 'Your property name',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Property Name',
    validationRules: {},
  },
  address: {
    ...controlDefaults,
    placeholder: '',
    name: 'address',
    fieldName: 'address',
    type: 'text',
    caption: 'Property Address',
    validationRules: {},
  },
  state: {
    ...controlDefaults,
    placeholder: '',
    name: 'state',
    fieldName: 'state',
    type: 'text',
    caption: 'State',
    validationRules: {},
  },
  lga: {
    ...controlDefaults,
    placeholder: '',
    name: 'lga',
    fieldName: 'lga',
    type: 'text',
    caption: 'LGA',
    validationRules: {},
  },
  nearest_town: {
    ...controlDefaults,
    placeholder: '',
    name: 'nearest_town',
    fieldName: 'nearest_town',
    type: 'text',
    caption: 'Nearest Town',
    validationRules: {},
  },
  primary_practice: {
    ...controlDefaults,
    placeholder: 'Describe the primary farming/grazing activity on this property',
    name: 'primary_practice',
    fieldName: 'primary_practice',
    type: 'textarea',
    rows: 3,
    caption: 'Primary Acricultural Practice',
    validationRules: {},
  },
  area_ha: {
    ...controlDefaults,
    placeholder: '',
    name: 'area_ha',
    fieldName: 'area_ha',
    type: 'display',
    disabled: true,
    caption: 'Total Area (ha)',
    validationRules: {
      isRequired: false,
    },
    formattingRules: {
      includeCommas: true,
      includeDecimals: true,
    },
    showInListview: false,
  },
};

/** Does Not Contribute checkbox for Client Properties (Admin only) */
export const adminControls = {
  ...controls,

  advisor_id: {
    ...controlDefaults,
    name: 'advisor_id',
    fieldName: 'advisor_id',
    type: 'select',
    caption: 'Advisor',
    showInListview: true,
    validationRules: {
      isRequired: false,
    },
    includeEmptyOption: true
  },
  dnc: {
    ...controlDefaults,
    name: 'dnc',
    fieldName: 'dnc',
    type: 'checkbox',
    caption: 'DNC',
    descriptionStatus: { switchOn: 'Yes', switchOff: 'No' },
    showInListview: true,
    validationRules: {
      isRequired: false,
    },
    defaultValue: false
  },
};
