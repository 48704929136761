import { useState } from 'react';
import Icon from 'jsx/components/core/icons/Icon';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Input} from 'reactstrap';

const AnalysisDashboardColumnHeader = (props) => {
  const { header } = props;

  const [iconName, setIconName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selections, setSelections] = useState(null);

  const handleChange = (event) => {
    setIconName((iconName === 'filter-solid' ? '' : 'filter-solid'));
    if (header.handleChange && event.target.checked) {
      const label = event.target.name;
      const value = event.target.id;

      header.handleChange({label, value});
      setSelections(value);
    }
  };

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  let className = '';
  if (header.columnType === 'quarter') className = 'd-flex justify-content-center';


  const renderOptions = () => header.options.map((option, index) => {
    const abbreviatedCaption = option?.name?.split(' ').reduce((accumulator, substring) => accumulator + substring[0], '');
    const name = `${option.name} (${abbreviatedCaption})`;
    const checked = (selections === option.id);

    return (
      <DropdownItem key={index} >
        <input className="mr-2" type="checkbox" onChange={handleChange} id={option.id} name={option.name} checked={checked}/>
        <span>{name}</span>
      </DropdownItem>
    );
  });

  return (
    <>
      {header?.options?.length > 0 && dropdownOpen && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="nav-link p-0"
            tag="div"
          >
            {header.shortCaption || header.caption}
            {iconName && (
              <Icon name={iconName} className="text-primary" style={{margin: 3}} onClick={handleChange} />
            )}
          </DropdownToggle>

          <DropdownMenu className="bg-lightgray">
            <DropdownItem key={-1}><input className="mr-2" type="checkbox" checked={!selections} onChange={(() => {})}/>Show ALL</DropdownItem>
            <DropdownItem divider className="border-bottom border-corporate"/>
            {renderOptions(selections)}
          </DropdownMenu>
        </Dropdown>
      )}

      {(!header?.options || !dropdownOpen) && (
        <div className={className} onClick={toggle} style={header.style}>
          {header.shortCaption || header.caption}
          {!selections && (
            <Icon name={iconName} className="text-success" style={{margin: 3, marginLeft: 4}}/>
          )}
        </div>
      )}
    </>
  );
};

export default AnalysisDashboardColumnHeader;
