import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    type: 'hidden',
    name: 'id',
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: false
  },
  category_id: {
    ...controlDefaults,
    placeholder: '',
    type: 'select',
    name: 'category_id',
    fieldName: 'category_id',
    caption: 'Category',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInLisview: false,
  },
  transaction_month: {
    ...controlDefaults,
    name: 'transaction_month',
    fieldName: 'transaction_date',
    caption: 'Month',
    type: 'date-month',
    showInListview: true,
    showInEditor: false,
    sortColumn: 'created',
  },
  category: {
    ...controlDefaults,
    name: 'category',
    fieldName: 'category.name',
    caption: 'Category',
    showInListview: true,
    showInEditor: false,
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    controlType: 'interval_date',
    linkedWith: 'transaction_date',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: false,
    showInListview: false,
  },
  value: {
    ...controlDefaults,
    name: 'value',
    fieldName: 'value',
    type: 'number',
    caption: 'Value',
    placeholder: '',
    validationRules: {
      isRequired: true,
    },
    formattingRules: {
      includeDecimals: true,
      includeDollarSign: true,
      includeCommas: true,
    },
    showInEditor: true,
    showInListview: true,
    totals: true,
  },
};
