import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Toast, ToastHeader, ToastBody, ToastFooter } from 'reactstrap';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';
import FormInput from 'jsx/components/core/form/components/FormInput';
import Icon from 'jsx/components/core/icons/Icon';

const AnalysisDashboardPeriodModal = (props) => {
  const {
    toggle,
    modal = false
  } = props;

  const controls = {
    rcs_status_id: {
      name: 'rcs_status_id',
      caption: 'RCS Status',
      value: '',
      type: 'select',
      options: [],
      validationRules: {
        required: true,
      }
    },
    client_status_id: {
      name: 'client_status_id',
      caption: 'Client Status',
      value: '',
      type: 'select',
      options: [],
      validationRules: {
        required: true,
      }
    },
    percent_completed: {
      name: 'percent_completed',
      caption: 'Percent Completed',
      value: 55,
      type: 'number',
      options: [],
      validationRules: {
        required: true,
      }
    },
    dnc: {
      name: 'dnc',
      caption: 'DNC',
      value: false,
      type: 'switch',
      validationRules: {
        required: true,
      }
    }
  };

  const handleChange = (event) => {
    console.log("change")
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={false}
      fade={false}
      size="sm"
      trapFocus={false}
      // style={{maxWidth: 400}}
      // centered
  >
    <ModalHeader toggle={toggle}>Quarter Period Settings</ModalHeader>
    <ModalBody>
      <Row>
        <Col>
          <FormInputSelect selectFirst={false} handleChange={handleChange} control={controls.rcs_status_id} /> 
        </Col>
        <Col>
          <FormInputSelect selectFirst={false} handleChange={handleChange} control={controls.client_status_id} /> 
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput handleChange={handleChange} control={controls.percent_completed} />
        </Col>
        <Col>
          <FormInput handleChange={handleChange} control={controls.dnc} />
        </Col>
      </Row>

      <div className="border-bottom border-corporate bg-light mt-2 rounded d-flex justify-content-between">
        <small>NOTES</small>
        <small><a herf="#">Add New</a></small>
      </div>
      <Toast className="m-1" style={{maxWidth: '100%'}}>
        <ToastHeader className="bg-red-20 text-black" tagClassName="d-flex justify-content-between w-100" style={{maxWidth: '100%'}}>
          <div>Nic Walker (Analyst)</div>
          <div>2025-01-29</div>
        </ToastHeader>
        <ToastBody>
          Found Labour UNPAID FTE issues with this property
        </ToastBody>
      </Toast>

      <Toast className="m-1" style={{maxWidth: '100%'}}>
        <ToastHeader className="bg-green-20 text-black" tagClassName="d-flex justify-content-between w-100" style={{maxWidth: '100%'}}>
          <div>Frank Ford (Farm Advisor)</div>
          <div>2025-01-15</div>
        </ToastHeader>
        <ToastBody>
          Set this analysis to Waiting for Submission
        </ToastBody>
      </Toast>

      <div className="border-bottom border-corporate bg-light mt-2 rounded"><small>TIMESHEETS</small></div>
      <div className="d-flex justify-content-center m-4">
        <Icon name="timer" size="3x" className="text-corporate"></Icon>
      </div>
      <p className="text-center">No Timesheets found for this property</p>

    </ModalBody>
    <ModalFooter className="d-flex justify-content-center">
      <Button color="primary" onClick={toggle} size="sm">
        Save Change
      </Button>{' '}
      <Button color="primary" onClick={toggle} size="sm">
        Execute Probe
      </Button>{' '}
      <Button color="primary" onClick={toggle} size="sm">
        Masquarade
      </Button>{' '}
      <Button color="secondary" onClick={toggle} size="sm">
        Close
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default AnalysisDashboardPeriodModal;
