import { useState } from 'react';
import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';

const AnalysisDashboardPeriodStatus = (props) => {
  const { header, lockEditing } = props;

  const [showControl, toggleShowControl] = useState(false);

  // Random
  const idx = Math.floor(Math.random() * header.options.length);
  const option = header.options[idx];

  const abbreviatedCaption = option?.name?.split(' ').reduce((accumulator, substring) => accumulator + substring[0], '');
  const optionColour = (option?.colour ? `text-${option?.colour}` : '');
  const className = `ml-1 mr-1 ${optionColour}`;

  const controls = {
    rcs_status_id: {
      name: 'rcs_status_id',
      caption: 'RCS Status',
      value: option.id,
      type: 'select',
      options: header.options,
      validationRules: {
        required: true,
      }
    }
  };

  const onClick = () => {
    if (lockEditing) return false;
    toggleShowControl(!showControl);
  };

  const handleChange = () => {
    console.log("handlechange")
  };

  return (
    <div className={className} title={option?.name} type="button">
      {header.abbrievateCaption && !showControl && (
        <span onClick={onClick}>{abbreviatedCaption}</span>
      )}

      {!header.abbrievateCaption && !showControl && (
        <span onClick={onClick}>{option.name}</span>
      )}

      {showControl && (
        <FormInputSelect size="30px" hideCaption selectFirst={false} handleChange={handleChange} control={controls.rcs_status_id} /> 
      )}
    </div>
  );
};

export default AnalysisDashboardPeriodStatus;
