import React from 'react';
import { connect } from 'react-redux';
import { Form, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Icon from 'jsx/components/core/icons/Icon';
import FormBase from '../../../core/form/components/FormBase';
import FormInput from '../../../core/form/components/FormInput';

import GenericDistributionsLsv from '../components/GenericDistributionsLsv';

import { controls as assetControls } from '../forms/assets';

import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';

import { fetchAttributes } from '../actions/attributes';
import {
  fetchAsset,
  createAsset,
  updateAsset,
  removeAsset,
  fetchAssetTransactions,
} from '../actions/assets';
import { fetchEnterpriseDistributions } from '../actions/enterprises';

import { fetchProperties } from '../../projects/actions/properties';

class AssetModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(assetControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Assets',
    };

    this.onSave = this.onSave.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadAttributes = this.loadAttributes.bind(this);
    this.setOptions = this.setOptions.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { selectedRanges } = this.props.enterprises;
    const { project } = this.props.portal;

    if (!prevProps.isOpen && this.props.isOpen) {
      let controls = initControls(cloneDeep(assetControls));
      controls = { ...controls, distributions: { value: [] } };
      let updatedState = {
        controls,
        data: {},
        id: null,
        isNew: true,
        title: 'New Asset',
      };

      this.props.dispatch(fetchAttributes({ type: 'asset_categories' }));
      this.props.dispatch(fetchAttributes({ type: 'divisions' }));
      this.props.dispatch(fetchEnterpriseDistributions({ type: 'division' }));

      // Manage property/organisation list
      const org = project ? project.org : {};
      const properties = await this.props.dispatch(fetchProperties());

      controls.property_id.options = properties.rows.map(property => ({id: property.id, name: property.name}));
      if (org.default_property_id) controls.property_id.value = org.default_property_id;

      // If no default, pick first in list
      if (!org.default_property_id && properties.rows.length > 0) controls.property_id.value = properties.rows[0].id;

      if (org.allow_org_transactions) {
        // Add null selection (organisation transaction) to options
        const orgTransaction = [{id: '', name: 'Organisation Transaction'}];
        controls.property_id.options = controls.property_id.options.concat(orgTransaction);
      }

      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          ...updatedState,
          id,
          isNew: false,
          title: 'Edit Asset',
        };

        this.props.dispatch(fetchAsset(id)).then((data) => {
          const group_id = data.asset_type?.parent_id;
          if (group_id) {
            this.loadAttributes(group_id);
            data = { ...data, group_id };
          }
          this.setState({
            data,
            controls: updateControls(controls, data),
          });
        });

        // Select organisation transaction if property_id is null
        if (!controls.property_id.value) controls.property_id.value = '';

        // Fetch transactions in background, no need to block user.
        this.props.dispatch(
          fetchAssetTransactions(
            {
              ...selectedRanges,
              asset_id: id,
            },
            true
          )
        );
      }

      this.setState(updatedState);
    }
  }

  async onSave(close = true) {
    const { data, controls, isNew } = this.state;

    // Force a null if selected value is empty quotes
    if (controls.property_id.value === '') controls.property_id.value = null;

    const unsavedData = saveControls(controls, data);
    unsavedData.distributions = controls.distributions.value;

    const { isValid, updatedControls } = await validateFormFieldControls(unsavedData, controls);

    if (isValid) {
      let success;
      if (isNew) {
        delete unsavedData.id;
        success = await this.props.dispatch(createAsset(unsavedData));
        if (success?.id) this.onClose(true, success.id);
      } else {
        success = await this.props.dispatch(updateAsset(unsavedData));
        if (success) {
          if (close) this.onClose(true);
          else {
            this.setState({
              data: {
                ...data,
                ...unsavedData,
              },
            });
          }
        }
      }
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing ${data.name} asset permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeAsset(data.id));
      if (success) this.onClose(true);
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_ASSET_ATTRIBUTES' });
  }

  onChange(event) {
    const { controls } = this.state;
    const { name, value } = event.target;

    const old_group_id = controls.group_id.value;

    this.handleChange(event);

    switch (name) {
      case 'adjustment_type_id': {
        // Set following as required form fields
        if (value !== null) {
          // Notes
          controls.notes.validationRules = {
            ...controls.notes.validationRules,
            isRequired: true,
          };
          // Adjustment Type
          controls.adjustment_type_id.validationRules = {
            ...controls.adjustment_type_id.validationRules,
            isRequired: true,
          };
        }
        break;
      }
      case 'group_id': {
        // Load another select;
        if (old_group_id !== value) this.loadAttributes(value);
        break;
      }
      default: {
        break;
      }
    }
  }

  loadAttributes(parent_id) {
    if (parent_id === '' || parent_id === '-') {
      this.props.dispatch({ type: 'UNSET_ASSET_TYPES_ATTRIBUTES' });
    } else {
      this.props.dispatch(fetchAttributes({ type: 'asset_types', parent_id }));
    }
  }

  setOptions(clonedControls) {
    const { asset_categories, asset_types } = this.props.attributes;
    const { distributions } = this.props.enterprises;
    const { properties } = this.props.properties;

    clonedControls = updateControlOptions(clonedControls, 'group_id', asset_categories);
    clonedControls = updateControlOptions(clonedControls, 'asset_type_id', asset_types);
    // clonedControls = updateControlOptions(clonedControls, 'property_id', properties?.rows);

    // Add default distribution values if no values are populated
    if (
      clonedControls.distributions.value === null ||
      clonedControls.distributions.value.length === 0
    ) {
      clonedControls.distributions.value = distributions;
    }
    return clonedControls;
  }

  render() {
    let { controls } = this.state;
    const { title, isNew } = this.state;
    const { isOpen } = this.props;
    const { asset_categories, divisions } = this.props.attributes;
    const { responseMessage, transactions } = this.props.assets;

    controls = this.setOptions(controls);

    const category_id = controls.group_id.value;
    const category = asset_categories.find((category) => category.id === category_id);
    const showDistributions = category_id && category?.rules?.allowDistributions === true;

    const iconName = 'shapes';
    const disabled = !isNew && transactions.rows?.length > 0;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.onChange} control={controls.property_id} />

            <FormInput
              handleChange={this.onChange}
              control={controls.group_id}
              disabled={disabled}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.asset_type_id}
              disabled={disabled}
            />
            <FormInput handleChange={this.handleChange} control={controls.name} />
          </Form>
          {showDistributions && (
            <GenericDistributionsLsv
              className="mt-2"
              rows={controls.distributions.value || []}
              divisions={divisions ?? []}
            />
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={disabled}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ assets, attributes, enterprises, properties, farmportrait_portal }) => ({
  assets,
  attributes,
  enterprises,
  properties,
  portal: farmportrait_portal
});

export default connect(mapStoreToProps)(AssetModal);
