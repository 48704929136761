/* eslint-disable no-plusplus, no-param-reassign */
import React from 'react';
import moment from 'moment';
import { getLastChildValue } from 'jsx/lib/generic';
import Icon from 'jsx/components/core/icons/Icon';
import { cloneDeep, noop, isArray } from 'lodash';
import Listview from './Listview';
import { evalFormula } from '../lib/formula';
import { prettyFormat, numberRuleFormat } from '../lib/fieldFormat';
import { getCopyValueEntry } from '../lib/copyValue';
import Pill from './Pill';
import ColumnHeader from './ColumnHeader';

const applyCopyValueRules = (controls, rows) =>
  rows.map((row) => {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      if (control?.copyValue) {
        const entry = getCopyValueEntry(control, row);
        if (entry) row[entry.key] = entry.value;
      }
    });
    return row;
  });

const addEvaluatedFields = (controls, rows) =>
  rows.map((row) => {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];

      if (control?.formula) {
        const existing_key = Object.keys(row).includes(key);
        if (!existing_key) {
          const value = evalFormula(controls, control.formula, row);
          row[control.name] = value;
        }
      }
    });
    return row;
  });

const prepareRows = (controls, rows) => {
  /**
   * Check and prepare rows by:
   * - Applying evaluated fields by evaluating the corresponding control formula
   * - Updating row values by applying copy value rules
   * - Sequence is important, copy value rules depend on all fields being populated in rows
   */
  rows = addEvaluatedFields(controls, rows);
  rows = applyCopyValueRules(controls, rows);
  return rows;
};

/**
 * @param {Object} props - Component props
 * @param {boolean} [props.allowTotals] - Allows the user to disable display of totals
 * @param {boolean} [props.darkTable] - Allows the user to set the table to dark mode
 * @param {boolean} [props.hideHeader] - Allows the user to hide the table header
 * @param {string} [props.iconName] - Icon name to be displayed when content is empty
 * @param {string} props.emptyCaption - Caption to be displayed when content is empty
 * @param {function} props.onClick - Function to be called when a row is clicked
 * @param {Array.<Object>} [props.actions] - Array of objects to be placed in the table body
 * @param {Array.<Object>} props.rows - Array of objects to be placed in the table body
 * @param {Object} props.controls - Control definitions to help format the table cells
 * @param {Array.<Object>} [props.totalFormattingRules] - Array of objects to be used to format the totals
 *
 * */
const GenericLsv = ({
  allowTotals = true,
  darkTable = false,
  rows,
  onClick = noop,
  controls,
  iconName,
  emptyCaption,
  hideHeader = false,
  actions = null,
  totalFormattingRules = null
}) => {
  const clonedControls = cloneDeep(controls);
  const onActionClick = (event, action, id) => {
    // Stop propagation of row click.
    event.stopPropagation();
    action.func(id);
  };

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const {
        field,
        formattingRules,
        type,
        unitType,
        width,
        displayLimit,
        options,
        sortColumn,
        formatter,
        classesFromRowPath,
      } = header;
      let { classes } = header;

      // If classes are set from the row object, use them instead
      if (classesFromRowPath)
        classes = classesFromRowPath.includes('.')
          ? getLastChildValue(classesFromRowPath, row)
          : row[classesFromRowPath];

      let caption = field?.includes('.') ? getLastChildValue(field, row) : row[field];

      // The control has the unit type value set.  Pull the value from the object back, if set
      if (unitType) caption = caption?.value ?? null;

      switch (type) {
        case 'pretty-number':
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
              {prettyFormat(caption)}
            </td>
          );
        case 'count':
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
              {Array.isArray(caption) ? caption.length : ''}
            </td>
          );
        case 'date':
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]} date-value={new Date(caption)} >
              {caption ? moment(caption).format('Do MMM YYYY') : ''}
            </td>
          );
        case 'date-month':
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
              {caption ? moment(caption).format('MMMM YYYY') : ''}
            </td>
          );
        case 'number': {
          /* Formatting rules only apply to number types.  If user has selected pretty-number, they've
             already told us that that is the specific format they want to use. */
          if (formattingRules) {
            return (
              <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
                {numberRuleFormat(caption, formattingRules)}
              </td>
            );
          } else
            return (
              <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
                {caption}
              </td>
            );
        }
        case 'checkbox': {
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
              {caption ? 'Yes' : 'No'}
            </td>
          );
        }
        case 'pill': {
          // Expect true/false boolean caption
          if (typeof caption === 'boolean') {
            if (caption) {
              caption = 'Yes';
              classes = `${classes} text-white bg-success`;
            } else {
              caption = 'No';
              classes = `${classes} text-white bg-danger`;
            }
          }

          return (
            <td key={index}>
              <div className="d-flex justify-content-center">
                <Pill caption={caption} classes={classes} />
              </div>
            </td>
          );
        }
        case 'custom': {
          const value = formatter ? formatter(caption, row) : caption;
          return (
            <td key={index} className={classes} width={width} data-sort-value={row[sortColumn]}>
              {value}
            </td>
          );
        }

        default:
          // truncate the number of characters to the display limit
          let title;
          if (displayLimit && caption) {
            title = caption;
            caption = caption.substring(0, displayLimit);
            if (displayLimit < title.length) caption += '...';
          }
          if (options !== null) {
            const optionIndex = options.findIndex((option) => option.id === caption);
            if (optionIndex > -1) {
              caption = options[optionIndex].name;
            }
          }
          return (
            <td key={index} className={classes} width={width} title={title ?? null}>
              {caption}
            </td>
          );
      }
    });

    if (actions) {
      const actionIcons = actions.map((action, index) => (
        <Icon
          key={index}
          onClick={(event) => onActionClick(event, action, row.id)}
          name={action.iconName}
          title={action.tooltip}
          className={action.classes ? action.classes : 'mr-2 text-primary'}
        />
      ));

      tableTd.push(
        <td key={headers.length} className="d-flex justify-content-end">
          {actionIcons}
        </td>,
      );
    }

    return tableTd;
  };

  const upperHeaders = [];
  let headers = [];
  let primary_id = 'id';

  if (clonedControls) {
    const keys = Object.keys(clonedControls);
    headers = keys
      .map((key) => {
        const control = clonedControls[key];
        if (control.isPrimary === true) primary_id = key;
        if (control.showInListview) {
          const groupKey = control.group ?? null;
          const upperHeader = {
            key: groupKey,
            caption:
              groupKey !== null
                ? groupKey[0].toUpperCase() + groupKey.substring(1).toLowerCase()
                : null,
            cells: 1,
            classes:
              groupKey !== null
                ? 'text-center bg-lightgray border border-lightgray'
                : 'border-bottom-0',
          };
          const previousUpperHeader = isArray(upperHeaders) ? upperHeaders.at(-1) : null;
          if (previousUpperHeader) {
            if (upperHeaders.at(-1)?.key === groupKey) upperHeaders.at(-1).cells++;
            else upperHeaders.push(upperHeader);
          } else {
            upperHeaders.push(upperHeader);
          }

          let formattingRules;
          const {
            type,
            formattingRules: rules,
            classes: controlClasses,
            listviewOrder: controlListviewOrder,
          } = control;
          if (type === 'number' && rules) formattingRules = rules;

          let classes = 'text-left';
          if (control.type === 'number') classes = 'text-right';
          if (control.type === 'pretty-number') classes = 'text-center';
          if (control.useDefaultClasses) classes = 'text-left';

          if (previousUpperHeader?.key !== upperHeader.key) classes += ' border-left';

          if (controlClasses) classes = controlClasses; // allows the class to be overridden in the form config

          // Add listview ordering
          let listviewOrder = -1; // Default set to end if no order
          if (controlListviewOrder) listviewOrder = controlListviewOrder;

          return {
            caption: control.caption,
            field: control.fieldName ?? control.name,
            formula: control.formula,
            type: control.type,
            classes,
            totals: control.totals,
            formattingRules,
            listviewOrder,
            unitType: control.unitType ?? null,
            width: control.width ?? null,
            displayLimit: control.displayLimit ?? null,
            options: control.options ?? null,
            sortColumn: control.sortColumn ?? null,
            formatter: control.formatter ?? null,
            classesFromRowPath: control.classesFromRowPath ?? null,
            handleChange: control.handleChange ?? null,
            filterSelection: control.filterSelection ?? null
          };
        }
        return false;
      })
      .filter(Boolean);
  }

  // Sort headers by listview order
  headers.sort((a, b) => {
    if (a.listviewOrder < b.listviewOrder) return -1;
    if (a.listviewOrder > b.listviewOrder) return 1;
    return 0;
  });

  const upperHeadTh = upperHeaders.map((upperHeader, index) => (
    <th key={index} colSpan={upperHeader.cells} className={upperHeader.classes}>
      {upperHeader.caption}
    </th>
  ));

  let totals = [];
  const tableHeadTh = headers.map((header, index) => {
    if (header.totals) totals.push(index);
    return (
      <th key={index} className={header.classes}>
        <ColumnHeader header={header} />
      </th>
    );
  });

  if (actions) {
    tableHeadTh.push(<th key={headers.length} />);
  }

  let tableBodyTr = <tr></tr>;

  if (rows?.rows) ({ rows } = rows);
  const haveRows = rows && rows.length > 0;
  if (haveRows) {
    rows = prepareRows(clonedControls, rows);

    tableBodyTr = rows.map((row, index) => (
      <tr key={index} onClick={() => onClick(row[primary_id], row)} role="button">
        {renderRows(headers, row)}
      </tr>
    ));
  }

  if (!allowTotals) totals = null;

  return (
    <div data-component="generic-lsv">
      <Listview
        rows={rows}
        headers={headers}
        totals={totals}
        tableHeadTh={tableHeadTh}
        upperHeadTh={upperHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        hideHeader={hideHeader}
        totalFormattingRules={totalFormattingRules}
        darkTable={darkTable}
      />
    </div>
  );
};

export default GenericLsv;
