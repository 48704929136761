import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  version_id: {
    ...controlDefaults,
    fieldName: 'version_id',
    name: 'version_id',
    type: 'hidden',
    caption: 'Version ID',
  },
  property_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {},
    showInEditor: true,
    includeEmptyOption: true
  },
  name: {
    ...controlDefaults,
    placeholder: '',
    name: 'name',
    fieldName: 'name',
    type: 'text',
    caption: 'Enterprise Name',
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
    description: 'Name of this Enterprise. This field can not be blank.',
  },
  division_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'division_id',
    fieldName: 'division_id',
    type: 'select',
    caption: 'Division',
    validationRules: {},
    options: [],
    showInEditor: true,
    showInListview: false,
    description: 'This field is optional',
  },
  division_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'division_name',
    fieldName: 'division.name',
    type: 'text',
    caption: 'Division',
    validationRules: {},
    showInListview: true,
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_id',
    fieldName: 'type_id',
    type: 'select',
    caption: 'Type',
    validationRules: {},
    disabled: true,
    showInEditor: true,
    description: 'Subtype of Division. Dependent on the Division selection',
  },
  type_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_name',
    fieldName: 'type.name',
    type: 'text',
    caption: 'Type',
    disabled: true,
    validationRules: {},
    showInListview: true,
  },
  production_units_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'production_units_id',
    fieldName: 'production_units_id',
    type: 'select',
    caption: 'Production Units',
    disabled: true,
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    description: 'Reporting units are dependent on Division Selection',
    showInEditor: true,
  },
  production_units_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'production_units_name',
    fieldName: 'production_units.name',
    type: 'text',
    caption: 'Production Unit',
    disabled: true,
    validationRules: {},
    showInListview: true,
  },
  reporting_units_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'reporting_units_id',
    fieldName: 'reporting_units_id',
    type: 'select',
    caption: 'Reporting Units',
    disabled: true,
    validationRules: {
      minLength: 2,
      isRequired: true,
    },
    description: 'Reporting units are dependent on Division Selection',
    showInEditor: true,
  },
  reporting_units_name: {
    ...controlDefaults,
    placeholder: '',
    name: 'reporting_units_name',
    fieldName: 'reporting_units.name',
    type: 'text',
    caption: 'Reporting Unit',
    disabled: true,
    validationRules: {},
    showInListview: true,
  },
  property_allocations: {
    ...controlDefaults,
    placeholder: '',
    caption: 'Property Allocations',
    name: 'property_allocations',
    type: 'text',
    validationRules: {},
    showInListview: true,
  },
};
