import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import ResponseMessage from '../../../core/form/components/ResponseMessageTab';
import Invitation from '../../../manage/containers/Invitation';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';

import PropertyNew from '../../portrait/containers/PropertyNew.jsx';
import Profile from '../components/Profile';
import ProfileContactsLsv from '../components/ProfileContactsLsv.jsx';
import ProfileDocumentsLsv from '../components/ProfileDocumentsLsv.jsx';
import ProfileContactsToolbar from '../components/ProfileContactsToolbar.jsx';
import EnterprisesTab from '../components/EnterprisesTab.jsx';

import DashboardProbes from '../../portrait/containers/DashboardProbes';
import GenericLsv from '../../../core/form/components/GenericLsv';
import { controls } from '../../portrait/forms/enterpriseAllocations';
import { controls as propertyControlsBlueprint } from '../../portrait/forms/properties';
import Indicators from '../../portrait/containers/Indicators';
import PropertiesToolbar from '../components/PropertiesToolbar.jsx';

import { fetchUsers } from '../../../manage/actions';
import { fetchProperties } from '../../projects/actions/properties';
import { fetchEnterpriseRanges, fetchEnterprises } from '../../portrait/actions/enterprises';

class FarmPortrait extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      controls,
      propertyControls: propertyControlsBlueprint,
      isPropertyModalOpen: false,
      isInviteModalOpen: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleEnterprisesTab = this.toggleEnterprisesTab.bind(this);
    this.setPropertyModal = this.setPropertyModal.bind(this);
    this.onEditProperty = this.onEditProperty.bind(this);
    this.setInviteModal = this.setInviteModal.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(fetchEnterprises());
    await this.props.dispatch(fetchEnterpriseRanges());
  }

  async componentDidUpdate() {
    const { currentUser, fetching } = this.props.profile;

    const { project } = this.props.portal;

    if (currentUser.id && !fetching && !project) {
      if (!currentUser.projects || currentUser.projects.length === 0) {
        this.setState({ errorMessage: 'No Projects or Properties found for this client' });
        return;
      }

      const [portalProject] = currentUser.projects;
      this.props.dispatch({ type: 'SET_PORTAL_PROJECT', payload: portalProject });

      // Extract org from project
      const { org } = portalProject;
      await this.props.dispatch(fetchUsers({ org_id: org.id }));
    }
  }

  setPropertyModal(isOpen, isNew) {
    this.setState({ isNew, isPropertyModalOpen: isOpen });
  }

  setInviteModal(isOpen) {
    this.setState({ isInviteModalOpen: isOpen });
  }

  onEditProperty(id) {
    const lnk = `/home/portrait/property/${id}`;
    this.props.history.push(lnk);
  }

  toggleTab(tab) {
    this.props.dispatch({ type: 'SET_FARMEYE_ACTIVETAB', payload: tab });
    const { project } = this.props.portal;

    switch (tab) {
      case 'clients':
        this.props.dispatch(fetchUsers({ filter_user_org_id: project.org_id }));
        break;
      case 'properties':
        this.props.dispatch(fetchProperties({ filter_org_id: project.org_id }));
        break;
      default:
        break;
    }
  }

  toggleEnterprisesTab(tab) {
    this.props.dispatch({ type: 'SET_ENTERPRISES_ACTIVE_TAB', payload: tab });
  }

  render() {
    const { activeTab, project } = this.props.portal;

    const { propertyControls, isPropertyModalOpen, isInviteModalOpen, errorMessage } = this.state;

    const { users, userResponseMessage } = this.props.manage;
    const { properties } = this.props.properties;
    const { authResponseMessage } = this.props.clients;
    const { enterprises } = this.props.enterprises;

    const iconName = 'tractor';
    const title = 'Farm profile';
    const description = 'Manage your contact information, property and profit probes';

    const showTab = [
      ...(new Set(enterprises.rows?.map((enterprise) => enterprise.division.tag)) ?? []),
    ];

    // Set Org
    const org = project ? project.org : {};

    return (
      <div>
        <div className="p-3">
          <ResponseMessage responseMessage={errorMessage} />
          <ResponseMessage responseMessage={authResponseMessage} />
          {!errorMessage && (
            <>
              <PageTitle title={title} description={description} iconName={iconName} />
              <>
                <Nav tabs className="mt-2">
                  <FormTab
                    caption="Dashboard"
                    tabId="dashboard"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled
                  />
                  <FormTab
                    caption="Profile/Contacts"
                    tabId="clients"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                  />
                  <FormTab
                    caption="Properties"
                    tabId="properties"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                  />
                  <FormTab
                    caption="ProfitProbe"
                    tabId="enterprises"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                  />
                  <FormTab
                    caption="Farm Portrait"
                    tabId="indicators"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled
                  />
                  <FormTab
                    caption="Document Downloads"
                    tabId="documents"
                    activeTab={activeTab}
                    toggle={this.toggleTab}
                    disabled
                  />
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="dashboard" className="mb-2 p-3">
                    <DashboardProbes />
                  </TabPane>
                  <TabPane tabId="clients" className="mb-2 p-3">
                    <Profile data={org} />
                    <div className="mt-4">
                      <ResponseMessage
                        responseMessage={userResponseMessage}
                        colour="text-success"
                      />
                      <ProfileContactsToolbar setInviteModal={this.setInviteModal} />
                      <ProfileContactsLsv onClick={() => undefined} rows={users} />
                    </div>

                    <Invitation setModal={this.setInviteModal} isOpen={isInviteModalOpen} />
                  </TabPane>
                  <TabPane tabId="properties" className="mb-2 p-3">
                    {activeTab === 'properties' && (
                      <>
                        <PropertiesToolbar onAddNew={this.setPropertyModal} />
                        <PropertyNew
                          setModal={this.setPropertyModal}
                          isOpen={isPropertyModalOpen}
                          toggleTab={this.toggleTab}
                        />
                        <GenericLsv
                          controls={propertyControls}
                          iconName="draw-polygon"
                          emptyCaption="No Properties found"
                          onClick={this.onEditProperty}
                          rows={properties}
                        />
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="enterprises" className="mb-2 p-3">
                    {activeTab === 'enterprises' && (
                      <EnterprisesTab
                        properties={properties}
                        history={this.props.history}
                        toggleEnterprisesTab={this.toggleEnterprisesTab}
                        showTab={showTab}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="indicators" className="mb-2 p-3">
                    {activeTab === 'indicators' && <Indicators />}
                  </TabPane>
                  <TabPane tabId="documents" className="mb-2 p-3">
                    {activeTab === 'documents' && <ProfileDocumentsLsv rows={[]} />}
                  </TabPane>
                </TabContent>
              </>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStoreToProps = ({
  realm,
  farmportrait_portal,
  properties,
  clients,
  manage,
  profile,
  enterprises,
}) => ({
  realm,
  portal: farmportrait_portal,
  properties,
  clients,
  manage,
  profile,
  enterprises,
});

export default  connect(mapStoreToProps)(FarmPortrait);
