import { noop } from 'lodash';
import React, { useState } from 'react';
import FormInput from '../../../../core/form/components/FormInput';
import Listview from '../../../../core/form/components/Listview';
import { numberRuleFormat } from '../../../../core/form/lib/fieldFormat';

const WoolflowLsv = ({ rows, onValueChange = noop }) => {
  const [changed, setChanged] = useState(false);

  const renderRows = (headers, row) => {
    const tableTd = headers.map((header, index) => {
      const { classes, field, formattingRules } = header;
      let caption = row[field];

      if (field?.includes('.')) {
        let parent = row;
        for (const key of field.split('.').values())
          parent = key && parent ? parent[key] : null;
        caption = parent;
      }

      if (formattingRules) caption = numberRuleFormat(caption, formattingRules);

      switch (field) {
        case 'closing.value': {
          const control = {
            id: `${index}-${field}`,
            name: field,
            value: caption,
            isEdit: false,
            ...row[field],
          };

          if (control.isEdit) {
            return (
              <td key={index} className={classes}>
                {
                  <FormInput
                    className={'text-center p-0 slim'}
                    autoFocus={true}
                    handleChange={(event) => handleChange(event.target, row)}
                    handleKeyDown={(event) =>
                      handleKeyDown(event.keyCode, field, row)
                    }
                    handleBlur={() => handleBlur(field, row)}
                    control={control}
                  />
                }
              </td>
            );
          }
          // Return editable field
          return (
            <td
              key={index}
              className={classes}
              onClick={() => {
                setEditMode(field, row, true);
              }}
            >
              {control.value}
            </td>
          );
        }
        default:
          return (
            <td key={index} className={classes}>
              {caption}
            </td>
          );
      }
    });

    return tableTd;
  };

  const handleChange = ({ name, value }, row) => {
    row[name] = {
      ...row[name],
      value,
      change: true,
    };

    setChanged(!changed);
  };

  const handleKeyDown = (eventKeyCode, field, row) => {
    // Handle user pressing the enter key
    const keyboardEnterKeyCode = 13;
    if (eventKeyCode === keyboardEnterKeyCode) setEditMode(field, row, false);
  };

  // On unfocus, turn off edit mode
  const handleBlur = (field, row) => setEditMode(field, row, false);

  const setEditMode = (name, row, isEdit = false) => {
    row[name] = {
      ...row[name],
      isEdit,
    };

    // If edit mode false and value has changed
    if (!isEdit && row[name]?.change) {
      onValueChange(name, row);

      // Reset change to false
      row[name] = {
        ...row[name],
        change: false,
      };
    }

    setChanged(!changed);
  };

  const upperHeaders = [
    { caption: null, cells: 3, classes: 'border-bottom-0' },
    {
      caption: 'Opening',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    },
    { caption: 'Shearing', cells: 3, classes: 'border-bottom-0 bg-blue-40 text-center' },
    {
      caption: 'Sales',
      cells: 7,
      classes: 'text-center border-bottom border-lightgray',
    },
    {
      caption: 'Closing',
      cells: 3,
      classes: 'text-center bg-lightgray border border-lightgray',
    }
  ];

  const headers = [
    { caption: 'Property', field: 'property.name', classes: 'text-center' },
    { caption: 'Enterprise', field: 'enterprise.name', classes: 'text-center' },
    {
      caption: 'Animal Class',
      field: 'animal_class.name',
      classes: 'text-center',
    },
    {
      caption: 'Weight (kg)',
      field: 'opening.weight',
      classes: 'text-center',
      formattingRules: { includeCommas: true },
    },
    {
      caption: 'Gross ($)',
      field: 'opening.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Value ($/kg)',
      field: 'opening.value',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: '# Shorn',
      field: 'transactions.adjustments.shorn',
      classes: 'text-center',
      formattingRules: { includeCommas: true },
    },
    {
      caption: 'Weight (kg)',
      field: 'transactions.adjustments.weight',
      classes: 'text-center',
      formattingRules: { includeCommas: true },
    },
    {
      caption: 'Weight (kg/hd)',
      field: 'transactions.adjustments.kg_head',
      classes: 'text-center border-right',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Greasy Weight (kg)',
      field: 'transactions.sales.weight',
      classes: 'text-center',
      formattingRules: { includeCommas: true },
    },
    {
      caption: 'Yield %',
      field: 'transactions.sales.yield_pcnt',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Clean Weight (kg)',
      field: 'transactions.wool_sold.clean',
      classes: 'text-center',
      formattingRules: { includeCommas: true },
    },
    {
      caption: 'Avg Micron',
      field: 'transactions.sales.avg_micron',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'VM %',
      field: 'transactions.sales.vm_pcnt',
      classes: 'text-center',
      formattingRules: { includeCommas: true, includeDecimals: true },
    },
    {
      caption: 'Gross ($)',
      field: 'transactions.sales.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Gross ($/kg)',
      field: 'transactions.sales.unit_amount',
      classes: 'text-center border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Weight (kg)',
      field: 'closing.weight',
      classes: 'text-center',
      formattingRules: { includeDecimals: true },
    },
    {
      caption: 'Gross ($)',
      field: 'closing.amount',
      classes: 'text-center',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    },
    {
      caption: 'Value ($/kg)',
      field: 'closing.value',
      classes: 'text-center  border-right',
      formattingRules: {
        includeCommas: true,
        includeDecimals: true,
        includeDollarSign: true,
      },
    }
  ];

  const upperHeadTh = upperHeaders.map(({ caption, cells, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const totals = [];
  const tableHeadTh = headers.map(
    ({ caption, classes, totals: header_totals }, index) => {
      if (header_totals) totals.push(index);
      return (
        <th key={index} className={classes}>
          {caption}
        </th>
      );
    },
  );

  let tableBodyTr = <tr></tr>;
  if (rows?.rows) ({ rows } = rows);
  const haveRows = rows && rows.length > 0;

  if (haveRows) {
    tableBodyTr = rows.map((row) => (
      <tr key={row.id} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  const iconName = 'sheep';
  const emptyCaption =
    'No transactions found. Have you got the right date range set?';

  return (
    <Listview
      rows={rows || []}
      tableHeadTh={tableHeadTh}
      upperHeadTh={upperHeadTh}
      tableBodyTr={tableBodyTr}
      totals={totals}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default WoolflowLsv;
