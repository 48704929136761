import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import { cloneDeep } from 'lodash';
import {
  initListviewControls,
  updateListviewControlOptions,
} from 'jsx/components/core/form/lib/validateListview';
import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';
import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../core/form/components/FormInput';
import FormBase from '../../../core/form/components/FormBase';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../core/form/lib/validateForm';
import { controls as otherBusinessIncomeControls } from '../forms/otherBusinessIncome';
import { controls as distributionBlueprintControls } from '../forms/otherBusinessIncomeDistributions';
import {
  createBusinessIncome,
  fetchBusinessIncome,
  removeBusinessIncome,
  updateBusinessIncome,
} from '../actions/otherBusiness';
import {
  allAreTrue,
  deriveDollarAmount,
  derivePercentage,
  getValidationChecks,
} from '../lib/distributions';
import OtherBusinessIncomeDistributionsLsv from '../components/OtherBusinessIncomeDistributionsLsv';
import FormIntervalDatePicker from '../../../core/form/components/FormIntervalDatePicker';

class OtherBusinessIncomeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(otherBusinessIncomeControls),
      data: {},
      distributionControls: [cloneDeep(distributionBlueprintControls)],
      id: null,
      isNew: false,
      title: 'Income',
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { id } = this.props;
      let controls = initControls(cloneDeep(otherBusinessIncomeControls));
      controls.distributions.value = [this.getDefaultDistribution()];

      let distributionControls = initListviewControls(
        controls.distributions.value,
        cloneDeep(distributionBlueprintControls),
      );

      let updatedState = {
        controls,
        data: {},
        distributionControls,
        id: null,
        isNew: true,
        title: 'New Income',
      };

      if (id) {
        const income = await this.props.dispatch(fetchBusinessIncome({ id }));
        if (income) {
          controls = updateControls(controls, income);

          if (income?.distributions?.length > 0) {
            const { distributions } = income;
            distributionControls = [];

            // Merge existing data into blueprint controls
            distributions.forEach((distribution, index) => {
              distributionControls.push(cloneDeep(distributionBlueprintControls));

              distributionControls[index] = updateControls(
                distributionControls[index],
                distribution,
              );
            });
          }
        }

        updatedState = {
          ...updatedState,
          controls,
          data: income,
          distributionControls,
          id,
          isNew: false,
          title: 'Edit Income',
        };
      }

      this.setState(updatedState);
    }
  }

  deriveTotalValue = (distributions) =>
    distributions
      .map(({ distribution_amount }) => (distribution_amount ? parseFloat(distribution_amount) : 0))
      .reduce((previous, current) => previous + current, 0);

  filterProperties = (enterprise_id) => {
    const { enterprises } = this.props.enterprises;
    // Only display properties that have been previously allocated to the enterprise
    const targetEnterprise = enterprises.rows.find((enterprise) => enterprise.id === enterprise_id);
    if (!targetEnterprise) return [];

    return targetEnterprise.allocations.map((allocation) => ({ ...allocation.property }));
  };

  getDefaultDistribution = () => ({
    enterprise_id: null,
    property_id: null,
    distribution_amount: 0,
    distribution_pcnt: 0,
  });

  onDistributionAdd = () => {
    const { controls, distributionControls } = this.state;

    controls.distributions.value.push(this.getDefaultDistribution());
    distributionControls.push(cloneDeep(distributionBlueprintControls));
    this.setState({ controls, distributionControls });
  };

  onDistributionChange = (event, index) => {
    const { name, value } = event.target;
    const { controls, distributionControls } = this.state;

    switch (name) {
      case 'distribution_amount': {
        // Display no values if input is empty for both amount/percent
        controls.distributions.value[index][name] = null;
        distributionControls[index][name].value = null;

        controls.distributions.value[index].distribution_pcnt = null;
        distributionControls[index].distribution_pcnt.value = null;

        if (allAreTrue(getValidationChecks(value))) {
          controls.distributions.value[index][name] = value;
          distributionControls[index][name].value = value;
        }

        const totalValue = this.deriveTotalValue(controls.distributions.value);
        controls.value.value = totalValue;
        break;
      }
      case 'enterprise_id':
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;

        const filteredProperties = this.filterProperties(value);
        if (filteredProperties.length > 0) {
          const property_id = filteredProperties[0].id;
          controls.distributions.value[index].property_id = property_id;
          distributionControls[index].property_id.value = property_id;
        }

        break;
      default: {
        controls.distributions.value[index][name] = value;
        distributionControls[index][name].value = value;
        break;
      }
    }

    this.setState({ controls, distributionControls });
  };

  onDistributionRemove = (index) => {
    const { controls, distributionControls } = this.state;
    const row = controls.distributions.value[index] ?? null;
    if (row) controls.distributions.value.splice(index, 1);

    const control = distributionControls[index] ?? null;
    if (control) distributionControls.splice(index, 1);

    // Recalculate total value
    const totalValue = this.deriveTotalValue(controls.distributions.value);
    controls.value.value = totalValue;

    this.setState({ controls, distributionControls });
  };

  onSave = async () => {
    let { data } = this.state;
    const { controls, isNew } = this.state;

    data = saveControls(controls, data);

    // Convert distribution_pcnt into decimal form
    data.distributions = controls.distributions.value.map((distribution) => {
      const distribution_pcnt = distribution.distribution_pcnt / 100;
      return {
        ...distribution,
        distribution_pcnt,
      };
    });

    const { isValid, updatedControls } = await validateFormFieldControls(data, controls);

    // All form fields are valid
    if (isValid) {
      let success;
      if (isNew) {
        delete data.id;
        success = await this.props.dispatch(createBusinessIncome(data));
      } else {
        success = await this.props.dispatch(updateBusinessIncome(data));
      }

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  };

  onClose = (refresh = false) => {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
    this.props.dispatch({ type: 'UNSET_BUSINESS_INCOME_ATTRIBUTES' });
    this.props.dispatch({ type: 'UNSET_ENTERPRISE_DISTRIBUTIONS' });
  };

  onRemove = async () => {
    const { data } = this.state;

    const confirmed = window.confirm('Removing other income permanently. Continue?');

    if (!confirmed) return;

    const success = await this.props.dispatch(removeBusinessIncome(data.id));
    if (success) this.onClose(true);
  };

  setTotalValue = (value) => {
    const { controls } = this.state;
    controls.value.value = value;

    this.setState({ controls });
  };

  render() {
    let { distributionControls } = this.state;
    const { controls, isNew, title } = this.state;
    const { isOpen, division_id, responseMessage } = this.props;
    const { enterprises } = this.props.enterprises;
    const { transaction_intervals } = this.props.attributes;
    const iconName = 'dollar-sign';
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);

    if (distributionControls?.length > 0) {
      const filteredEnterprises = enterprises?.rows
        ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
        : [];

      distributionControls = updateListviewControlOptions(
        distributionControls,
        'enterprise_id',
        filteredEnterprises,
      );

      distributionControls.forEach((control, index) => {
        const enterprise_id = control?.enterprise_id?.value ?? null;
        if (enterprise_id) {
          const filteredProperties = this.filterProperties(enterprise_id);
          distributionControls[index] = updateControlOptions(
            control,
            'property_id',
            filteredProperties,
          );
        }

        const { value: distribution_amount } = control.distribution_amount;
        const { value: distribution_pcnt } = control.distribution_pcnt;
        const { value: totalValue } = controls.value;

        // Derive distribution %
        if (
          allAreTrue(getValidationChecks(distribution_amount)) &&
          allAreTrue(getValidationChecks(totalValue))
        ) {
          const percentage = derivePercentage(totalValue, distribution_amount);
          distributionControls[index].distribution_pcnt.value = percentage;
          controls.distributions.value[index].distribution_pcnt = percentage;
        }

        // Derive distribution $
        if (distribution_pcnt > 0 && !distribution_amount) {
          if (
            allAreTrue(getValidationChecks(distribution_pcnt)) &&
            allAreTrue(getValidationChecks(totalValue))
          ) {
            const amount = deriveDollarAmount(totalValue, distribution_pcnt);
            distributionControls[index].distribution_amount.value = amount;
            controls.distributions.value[index].distribution_amount = amount;

            // Format % on edit
            if (!isNew) {
              const percentage = derivePercentage(totalValue, amount);
              distributionControls[index].distribution_pcnt.value = percentage;
              controls.distributions.value[index].distribution_pcnt = percentage;
            }
          }
        }
      });
    }

    // Copy default option from distributionControls if distribution value is null.
    if (controls.distributions.value?.length > 0) {
      controls.distributions.value.forEach((distribution, index) => {
        Object.keys(distribution).forEach((key) => {
          if (distribution[key] === null && distributionControls[index][key].value) {
            controls.distributions.value[index][key] = distributionControls[index][key].value;
          }
        });
      });
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <div className="text-center text-danger">{responseMessage}</div>}
          <Form>
            <FormInput handleChange={this.handleChange} control={controls.category} />
            <FormIntervalDatePicker
              handleChange={this.handleChange}
              controls={controls}
              intervals={filteredTransactionIntervals}
              intervalKey="transaction_interval_id"
              dateKey="transaction_date"
            />
            <FormInput handleChange={this.handleChange} control={controls.value} />
          </Form>
          <OtherBusinessIncomeDistributionsLsv
            controls={distributionControls}
            enterprise={enterprises?.rows ?? []}
            onAdd={this.onDistributionAdd}
            onChange={this.onDistributionChange}
            onRemove={this.onDistributionRemove}
            rows={controls.distributions.value}
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button size="sm" className="mr-2" color="success" onClick={this.onSave}>
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises }) => ({
  attributes,
  enterprises,
});

export default connect(mapStoreToProps)(OtherBusinessIncomeModal);
