import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    placeholder: 'Organisation',
    fieldName: 'property_id',
    name: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: false,
  },
  category_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'category_id',
    type: 'select',
    caption: 'Category',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  name: {
    ...controlDefaults,
    name: 'name',
    fieldName: 'name',
    caption: 'Liability Name',
    type: 'text',
    validationRules: {
      isRequired: true,
    },
    showInListview: false,
    showInEditor: true,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    caption: 'Distributions',
    type: 'json',
    validationRules: {
      isArray: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  rates: {
    ...controlDefaults,
    name: 'rates',
    caption: 'Rates',
    type: 'json',
    validationRules: {},
    showInEditor: true,
    showInListview: false,
  },
};
