import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from 'reactstrap';

import Icon from 'jsx/components/core/icons/Icon';
import FormInput from '../../../../core/form/components/FormInput';
import FormBase from '../../../../core/form/components/FormBase';

import { controls as grazingControls } from '../../forms/croppingGrazings';
import {
  initControls,
  saveControls,
  updateControlOptions,
  updateControls,
  validateFormFieldControls,
} from '../../../../core/form/lib/validateForm';
import {
  removeCroppingGrazing,
  updateCroppingGrazing,
  createCroppingGrazing,
  fetchCroppingGrazing,
} from '../../actions/cropping';
import { fetchAttributes } from '../../actions/attributes';

import { cloneDeep, omit } from 'lodash';
import { fetchAnimalClasses } from '../../actions/animal_classes';

class CroppingGrazingModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      controls: cloneDeep(grazingControls),
      data: {},
      id: null,
      isNew: false,
      title: 'Grazing',
      filtered_cropping_product_types: []
    };

    this.filterProperties = this.filterProperties.bind(this);
    this.loadAnimalClasses = this.loadAnimalClasses.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.updateEnterpriseProperties = this.updateEnterpriseProperties.bind(this);
    this.onEnterpriseChange = this.onEnterpriseChange.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(fetchAttributes({ type: 'crop_product_type' }));
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let controls = initControls(cloneDeep(grazingControls));
      let updatedState = {
        isNew: true,
        title: 'New Grazing',
        data: {},
        controls,
        id: null,
      };

      // Load associated animal classes by default selected target enterprise
      const { livestockDivisionIds } = this.props;
      const { enterprises } = this.props.enterprises;

      const livestockEnterprises = enterprises?.rows
        ? enterprises.rows.filter(({ division_id }) =>
            livestockDivisionIds.includes(division_id),
          )
        : [];

      if (livestockEnterprises?.length > 0)
        this.loadAnimalClasses(livestockEnterprises[0].division_id);

      if (this.props.id) {
        const { id } = this.props;
        updatedState = {
          ...updatedState,
          isNew: false,
          id,
          title: 'Edit Grazing',
        };

        const data = await this.props.dispatch(fetchCroppingGrazing(id));
        const { division_id } = data?.target_enterprise;
        if (division_id) this.loadAnimalClasses(division_id);

        controls = updateControls(controls, data);
        updatedState.controls = controls;
        updatedState.data = data;
      }

      updatedState.filtered_cropping_product_types = await this.props.filterCroppingProductTypes(controls.source_enterprise_id.value);
      this.setState(updatedState);
    }
  }

  loadAnimalClasses(division_id) {
    this.props.dispatch(fetchAnimalClasses({ division_id, valid: true }));
  }

  async onSave() {
    const { data, controls, isNew } = this.state;

    let saveData = saveControls(controls, data);
    const { isValid, updatedControls } = await validateFormFieldControls(
      saveData,
      controls,
    );

    if (isValid) {
      let saveMethod = updateCroppingGrazing;

      if (isNew) {
        saveData = omit(saveData, ['id']);
        saveMethod = createCroppingGrazing;
      }

      const success = await this.props.dispatch(saveMethod(saveData));

      if (success) this.onClose(true);
    } else {
      // Update controls state to display messages to the user
      this.setState({
        controls: updatedControls,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.props.setModal(false);
  }

  async onRemove() {
    const { data } = this.state;

    const confirmed = window.confirm(`Removing Grazing permanently. Continue?`);
    if (confirmed) {
      const success = await this.props.dispatch(removeCroppingGrazing(data.id));
      if (success) this.onClose(true);
    }
  }

  async onChange(event) {
    const { controls } = this.state;
    const { name, value } = event.target;

    switch (name) {
      case 'target_enterprise_id': {
        const target_enterprise = controls[name].options.find(
          (target) => target.id === value,
        );
        if (target_enterprise)
          this.loadAnimalClasses(target_enterprise.division_id);
        break;
      }
      default: {
        break;
      }
    }

    this.handleChange(event);
  }

  async onEnterpriseChange(event) {
    const { value: enterprise_id } = event.target;

    this.handleChange(event);

    const filtered_cropping_product_types = await this.props.filterCroppingProductTypes(enterprise_id);
    this.setState({filtered_cropping_product_types});
  }

  setOptions(controls) {
    const { division_id, livestockDivisionIds } = this.props;
    const { animal_classes } = this.props.animal_classes;
    const { enterprises } = this.props.enterprises;
    const { filtered_cropping_product_types } = this.state;

    // Filter options
    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter(
          (enterprise) => enterprise.division_id === division_id,
        )
      : [];

    const livestock_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) =>
          livestockDivisionIds.includes(enterprise.division_id),
        )
      : [];

    // Set control options
    const typeOptions = {
      product_id: filtered_cropping_product_types,
      source_enterprise_id: division_enterprises,
      target_animal_class_id: animal_classes,
      target_enterprise_id: livestock_enterprises,
    };

    // Update control options
    Object.entries(typeOptions).forEach(
      ([key, options]) =>
        (controls = updateControlOptions(controls, key, options)),
    );

    return controls;
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;
    const enterprise =
      enterprises?.rows?.filter(({ id }) => id === enterprise_id)[0] ?? null;

    if (!enterprise || enterprise.allocations.length === 0)
      return [{ id: null, value: '-' }];

    return enterprise.allocations.map(({ property }) => property);
  }

  updateEnterpriseProperties(controls) {
    const keys = ['source', 'target'];

    keys.forEach((key) => {
      const enterprise_id = controls[`${key}_enterprise_id`]?.value ?? null;

      if (enterprise_id) {
        const properties = this.filterProperties(enterprise_id);
        controls = updateControlOptions(
          controls,
          `${key}_property_id`,
          properties,
        );
        controls[`${key}_property_id`].showInEditor = properties.length > 1;
      }
    });

    return controls;
  }

  render() {
    let { controls } = this.state;
    const { isNew, title } = this.state;
    const { isOpen } = this.props;
    const { responseMessage } = this.props.cropping;
    const iconName = 'clipboard-list';
    // const { cropping_product_types } = this.props.attributes;

    controls = this.setOptions(controls);
    controls = this.updateEnterpriseProperties(controls);

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && (
            <div className="text-center text-danger">{responseMessage}</div>
          )}
          <Form>
            <FormInput
              handleChange={this.onEnterpriseChange}
              control={controls.source_enterprise_id}
            />
            {controls.source_property_id.showInEditor && (
              <FormInput
                handleChange={this.handleChange}
                control={controls.source_property_id}
              />
            )}
            <FormInput
              handleChange={this.handleChange}
              control={controls.product_id}
            />
            <FormInput
              handleChange={this.onChange}
              control={controls.target_enterprise_id}
            />
            {controls.target_property_id.showInEditor && (
              <FormInput
                handleChange={this.onChange}
                control={controls.target_property_id}
              />
            )}
            <FormInput
              handleChange={this.handleChange}
              control={controls.target_animal_class_id}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.from_date}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.total_weeks_count}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.quantity}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.au_rating}
            />
            <FormInput
              handleChange={this.handleChange}
              control={controls.value_per_head_week}
            />
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button
              size="sm"
              className="mr-2"
              color="success"
              onClick={this.onSave}
            >
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button
              size="sm"
              color="danger"
              onClick={this.onRemove}
              disabled={false}
            >
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    animal_classes: store.animal_classes,
    attributes: store.attributes,
    cropping: store.cropping,
    enterprises: store.enterprises,
  };
};

export default connect(mapStoreToProps)(CroppingGrazingModal);
