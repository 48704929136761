import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';

import { updateControlOptions } from 'jsx/components/core/form/lib/validateForm';
import { GenericModal } from 'jsx/components/core/form/components/GenericModal';
import Icon from 'jsx/components/core/icons/Icon';
import ResponseMessageTab from 'jsx/components/core/form/components/ResponseMessageTab';
import { isEmpty } from 'lodash';

export class WoolTransactionModal extends GenericModal {
  constructor(props) {
    super(props);

    this.filterProperties = this.filterProperties.bind(this);
    this.handleTransactionChange = this.handleTransactionChange.bind(this);
  }

  filterProperties(enterprise_id) {
    const { enterprises } = this.props.enterprises;

    // Only display properties that have been previously allocated to the enterprise
    const { allocations = [] } = enterprises?.rows.find(
      (enterprise) => enterprise.id === enterprise_id
    );

    return allocations.map((allocation) => ({ ...allocation.property }));
  }

  handleTransactionChange(event) {
    const { controls } = this.state;
    const {name, value} = event.target;

    this.handleChange(event);

    switch (name) {
      case 'weight':
        controls.total_clean_weight.value = (value * (controls.yield_pcnt.value / 100)).toFixed(2);
        controls.total_amount_dollar_kg.value = (controls.amount.value / value).toFixed(2);
        break;
      case 'yield_pcnt':
        controls.total_clean_weight.value = (controls.weight.value * (value / 100)).toFixed(2);;
        break;
      case 'amount':
        controls.total_amount_dollar_kg.value = (value / controls.weight.value).toFixed(2);
        break;
      default: break;
    }

    this.setState({controls});
  }

  render() {
    const { iconName, responseMessage, isOpen } = this.props;
    const { title, isNew } = this.state;
    let { controls } = this.state;


    controls = this.setOptions(controls);

    const enterprise_id = controls?.enterprise_id?.value ?? null;
    if (enterprise_id) {
      const filteredProperties = this.filterProperties(enterprise_id);
      controls = updateControlOptions(controls, 'property_id', filteredProperties);
      controls.property_id.showInEditor = filteredProperties.length > 1;
    }

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader className="bg-corporate text-white">
          <Icon size="1x" name={iconName} className="mr-2" />
          {title}
        </ModalHeader>
        <ModalBody>
          {responseMessage && <ResponseMessageTab responseMessage={responseMessage} />}
          <Form>{!isEmpty(controls) ? this.renderInputs(controls, this.handleTransactionChange) : null}</Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <div>
            <Button
              size="sm"
              className="mr-2"
              color="success"
              onClick={() => this.onSave(controls)}
            >
              Save
            </Button>
            <Button size="sm" color="light" onClick={this.onClose}>
              Cancel
            </Button>
          </div>
          {!isNew && (
            <Button size="sm" color="danger" onClick={this.onRemove} disabled={false}>
              Delete
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ attributes, direct_costs, enterprises }) => ({
  attributes,
  direct_costs,
  enterprises,
});

export default connect(mapStoreToProps)(WoolTransactionModal);
