/* eslint-disable no-param-reassign, no-alert */
import React from 'react';
import withFeatureFlagCheck from 'jsx/components/core/features/withFeatureFlagCheck';
import { cloneDeep, isNil } from 'lodash';
import { connect } from 'react-redux';
import Icon from 'jsx/components/core/icons/Icon';
import { Alert, Nav, TabContent, TabPane } from 'reactstrap';
import { getFilteredTransactionIntervals } from 'jsx/lib/lookupAttributes';
import { downloadBlob } from 'jsx/lib/download';
import FormTab from '../../../core/form/components/FormTab';
import FormBase from '../../../core/form/components/FormBase';
import PageTitle from '../../../core/form/components/PageTitle';

import OverheadsToolbar from '../components/OverheadsToolbar';
import OverheadsFilter from '../components/OverheadsFilter';
import GenericLsv from '../../../core/form/components/GenericLsv';
import BusinessExpendituresToolbar from '../components/BusinessExpendituresToolbar';
import LabourToolbar from '../components/LabourToolbar';
import BusinessLandcareIndicatorsLsv from '../components/BusinessLandcareIndicatorsLsv';
import BusinessAnimalUnitsLsv from '../components/BusinessAnimalUnitsLsv';
import { updateListviewControlOptions } from '../../../core/form/lib/validateListview';

import OverheadModal from './OverheadModal';
import LiabilityModal from './LiabilityModal';
import LabourModal from './LabourModal';
import AssetModal from './AssetModal';
import GenericModal from '../../../core/form/components/GenericModal';
import GenericToolbar from '../components/GenericToolbar';
import AssetflowLsv from '../components/AssetflowLsv';
import LiabilityflowLsv from '../components/LiabilityflowLsv';

import { controls as controlsExpenditures } from '../forms/capitalExpenditures';
import { controls as controlsLabour } from '../forms/labour';
import { controls as controlsPropertyOtherIncomes } from '../forms/propertyOtherIncomes';
import { controls as controlsOverheadsList } from '../forms/overheadsLst';

import {
  fetchLiabilities,
  fetchLiabilityflows,
  fetchLiabilityTransactions,
} from '../actions/liabilities';

import { fetchAttribute, fetchAttributes } from '../actions/attributes';
import {
  fetchOverheadflows,
  fetchOverheads,
  createOverhead,
  updateOverhead,
  removeOverhead,
} from '../actions/overheads';
import { fetchAssetflows, fetchAssets, fetchAssetTransactions } from '../actions/assets';
import { fetchFTESummary, fetchLabourflows, fetchLabours } from '../actions/labours';

import {
  fetchCapitalExpenditures,
  fetchCapitalExpenditure,
  removeCapitalExpenditure,
  createCapitalExpenditure,
  updateCapitalExpenditure,
} from '../actions/capitalExpenditures';

import { fetchEnterpriseDistributions } from '../actions/enterprises';

import {
  fetchPropertyOtherIncomes,
  fetchPropertyOtherIncome,
  removePropertyOtherIncome,
  createPropertyOtherIncome,
  updatePropertyOtherIncome,
} from '../actions/propertyOtherIncomes';
import LiabilityToolbar from '../components/LiabilityToolbar';
import LiabilityTransactionModal from './LiabilityTransactionModal';
import LiabilityFilter from '../components/LiabilityFilter';
import AssetsFilter from '../components/AssetsFilter';
import AssetTransactionModal from './AssetTransactionModal';
import AssetsToolbar from '../components/AssetsToolbar';
import PageTitleH5 from '../../../core/form/components/PageTitleH5';
import LabourflowLsv from '../components/LabourflowLsv';
import FTESummary from '../components/FTESummary';
import OverheadflowLsv from '../components/OverheadflowLsv';
import LabourFilter from '../components/LabourFilter';
import { fetchProperties } from '../../projects/actions/properties';
import InlineCell from '../../projects/components/sampling_plans/InlineCell';
import OverheadflowLsvFeature from '../components/OverheadflowLsvFeature';
import { downloadImportErrorFile, importClientTransactions } from '../actions/importing';

class Business extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      activeTab: '1',
      isModalOpen: false,
      isOverheadModalOpen: false,
      isLabourModalOpen: false,
      isLiabilityModalOpen: false,
      isLiabilityTransactionModalOpen: false,
      isAssetsModalOpen: false,
      isAssetTransactionsModalOpen: false,
      modalType: null,
      controls: null,
      data: {},
      filterOpen: false,
      overheadFilters: [],
      overheadListControls: [cloneDeep(controlsOverheadsList)],
      blobData: null,
    };

    this.overheadRef = React.createRef();

    this.toggleTab = this.toggleTab.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setOverheadModal = this.setOverheadModal.bind(this);
    this.setLabourModal = this.setLabourModal.bind(this);
    this.setLiabilityModal = this.setLiabilityModal.bind(this);
    this.setLiabilityTransactionModal = this.setLiabilityTransactionModal.bind(this);
    this.setAssetsModal = this.setAssetsModal.bind(this);
    this.setAssetTransactionsModal = this.setAssetTransactionsModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
    this.toggleFilterBox = this.toggleFilterBox.bind(this);
    this.onOverheadSave = this.onOverheadSave.bind(this);
    this.onOverheadDelete = this.onOverheadDelete.bind(this);
    this.fillControlDefaults = this.fillControlDefaults.bind(this);
  }

  toggleFilterBox() {
    const { filterOpen } = this.state;
    const newState = {
      filterOpen: !filterOpen,
    };

    this.setState(newState);
  }

  async onOverheadSave(data) {
    const overhead = cloneDeep(data);

    overhead.distributions = overhead.distributions.map((distribution) => ({
      ...distribution,
      distribution_amount: distribution.distribution_value,
    }));

    delete overhead.editMode;
    delete overhead.isValid;
    if (overhead.id !== null) {
      await this.props.dispatch(updateOverhead(overhead));
    } else {
      // Keep track of users last selection
      this.overheadRef.current = overhead;

      delete overhead.id;
      await this.props.dispatch(createOverhead(overhead));
    }
    this.onRefresh();
  }

  async onOverheadDelete(id) {
    const confirmed = window.confirm('Removing overhead permanently. Continue?');
    if (confirmed) {
      await this.props.dispatch(removeOverhead(id));
      this.onRefresh();
    }
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
    const overheadGroups = await this.props.dispatch(fetchAttributes({ type: 'overhead_groups' }));
    this.props.dispatch(fetchAttributes({ type: 'overhead_types' }));
    let overheadLabourGroup = await this.props.dispatch(
      fetchAttribute({ type: 'labour_groups', tag: 'overheads' }),
    );
    overheadLabourGroup = {
      ...overheadLabourGroup,
      name: 'Unpaid Labour',
      tag: 'unpaid_labour',
    };
    const overheadFilters = [...overheadGroups.rows, overheadLabourGroup];
    // Conditionally fetch property information. This _may_ already be in the store, but we can't guarentee it
    if (!this.props.properties?.properties?.rows?.length > 0) {
      await this.props.dispatch(fetchProperties());
    }

    await this.props.dispatch(fetchEnterpriseDistributions({ type: 'division' }));

    this.setState({ overheadFilters });
  }

  async componentDidUpdate(prevProps) {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }

    if (!prevProps.importing.filename && this.props.importing.filename) {
      // Download file from server and update blob response data in state
      const { filename } = this.props.importing;
      const result = await this.props.dispatch(downloadImportErrorFile(filename));
      this.setState({ blobData: result.data });
    }
  }

  getFetchOptions(type) {
    const { filters, params } = this.props[type];
    return { filters, params };
  }

  onImport = async (event, onProgressChange, schemaType) => {
    if (event.target.files.length === 0) return false;

    const [file] = event.target.files;
    const formData = new FormData();
    formData.append('document', file);
    formData.append('schemaType', schemaType);

    // Append client timezone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    formData.append('timeZone', timeZone);

    const success = await this.props.dispatch(importClientTransactions(formData, onProgressChange));

    if (!success) return false;

    return this.onRefresh();
  };

  async toggleTab(tab) {
    let { modalType } = this.state;
    const { selectedRanges, selectedProperties, includeOrganisationPropertyOption } =
      this.props.enterprises;

    switch (parseInt(tab)) {
      case 1:
      case 12: {
        const { filters, params } = this.getFetchOptions('overheads');
        this.props.dispatch(
          fetchOverheadflows(
            {
              ...params,
              property_ids: selectedProperties,
              ...selectedRanges,
              includeOrganisationPropertyOption,
            },
            filters,
          ),
        );
        this.props.dispatch(
          fetchOverheads({
            ...selectedRanges,
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
          }),
        );
        break;
      }
      case 2: {
        this.props.dispatch(
          fetchCapitalExpenditures({
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
            ...selectedRanges,
          }),
        );
        modalType = 'expenditures';
        break;
      }
      case 3: {
        this.props.dispatch(
          fetchPropertyOtherIncomes({
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
            ...selectedRanges,
          }),
        );
        modalType = 'property_other_incomes';
        break;
      }
      case 5: {
        const { params, filters } = this.getFetchOptions('assets');
        // Fetch and store asset categories/types
        this.props.dispatch(fetchAttributes({ type: 'asset_categories' }));
        this.props.dispatch(
          fetchAssetflows(
            {
              ...params,
              property_ids: selectedProperties,
              ...selectedRanges,
              includeOrganisationPropertyOption,
            },
            filters,
          ),
        );
        this.props.dispatch(
          fetchAssetTransactions({
            ...selectedRanges,
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
          }),
        );
        this.props.dispatch(
          fetchAssets({
            ...selectedRanges,
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
          }),
        );

        break;
      }
      case 8: {
        const { params, filters } = this.getFetchOptions('liabilities');
        // Fetch and store in reducer
        this.props.dispatch(fetchAttributes({ type: 'liability_categories' }));
        this.props.dispatch(
          fetchLiabilityflows(
            {
              ...params,
              ...selectedRanges,
              property_ids: selectedProperties,
              includeOrganisationPropertyOption,
            },
            filters,
          ),
        );
        this.props.dispatch(
          fetchLiabilityTransactions({
            ...selectedRanges,
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
          }),
        );
        this.props.dispatch(
          fetchLiabilities({
            property_ids: selectedProperties,
            includeOrganisationPropertyOption,
          }),
        );
        break;
      }
      case 9: {
        const { filters, params } = this.props.labours;

        this.props.dispatch(fetchAttributes({ type: 'labour_groups' }));
        this.props.dispatch(fetchAttributes({ type: 'labour_categories' }));
        this.props.dispatch(
          fetchLabourflows(
            {
              ...params,
              ...selectedRanges,
              property_ids: selectedProperties,
              includeOrganisationPropertyOption,
            },
            filters,
          ),
        );
        this.props.dispatch(
          fetchLabours(
            {
              ...params,
              ...selectedRanges,
              property_ids: selectedProperties,
              includeOrganisationPropertyOption,
            },
            filters,
          ),
        );
        this.props.dispatch(
          fetchFTESummary({
            ...selectedRanges,
            property_ids: selectedProperties,
          }),
        );
        break;
      }
      default:
        break;
    }

    this.setState({
      modalType,
      activeTab: tab,
    });
  }

  goBack() {
    this.props.history.goBack();
  }

  onRefresh() {
    const { activeTab } = this.state;
    if (activeTab) this.toggleTab(activeTab);
  }

  setLabourModal(isLabourModalOpen, id = null) {
    const newState = {
      isLabourModalOpen,
      id,
    };
    this.setState(newState);
  }

  // Use a different variable for different type of modal's (Overheads) open/closing
  setOverheadModal(isOverheadModalOpen, id = null) {
    const newState = {
      isOverheadModalOpen,
      id,
    };

    this.setState(newState);
  }

  // Set a different variable for different type of modal's (Liabilities) open/closing
  setLiabilityModal(isLiabilityModalOpen, id = null) {
    const newState = {
      isLiabilityModalOpen,
      id,
    };

    this.setState(newState);
  }

  // Set a different variable for different type of modal's (Liability Transactions) open/closing
  setLiabilityTransactionModal(isLiabilityTransactionModalOpen, id = null) {
    const newState = {
      isLiabilityTransactionModalOpen,
      id,
    };

    this.setState(newState);
  }

  // Set a different variable for different type of modal's (Assets) open/closing
  setAssetsModal(isAssetsModalOpen, id = null) {
    const newState = {
      isAssetsModalOpen,
      id,
    };

    this.setState(newState);
  }

  // Set a different variable for different type of modal's (Asset Transactions) open/closing
  setAssetTransactionsModal(isAssetTransactionsModalOpen, id = null) {
    const newState = {
      isAssetTransactionsModalOpen,
      id,
    };

    this.setState(newState);
  }

  async setModal(isModalOpen, id = null, modalType = null) {
    let modalData;
    // Refresh dependant lookups based on type
    if (isModalOpen) {
      switch (modalType) {
        case 'property_other_incomes': {
          this.props.dispatch(fetchAttributes({ type: 'income_categories' }));
          if (id) {
            modalData = await this.props.dispatch(fetchPropertyOtherIncome(id));
          }
          break;
        }
        case 'expenditures': {
          this.props.dispatch(fetchAttributes({ type: 'capex_categories' }));
          if (id) {
            modalData = await this.props.dispatch(fetchCapitalExpenditure(id));
          }
          break;
        }
        default:
          break;
      }
    }

    const newState = {
      id,
      modalData,
      isModalOpen,
    };
    if (modalType) newState.modalType = modalType;

    this.setState(newState);
  }

  async fillControlDefaults(controls) {
    const { project } = this.props.portal;
    const { properties } = this.props.properties;

    // Manage property/organisation list
    const org = project ? project.org : {};

    // Set to default property if selected and property_id.value is still null
    if (org.default_property_id && !controls.property_id.value) controls.property_id.value = org.default_property_id;

    // If org transactions not allowed, and property value is not set and there are options, set first option
    if (!org.allow_org_transactions && !controls.property_id.value && properties.rows.length > 0) {
      controls.property_id.value = properties.rows[0].id;
    }

    // Set to Organisation transaction if property_id.value is still null
    if (org.allow_org_transactions && !controls.property_id.value) controls.property_id.value = null;

    return controls;
  }

  setModalOptions(type) {
    const { capex_categories, income_categories, transaction_intervals } = this.props.attributes;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);
    const { properties } = this.props.properties;
    const { project } = this.props.portal;

    // Manage property/organisation list
    const org = project ? project.org : {};

    let propertyOptions = properties.rows.map(property => ({id: property.id, name: property.name}));

    if (org.allow_org_transactions) {
      // Add null selection (organisation transaction) to options
      const orgTransaction = [{id: '', name: 'Organisation Transaction'}];
      propertyOptions = propertyOptions.concat(orgTransaction);
    }

    let options;
    switch (type) {
      case 'property_other_incomes': {
        options = {
          title: 'Property Other Income',
          controls: controlsPropertyOtherIncomes,
          options: {
            category_id: income_categories,
            transaction_interval_id: filteredTransactionIntervals,
            property_id: propertyOptions || [],
          },
        };
        controlsPropertyOtherIncomes.property_id.disabled = false;

        break;
      }
      case 'expenditures':
      default: {
        options = {
          title: 'Capital Expenditures',
          controls: controlsExpenditures,
          options: {
            category_id: capex_categories,
            transaction_interval_id: filteredTransactionIntervals,
            property_id: propertyOptions || [],
          },
        };
        controlsExpenditures.property_id.disabled = false;

        break;
      }
    }

    return options;
  }

  onClose(refresh = false) {
    const { modalType } = this.state;

    if (refresh && this.props.onRefresh) this.props.onRefresh();
    this.state.setModal(false);
    this.setState({ isOpen: false });

    switch (modalType) {
      case 'property_other_incomes':
        this.props.dispatch({ type: 'UNSET_PROPERTY_OTHER_INCOMES_ATTRIBUTES' });
        break;
      case 'expenditures':
        this.props.dispatch({ type: 'UNSET_CAPITAL_EXPENDITURES_ATTRIBUTES' });
        break;
      default:
        break;
    }
  }

  onRemove(id) {
    const { modalType } = this.state;

    let removeMethod;

    switch (modalType) {
      case 'property_other_incomes': {
        removeMethod = removePropertyOtherIncome;
        break;
      }
      case 'expenditures':
      default: {
        removeMethod = removeCapitalExpenditure;
        break;
      }
    }

    return this.props.dispatch(removeMethod(id));
  }

  onSave(data, isNew) {
    const { modalType } = this.state;

    if (isNew) delete data.id;

    let saveMethod;

    switch (modalType) {
      case 'property_other_incomes': {
        saveMethod = isNew ? createPropertyOtherIncome : updatePropertyOtherIncome;
        break;
      }
      case 'expenditures':
      default: {
        saveMethod = isNew ? createCapitalExpenditure : updateCapitalExpenditure;
        break;
      }
    }

    return this.props.dispatch(saveMethod(data));
  }

  addLabourDistributionControls = (controls, rows) => {
    const { distribution_default } = controls;
    // Check rows exist
    if (rows.rows?.length > 0) {
      const next = 1;
      const borderClass = ' border-left border-right';
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row?.distributions?.length > 0) {
          const offset = row?.distributions?.length;
          // Sort distributions alphabetically
          row.distributions.sort((a, b) =>
            a?.division?.name ? a.division.name.localeCompare(b.division.name) : next,
          );

          let listviewOrder = 0; // Starts after category

          // Iterate through each distribution
          row.distributions.forEach((distribution, idx) => {
            let divisionName;
            let fieldName;

            if (!row.is_paid) {
              // Add distribution control for total value. Only applicable to unpaid labour
              divisionName = `${distribution.division.name
                .toString()
                .toLowerCase()}_distribution_value`;
              fieldName = `distributions.${idx.toString()}.value`;
              listviewOrder = idx + next;

              controls[divisionName] = {
                ...distribution_default,
                caption: `${distribution.division.name}`,
                classes: `${distribution_default.classes}${borderClass}`,
                fieldName,
                formattingRules: {
                  includeCommas: true,
                  includeDecimals: true,
                  includeDollarSign: true,
                },
                listviewOrder,
                name: `${divisionName}`,
                showInListview: true,
              };
            }

            // Add distribution control for weeks
            divisionName = `${distribution.division.name
              .toString()
              .toLowerCase()}_distribution_weeks_count`;
            fieldName = `distributions.${idx.toString()}.weeks_count`;
            listviewOrder += offset;

            controls[divisionName] = {
              ...distribution_default,
              caption: `${distribution.division.name}`,
              classes: `${distribution_default.classes}${borderClass}`,
              fieldName,
              group: 'weeks',
              name: `${divisionName}`,
              listviewOrder,
              showInListview: true,
            };
          });

          // Set listview order for total weeks count
          controls.total_weeks_count.listviewOrder = listviewOrder + next;

          if (!row.is_paid) {
            // Display total fte amount
            controls.total_value.showInListview = true;

            // Insert total value after last division value column
            const distributions_length = row.distributions.length;
            const total_weeks_count_position = controls.total_weeks_count.listviewOrder; // Last column position
            const target = total_weeks_count_position - distributions_length;
            controls.total_value.listviewOrder = target;

            // Display and insert FTE after total value
            controls.fte.showInListview = true;
            controls.fte.listviewOrder = target;
          }
        }
      });
    }

    return controls;
  };

  downloadFile = () => {
    const { filename } = this.props.importing;
    const { blobData } = this.state;
    if (!blobData || !filename) return;

    downloadBlob(blobData, filename);
  };

  onDownloadFileKeyDown = (event) => {
    // If enter key, download file
    if (event.key === 'Enter') this.downloadFile();
  };

  getErrorMessageFromResponseMessage = (importResponseMessage) => {
    if (importResponseMessage !== 'Error Importing Transactions') return importResponseMessage;

    return (
      <p className="m-0">
        The import file contains invalid values. Please save and open the log file for more
        information:
        <a
          role="button"
          tabIndex={0}
          onKeyDown={this.onDownloadFileKeyDown}
          className="text-black mx-2 cursor-pointer"
          onClick={this.downloadFile}
        >
          Click to Download
        </a>
        For further assistance, contact a FarmEye specialist on
        <a href="tel:0749394255" className="text-black mx-2">
          07 4939 4255
        </a>
        or
        <a href="mailto:profitprobe@rcsaustralia.com.au" className="text-black mx-2">
          profitprobe@rcsaustralia.com.au
        </a>
      </p>
    );
  };

  onImportSuccessAlertDismiss = () =>
    this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

  onImportErrorAlertDismiss = () => {
    const { filename, responseMessage, errorResponseMessage, error } = this.props.importing;
    if (filename) {
      this.props.dispatch({ type: 'CLEAR_IMPORT_FILENAME' });
      this.setState({ blobData: null });
    }

    if (responseMessage) this.props.dispatch({ type: 'CLEAR_IMPORT_RESPONSE_MESSAGE' });

    if (errorResponseMessage) this.props.dispatch({ type: 'CLEAR_IMPORT_ERROR_RESPONSE_MESSAGE' });

    if (error.type || error.message) this.props.dispatch({ type: 'CLEAR_IMPORT_ERROR' });
  };

  getStatus400ErrorMessage = () => {
    const { type, message } = this.props.importing.error;
    const phoneNumber = '07 4939 5255';
    const email = 'profitprobe@rcsaustralia.com.au';
    switch (type) {
      case 'file':
      case 'mimetype':
      case 's3':
      case 'schemaType': {
        return (
          <p className="m-0">
            {message} For further assistance, please contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'limit': {
        return (
          <p className="m-0">
            There is a row import limit of 200, please split your transactions into another file. If
            this limit is prohibitive for you, please contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'headers': {
        return (
          <p className="m-0">
            The import file is missing the following required row headings:
            <span className="ml-1">{message}.</span> For further assistance, please contact a
            FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      }
      case 'download':
        return (
          <p className="m-0">
            The import file contains invalid values. Please save and open the log file for more
            information:
            <a
              role="button"
              tabIndex={0}
              onKeyDown={this.onDownloadFileKeyDown}
              className="mx-2 cursor-pointer"
              onClick={this.downloadFile}
            >
              Click to Download
            </a>
            For further assistance, contact a FarmEye specialist on
            <a href={`tel:${phoneNumber}`} className="mx-2">
              {phoneNumber}
            </a>
            or
            <a href={`mailto:${email}`} className="mx-2">
              {email}
            </a>
          </p>
        );
      case 'database':
      default: {
        break;
      }
    }

    return (
      <p className="m-0">
        There was a problem uploading the file. Please contact a FarmEye specialist on
        <a href={`tel:${phoneNumber}`} className="mx-2">
          {phoneNumber}
        </a>
        or
        <a href={`mailto:${email}`} className="mx-2">
          {email}
        </a>
      </p>
    );
  };

  render() {
    const {
      id,
      isModalOpen,
      isOverheadModalOpen,
      isLabourModalOpen,
      isLiabilityModalOpen,
      isLiabilityTransactionModalOpen,
      isAssetsModalOpen,
      isAssetTransactionsModalOpen,
      modalType,
      activeTab,
      modalData,
      filterOpen,
      overheadFilters,
    } = this.state;

    let { overheadListControls } = this.state;

    const { isOnScreenDataEntryFeatureEnabled } = this.props;
    const {
      asset_categories,
      asset_types,
      labour_groups,
      labour_categories,
      liability_categories,
      overhead_groups,
      overhead_types,
      transaction_intervals,
    } = this.props.attributes;
    const { capital_expenditures, responseMessage } = this.props.capital_expenditures;
    const { overheads, overheadflows } = this.props.overheads;
    const { selectedRanges, distributions } = this.props.enterprises;
    const { properties } = this.props.properties;
    const { fteSummary, labours, labourflows } = this.props.labours;
    const { property_other_incomes } = this.props.property_other_incomes;
    const { liabilityflows } = this.props.liabilities;
    const liability_transactions = this.props.liabilities.transactions;
    const filteredTransactionIntervals = getFilteredTransactionIntervals(transaction_intervals);
    const {
      responseMessage: importResponseMessage,
      errorResponseMessage: importErrorResponseMessage,
    } = this.props.importing;
    const isImportingResponseMessageSuccessful =
      importResponseMessage && importResponseMessage === 'Import Transactions - Success';

    const { assetflows } = this.props.assets;
    const assetTransactions = this.props.assets.transactions;
    const title = 'Business Information';
    const description = '';

    const icons = {
      overheads: 'dollar-sign',
      capital_expenditures: 'handshake',
      liabilities: 'weight-hanging',
      assets: 'shapes',
      labour: 'person-digging',
      property_other_incomes: 'money-check',
    };

    const emptyCaptions = {
      overheads: 'No overhead rows found',
      capital_expenditures: 'No capital expenditures found',
      liabilities: 'No liabilities found',
      assets: 'No assets found',
      asset_transactions: 'No transactions found',
      labour: 'No Labour found',
      property_other_incomes: 'No other property income found',
    };

    const addButtonText = {
      assets: 'Add Asset',
      liabilities: 'Add Liability',
      property_other_incomes: 'Add other property Income',
    };

    const alertText = {
      property_other_incomes:
        'Do not include diesel rebates or plant and equipment sales. Rebates will be included on the Off Farm tab.  Plant and equipment sales are included on the Assets tab',
    };

    const genericTotalFormattingRules = {
      includeCommas: true,
      includeDollarSign: true,
      includeDecimals: true,
    };

    const labourTotalFormattingRules = {
      includeCommas: true,
      includeDecimals: true,
    };

    // Overhead List Control Options
    if (overheadListControls?.length > 0) {
      overheadListControls = updateListviewControlOptions(
        overheadListControls,
        'property_id',
        properties?.rows,
      );
      overheadListControls = updateListviewControlOptions(
        overheadListControls,
        'group_id',
        overhead_groups,
      );
      overheadListControls = updateListviewControlOptions(
        overheadListControls,
        'type_id',
        overhead_types,
      );
      overheadListControls = updateListviewControlOptions(
        overheadListControls,
        'transaction_interval_id',
        filteredTransactionIntervals,
      );

      overheadListControls[0].distributions.value = distributions;
    }

    const modalOptions = this.setModalOptions(modalType, modalData);

    // Sort selections alphabetically
    const toEnd = 1;
    asset_categories.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));
    asset_types.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));
    liability_categories.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));
    overheadFilters.sort((a, b) => (a?.tag ? a.tag.localeCompare(b.tag) : toEnd));

    // Create control sets for paid/unpaid labour
    const controlsLabourPaid = cloneDeep(controlsLabour);
    const controlsLabourUnpaid = cloneDeep(controlsLabour);

    return (
      <div className="p-0 h-100">
        <OverheadModal
          onRefresh={this.onRefresh}
          setModal={this.setOverheadModal}
          id={id}
          isOpen={isOverheadModalOpen}
          isNew={id === null}
        />
        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          modalTitle={modalOptions.title}
          setModal={this.setModal}
          data={modalData}
          isOpen={isModalOpen}
          iconName={modalOptions.iconName}
          onSave={this.onSave}
          onRemove={this.onRemove}
          onClose={this.onRefresh}
          responseMessage={responseMessage}
          fillControlDefaults={this.fillControlDefaults}
        />
        <AssetModal
          onRefresh={this.onRefresh}
          setModal={this.setAssetsModal}
          id={id}
          isOpen={isAssetsModalOpen}
          isNew={id === null}
        />
        <AssetTransactionModal
          onRefresh={this.onRefresh}
          setModal={this.setAssetTransactionsModal}
          id={id}
          isOpen={isAssetTransactionsModalOpen}
          isNew={id === null}
        />
        <LiabilityModal
          onRefresh={this.onRefresh}
          setModal={this.setLiabilityModal}
          id={id}
          isOpen={isLiabilityModalOpen}
          isNew={id === null}
        />
        <LabourModal
          onRefresh={this.onRefresh}
          setModal={this.setLabourModal}
          id={id}
          isOpen={isLabourModalOpen}
        />
        <LiabilityTransactionModal
          onRefresh={this.onRefresh}
          setModal={this.setLiabilityTransactionModal}
          id={id}
          isOpen={isLiabilityTransactionModalOpen}
          isNew={id === null}
        />
        <PageTitle title={title} description={description} />
        {/* Alert for successful import */}
        <Alert
          isOpen={isImportingResponseMessageSuccessful}
          color="success"
          className="m-2 d-flex justify-content-center align-items-center mb-4"
          toggle={this.onImportSuccessAlertDismiss}
          closeAriaLabel="Close"
        >
          <Icon name="check" className="mr-2" />
          {importResponseMessage}
        </Alert>
        {/* Alert for status 400 error responses */}
        <Alert
          isOpen={!isImportingResponseMessageSuccessful && !isNil(importErrorResponseMessage)}
          color="warning"
          className="m-2 d-flex justify-content-center align-items-center mb-4"
          toggle={this.onImportErrorAlertDismiss}
          closeAriaLabel="Close"
        >
          <Icon name="triangle-exclamation" className="mr-2" />
          {this.getStatus400ErrorMessage()}
        </Alert>
        <Nav tabs className="mt-2">
          <FormTab
            caption="Overheads"
            tabId="1"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="overheads"
          />
          {isOnScreenDataEntryFeatureEnabled && (
            <FormTab
              caption="Overheads Test"
              tabId="12"
              activeTab={activeTab}
              toggle={this.toggleTab}
              tabTag="overheads-test"
            />
          )}
          <FormTab
            caption="Capital Expenditure"
            tabId="2"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="expenditures"
          />
          <FormTab
            caption="Other Property Income"
            tabId="3"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="property_incomes"
          />
          <FormTab
            caption="Assets"
            tabId="5"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="assets_flow"
          />
          <FormTab
            caption="Liabilities"
            tabId="8"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="liabilities"
          />
          <FormTab
            caption="Labour"
            tabId="9"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="labour"
          />
          <FormTab
            caption="Landcare Indicators"
            tabId="10"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="users"
            disabled
          />
          <FormTab
            caption="Standard Animal Unit Tables"
            tabId="11"
            activeTab={activeTab}
            toggle={this.toggleTab}
            tabTag="users"
            disabled
          />
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mb-2 p-1">
            <OverheadsToolbar
              onAddNew={() => {
                this.setOverheadModal(true, id);
              }}
              onImport={this.onImport}
              filterClick={this.toggleFilterBox}
            />
            <OverheadsFilter filterOpen={filterOpen} groups={overheadFilters} />
            <OverheadflowLsv
              editTransaction={(transactionId) => this.setOverheadModal(true, transactionId)}
              emptyCaption={emptyCaptions.overheads}
              iconName={icons.overheads}
              overheads={overheads?.rows}
              rows={overheadflows?.rows}
            />
          </TabPane>
          {isOnScreenDataEntryFeatureEnabled && (
            <TabPane tabId="12" className="mb-2 p-1">
              <OverheadsToolbar
                displayAddNewButton={false}
                onAddNew={() => {
                  this.setOverheadModal(true, id);
                }}
                filterClick={this.toggleFilterBox}
              />
              <OverheadsFilter filterOpen={filterOpen} groups={overheadFilters} />
              <OverheadflowLsvFeature
                editTransaction={(transactionId) => this.setOverheadModal(true, transactionId)}
                emptyCaption={emptyCaptions.overheads}
                iconName={icons.overheads}
                overheads={overheads?.rows}
                userSelectionRef={this.overheadRef}
                rows={overheadflows?.rows}
                overheadListControls={overheadListControls}
                onOverheadSave={this.onOverheadSave}
                onOverheadDelete={this.onOverheadDelete}
                overhead_groups={overhead_groups}
                overhead_types={overhead_types}
                distributions={distributions}
                properties={properties?.rows || []}
                transaction_intervals={filteredTransactionIntervals}
              />
            </TabPane>
          )}

          <TabPane tabId="2" className="mb-2 p-1">
            <BusinessExpendituresToolbar
              onAddNew={() => {
                this.setModal(true, id, 'expenditures');
              }}
            />
            <GenericLsv
              controls={modalOptions.controls}
              emptyCaption={emptyCaptions.capital_expenditures}
              iconName={icons.capital_expenditures}
              onClick={(expenditureId) => {
                this.setModal(true, expenditureId, 'expenditures');
              }}
              rows={capital_expenditures}
              totalFormattingRules={genericTotalFormattingRules}
            />
          </TabPane>
          <TabPane tabId="3" className="mb-2 p-1">
            <GenericToolbar
              onAddNew={() => {
                this.setModal(true, id, 'property_other_incomes');
              }}
              addButtonText={addButtonText.property_other_incomes}
              alertText={alertText.property_other_incomes}
            />
            <GenericLsv
              controls={modalOptions.controls}
              iconName={icons.property_other_incomes}
              emptyCaption={emptyCaptions.property_other_incomes}
              onClick={(otherIncomeId) => {
                this.setModal(true, otherIncomeId, 'property_other_incomes');
              }}
              rows={property_other_incomes}
              totalFormattingRules={genericTotalFormattingRules}
            />
          </TabPane>

          <TabPane tabId="5" className="mb-2 p-1">
            <AssetsToolbar
              onAddNewAsset={() => {
                this.setAssetsModal(true);
              }}
              onAddNewTransaction={() => {
                this.setAssetTransactionsModal(true);
              }}
              filterClick={this.toggleFilterBox}
            />
            <AssetsFilter
              filterOpen={filterOpen}
              categories={asset_categories}
              types={asset_types}
            />
            <AssetflowLsv
              rows={assetflows}
              transactions={assetTransactions?.rows}
              editAsset={(assetId) => {
                this.setAssetsModal(true, assetId);
              }}
              editTransaction={(transactionId) => {
                this.setAssetTransactionsModal(true, transactionId);
              }}
              selectedRanges={selectedRanges}
            />
          </TabPane>
          <TabPane tabId="8" className="mb-2 p-1">
            <LiabilityToolbar
              onAddNew={() => this.setLiabilityModal(true)}
              onAddNewTransaction={() => this.setLiabilityTransactionModal(true)}
              filterClick={this.toggleFilterBox}
            />
            <LiabilityFilter filterOpen={filterOpen} categories={liability_categories} />
            <LiabilityflowLsv
              rows={liabilityflows}
              transactions={liability_transactions.rows}
              editLiability={(liabilityId) => this.setLiabilityModal(true, liabilityId)}
              editTransaction={(transactionId) =>
                this.setLiabilityTransactionModal(true, transactionId)
              }
              selectedRanges={selectedRanges}
            />
          </TabPane>
          <TabPane tabId="9" className="mb-2 p-1">
            <LabourToolbar
              onAddNew={() => {
                this.setLabourModal(true, id);
              }}
              filterClick={this.toggleFilterBox}
            />
            <LabourFilter
              filterOpen={filterOpen}
              groups={labour_groups}
              categories={labour_categories}
            />
            <PageTitleH5 iconName={icons.labour} title="Paid Labour" />
            <LabourflowLsv
              controls={this.addLabourDistributionControls(controlsLabourPaid, labourflows?.paid)}
              emptyCaption={emptyCaptions.labour}
              iconName={icons.labour}
              isPaid
              labours={labours?.paid}
              rows={labourflows?.paid}
              setLabourModal={(labourId) => {
                this.setLabourModal(true, labourId);
              }}
              totalFormattingRules={labourTotalFormattingRules}
            />
            <PageTitleH5 iconName={icons.labour} title="Unpaid Labour" />
            <LabourflowLsv
              controls={this.addLabourDistributionControls(
                controlsLabourUnpaid,
                labourflows?.unpaid,
              )}
              iconName={icons.labour}
              isPaid={false}
              emptyCaption={emptyCaptions.labour}
              labours={labours?.unpaid}
              rows={labourflows?.unpaid}
              setLabourModal={(labourId) => {
                this.setLabourModal(true, labourId);
              }}
              totalFormattingRules={labourTotalFormattingRules}
            />
            <div className="d-flex justify-content-end">
              <div className="d-flex bg-transparent text-black py-2 pl-1">
                <InlineCell
                  label="Weekly Average:"
                  labelClassName="font-weight-bold"
                  value={
                    labourflows?.unpaid?.weeklyAverage
                      ? `$${labourflows.unpaid.weeklyAverage.toFixed(2)}`
                      : '-'
                  }
                  valueClassName="border border-gray"
                  wrapperClassName="mr-3 pl-3"
                />
                <InlineCell
                  label="Total Valid Weeks:"
                  labelClassName="font-weight-bold"
                  value={
                    labourflows?.unpaid?.totalValidWeeks
                      ? labourflows.unpaid.totalValidWeeks
                      : 'N/A'
                  }
                  valueClassName="border border-gray"
                  wrapperClassName="pr-2"
                />
              </div>
            </div>
            <PageTitleH5 iconName={icons.labour} title="Unpaid FTE Summary" />
            <FTESummary
              emptyCaption={emptyCaptions.labour}
              iconName={icons.labour}
              rows={fteSummary}
            />
          </TabPane>
          <TabPane tabId="10" className="mb-2 p-1">
            <BusinessLandcareIndicatorsLsv rows={[]} />
          </TabPane>
          <TabPane tabId="11" className="mb-2 p-1">
            <BusinessAnimalUnitsLsv rows={[]} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({
  assets,
  attributes,
  capital_expenditures,
  clients,
  enterprises,
  importing,
  labours,
  liabilities,
  overheads,
  properties,
  property_other_incomes,
  realm,
  farmportrait_portal
}) => ({
  assets,
  attributes,
  capital_expenditures,
  clients,
  enterprises,
  importing,
  labours,
  liabilities,
  overheads,
  properties,
  property_other_incomes,
  realm,
  portal: farmportrait_portal
});

const StoreConnectedComponent = connect(mapStoreToProps)(Business);
const FeatureFlagComponent = withFeatureFlagCheck(
  'on-screen-data-entry',
  'isOnScreenDataEntryFeatureEnabled',
  StoreConnectedComponent,
);

export default FeatureFlagComponent;
