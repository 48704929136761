
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import FormBase from 'jsx/components/core/form/components/FormBase';
import PageTitleH5 from 'jsx/components/core/form/components/PageTitleH5';
import LivestockAssumptions from 'jsx/components/manage/containers/LivestockAssumptions';
import FilterIntervalDatePicker from '../../components/FilterIntervalDatePicker';

import {
  fetchAssumedAnimalClassValueRanges,
  fetchAssumedAnimalClasses
} from 'jsx/components/manage/actions/livestock_assumptions';

import { fetchAttributes } from 'jsx/components/modules/portrait/actions/attributes';
import { fetchEnterpriseIntervalRanges, fetchEnterpriseRanges } from 'jsx/components/modules/portrait/actions/enterprises';

class BenchmarkValues extends FormBase {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
    await this.props.dispatch(fetchEnterpriseIntervalRanges());
    await this.props.dispatch(fetchEnterpriseRanges());
  }

  onIntervalDateChange = async (value) => {
    const { interval } = this.props.enterprises.selectedInterval;
    this.onIntervalChange(interval, value);
  };

  onIntervalChange = async (interval, date_value) => {
    let date;
    let intervalSelection;

    if (interval?.tag === 'all_time') {
      const allTimeRange = await this.props.dispatch(fetchAssumedAnimalClassValueRanges());
      date = moment(allTimeRange.to_date).endOf('month').toISOString(true);
      intervalSelection = { from_date: allTimeRange.from_date, transaction_date: date, interval };
    } else {
      date = this.getDateValue(interval?.tag, date_value);
      intervalSelection = { transaction_date: date, interval };
    }

    await this.props.dispatch({
      type: 'SET_ENTERPRISE_SELECTED_INTERVAL',
      payload: intervalSelection,
    });
  };

  getDateValue = (tag, value) => {
    let unitType;
    switch (tag) {
      case 'year':
      case 'half_year':
      case 'quarter': {
        unitType = 'quarter';
        break;
      }
      case 'month':
      default: {
        unitType = 'month';
      }
    }

    return moment(value).endOf(unitType).toISOString(true);
  };

  render() {
    const { transaction_intervals } = this.props.attributes;
    const { selectedInterval } = this.props.enterprises;
    const intervalOptions = isEmpty(transaction_intervals)
      ? []
      : transaction_intervals
          .filter(({ tag }) => tag !== 'day')
          .map((interval) => ({
            ...interval,
            value: interval.id,
            label: interval.name,
            tag: interval.tag,
          }));

    return (
      <div className=" h-100">
        <div className="border-bottom border-corporate bg-light p-1 rounded mt-2 d-flex justify-content-between">
          <PageTitleH5 iconName="paw" title="Livestock Assumptions" />
          <div className="mt-1">
            <FilterIntervalDatePicker
              intervalOptions={intervalOptions}
              interval={selectedInterval.interval}
              transaction_date={selectedInterval.transaction_date}
              onIntervalDateChange={this.onIntervalDateChange}
              onIntervalChange={this.onIntervalChange}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
        <div className="p-2">
          <LivestockAssumptions />
        </div>
        
      </div>
    );
  }
}

const mapStoreToProps = ({ attributes, enterprises }) => ({
  attributes,
  enterprises
});

export default connect(mapStoreToProps)(BenchmarkValues);
