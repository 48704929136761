import { useState } from 'react';
import Listview from "../../../../core/form/components/Listview";
import Icon from 'jsx/components/core/icons/Icon';

import SubscriptionIndicators from '../SubscriptionIndicators';
import AnalysisDashboardPeriodSwitch from './AnalysisDashboardPeriodSwitch';
import AnalysisDashboardPeriodCheck from './AnalysisDashboardPeriodCheck';
import AnalysisDashboardPeriodAlert from './AnalysisDashboardPeriodAlert';
import AnalysisDashboardColumnHeader from './AnalysisDashboardColumnHeader';
import AnalysisDashboardPeriodPercent from './AnalysisDashboardPeriodPercent';
import AnalysisDashboardPeriodStatus from './AnalysisDashboardPeriodStatus';
import AnalysisDashboardPeriodModal from './AnalysisDashboardPeriodModal';
import AnalysisDashboardSubscriptionModal from './AnalysisDashboardSubscriptionModal';

const AnalysisDashboardLsv = (props) => {
  const {
    rows,
    lockEditing,
    upperHeaders,
    headers
  } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [isSubscriptionModalOpen, setSubscriptionModal] = useState(false);
  const toggleSubscription = () => setSubscriptionModal(!isSubscriptionModalOpen);


  const cellContextMenu = (event) => {
    event.stopPropagation()
    console.log("show cell right click")
    event.preventDefault();

    setModal(true);
  
    return false;
  };

  const subscriptionContextMenu = (event) => {
    event.stopPropagation()
    event.preventDefault();

    setSubscriptionModal(true);
  
    return false;
  };

  const headerContextMenu = (event) => {
    event.stopPropagation()
    console.log("show header right click")
    event.preventDefault();
    return false;
  };


  const renderRows = (headers, row) => {
    let iconName = 'mask';
    let iconColour = "mr-3 "

    const tableTd = headers.map((header, index) => {
      if (header.hide) return null;
      switch (header.field) {
        case 'client': return (
          <td className={`${header.classes}`} key={index}>{row.project.org.name}</td>
        );
        case 'subscriptions': return (
          <td className={`${header.classes}`} key={index} onContextMenu={subscriptionContextMenu}><SubscriptionIndicators subscriptions={[]} /></td>
        );
        case 'advisor': return (
          <td className={`${header.classes}`} key={index}>{row?.advisor?.name}</td>
        );
        case 'rcs_status': return (
          <td className={`${header.classes}`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodStatus lockEditing={lockEditing} header={header}/></td>
        );
        case 'client_status': return (
          <td className={`${header.classes}`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodStatus lockEditing={lockEditing} header={header}/></td>
        );
        case 'alert': return (
          <td className={`${header.classes}`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodAlert lockEditing={lockEditing} /></td>
        );
        case 'completed': return (
          <td className={`${header.classes}`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodPercent lockEditing={lockEditing} header={header}/></td>
        );
        case 'dnc': return (
          <td className={`${header.classes} text-danger`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodSwitch lockEditing={lockEditing} /></td>
        );
        case 'probe': return (
          <td className={`${header.classes} text-success`} style={header.style} key={index} onContextMenu={cellContextMenu}><AnalysisDashboardPeriodCheck lockEditing={lockEditing} /></td>
        );
        default: return  (
          <td className={`${header.classes}`} key={index}>{(index === 0 ? <Icon name={iconName} className={iconColour} /> : null)}{row[header.field]}</td>
        );
      }
    });

    return tableTd;
  };

  const iconName = 'folder-user';
  const emptyCaption = 'No Client Properties found';

  const upperHeadTh = upperHeaders.map(({ caption, cells, classes }, index) => (
    <th key={index} colSpan={cells} className={classes}>
      {caption}
    </th>
  ));

  const visibleHeaders = headers.filter(header => !header.hide);
  const tableHeadTh = visibleHeaders.map((header, index) =>
    <th
      key={index}
      className={header.classes}
      style={header.style}
      title={header.title}
      onContextMenu={headerContextMenu}
      >
        <AnalysisDashboardColumnHeader header={header} />
    </th>
  );

  let tableBodyTr = (<tr></tr>);
  const haveRows = (rows && rows.length > 0);
  if (haveRows) {
    tableBodyTr = rows.map((row, index) =>
      <tr key={index} onClick={() => {}} style={{cursor: 'pointer'}}>
        {renderRows(headers, row)}
      </tr>
    );
  }

  return (
    <div className="mt-2">
      <AnalysisDashboardPeriodModal toggle={toggle} modal={modal} />
      <AnalysisDashboardSubscriptionModal toggle={toggleSubscription} modal={isSubscriptionModalOpen} />

      <Listview
        rows={rows}
        tableHeadTh={tableHeadTh}
        tableBodyTr={tableBodyTr}
        iconName={iconName}
        emptyCaption={emptyCaption}
        upperHeadTh={upperHeadTh}
        hover
        // sortType="None"
        enableStickyHeaders
      />
    </div>
  );
};

export default AnalysisDashboardLsv;
