import { controlDefaults } from '../../../core/form/lib/validateForm';

export const controls = {
  id: {
    ...controlDefaults,
    name: 'id',
    type: 'hidden',
  },
  property_id: {
    ...controlDefaults,
    // placeholder: 'Organisation',
    name: 'property_id',
    fieldName: 'property_id',
    type: 'select',
    caption: 'Property',
    validationRules: {},
    showInEditor: true,
    includeEmptyOption: false
  },
  transaction_month: {
    ...controlDefaults,
    placeholder: '',
    fieldName: 'transaction_date',
    name: 'transaction_month',
    type: 'date-month',
    caption: 'Month',
    showInEditor: false,
    showInListview: true,
    sortColumn: 'created',
  },
  transaction_interval_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_interval_id',
    fieldName: 'transaction_interval.name',
    type: 'select',
    caption: 'Transaction Interval',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  transaction_date: {
    ...controlDefaults,
    placeholder: '',
    name: 'transaction_date',
    fieldName: 'transaction_date',
    type: 'date',
    caption: 'Transaction Date',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  group_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'group_id',
    type: 'select',
    caption: 'Group',
    validationRules: {
      isRequired: false,
    },
    showInEditor: true,
    showInListview: false,
    includeEmptyOption: true,
  },
  type_id: {
    ...controlDefaults,
    placeholder: '',
    name: 'type_id',
    type: 'select',
    caption: 'Type',
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: false,
  },
  type: {
    ...controlDefaults,
    placeholder: '',
    name: 'type',
    fieldName: 'type.name',
    type: 'select',
    caption: 'Type',
    validationRules: {},
    showInEditor: false,
    showInListview: true,
    includeEmptyOption: true
  },
  total_amount: {
    ...controlDefaults,
    placeholder: '',
    name: 'total_amount',
    type: 'number',
    caption: 'Total Amount ($)',
    disabled: true,
    validationRules: {
      isRequired: true,
    },
    showInEditor: true,
    showInListview: true,
  },
  distribution_count: {
    ...controlDefaults,
    placeholder: '',
    name: 'distribution_count',
    fieldName: 'distributions',
    type: 'count',
    caption: 'Distribution #',
    disabled: true,
    validationRules: {},
    showInListview: true,
    showInEditor: false,
  },
  distributions: {
    ...controlDefaults,
    name: 'distributions',
    type: 'json',
    caption: 'Distributions',
    disabled: false,
    validationRules: {
      isArray: true,
    },
    showInListView: false,
    showInEditor: true,
  },
};
