/* eslint-disable no-param-reassign, no-plusplus */
import React from 'react';
import { connect } from 'react-redux';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { cloneDeep, noop, omit } from 'lodash';
import { withContainerError } from 'jsx/components/core/errors/ContainerError';
import FormTab from '../../../../core/form/components/FormTab';
import FormBase from '../../../../core/form/components/FormBase';
import PageTitle from '../../../../core/form/components/PageTitle';

import CroppingEnterpriseCharts from '../../components/cropping/CroppingEnterpriseCharts';
import CroppingEnterpriseToolbar from '../../components/cropping/CroppingEnterpriseToolbar';
import { fetchAttributes } from '../../actions/attributes';
import GenericLsv from '../../../../core/form/components/GenericLsv';
import {
  createCroppingTransaction,
  createCroppingValue,
  fetchCropflows,
  fetchCroppingGrazings,
  fetchCroppingSummaries,
  fetchCroppingTransaction,
  fetchCroppingTransactions,
  fetchCroppingTransfers,
  fetchCroppingValue,
  fetchCroppingValues,
  removeCroppingTransaction,
  removeCroppingValue,
  updateCroppingTransaction,
  updateCroppingValue,
} from '../../actions/cropping';
import { fetchDirectCosts } from '../../actions/directCosts';
import { controls as controlsAdjustment } from '../../forms/croppingAdjustments';
import { controls as controlsDirectCost } from '../../forms/directCosts';
import { controls as controlsGrazing } from '../../forms/croppingGrazings';
import { controls as controlsTransaction } from '../../forms/croppingTransactions';
import { controls as controlsTransfer } from '../../forms/croppingTransfers';
import { controls as controlsValue } from '../../forms/croppingValues';
import GenericModal from '../../../../core/form/components/GenericModal';
import DirectCostModal from '../DirectCostModal';
import CroppingTransferModal from './CroppingTransferModal';
import { fetchAnimalClasses } from '../../actions/animal_classes';
import CropflowLsv from '../../components/cropping/CropflowLsv';
import CroppingAdjustmentModal from './CroppingAdjustmentModal';
import CroppingGrazingModal from './CroppingGrazingModal';
import CroppingTransactionModal from './CroppingTransactionModal';
import CroppingValueModal from './CroppingValueModal';
import { strip } from '../../../../core/form/lib/fieldFormat';

class CroppingEnterprise extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      division_id: null,
      errorMessage: null,
      id: null,
      isModalOpen: false,
      isAdjustmentModalOpen: false,
      isDirectCostModalOpen: false,
      isGrazingModalOpen: false,
      isTransferModalOpen: false,
      isTransactionModalOpen: false,
      isCroppingValueModalOpen: false,
      livestockDivisionIds: [],
      modalData: null,
      modalType: null,
      showOpeningValueFields: false,
    };

    this.addDistributionControls = this.addDistributionControls.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.setModal = this.setModal.bind(this);
    this.setAdjustmentModal = this.setAdjustmentModal.bind(this);
    this.setDirectCostModal = this.setDirectCostModal.bind(this);
    this.setGrazingModal = this.setGrazingModal.bind(this);
    this.setModalOptions = this.setModalOptions.bind(this);
    this.setTransferModal = this.setTransferModal.bind(this);
    this.setTransactionModal = this.setTransactionModal.bind(this);
    this.setCroppingValueModal = this.setCroppingValueModal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.filterCroppingProductTypes = this.filterCroppingProductTypes.bind(this);
  }

  async componentDidMount() {
    this.props
      .dispatch(
        fetchAttributes({
          type: 'divisions',
          tag: ['cattle', 'crop', 'sheep', 'other_livestock'],
        }),
      )
      .then((divisions) => {
        if (divisions?.rows) {
          const crop = divisions.rows.find((division) => division.tag === 'crop');
          const livestockDivisionIds = divisions.rows
            .map((division) => {
              if (division.tag !== 'crop') {
                return division.id;
              }
              return false;
            })
            .filter(Boolean);
          this.setState({
            division_id: crop.id,
            livestockDivisionIds,
          });
        }
      });
    await this.props.dispatch(fetchAnimalClasses());
    await this.props.dispatch(fetchAttributes({ type: 'transaction_types' }));
    await this.props.dispatch(fetchAttributes({ type: 'crop_product_type' }));
  }

  componentDidUpdate(prevProps) {
    const { isActive, forceRefresh, setRefresh } = this.props;

    if (isActive && forceRefresh && setRefresh) {
      this.onRefresh();
      if (setRefresh) setRefresh(false);
    }

    const { selectedProperties: prevProperties } = prevProps.enterprises;
    const { selectedProperties } = this.props.enterprises;
    if (isActive && prevProperties !== selectedProperties) this.onRefresh();
  }

  filterCroppingProductTypes(selected_enterprise_id) {
    const { enterprises } = this.props.enterprises;
    const { cropping_product_types } = this.props.attributes;

    if (enterprises.length === 0 ) return [];

    const cropEnterprises = enterprises.rows.filter(enterprise => enterprise.division.tag === 'crop');

    const enterprise_id = (!selected_enterprise_id ? cropEnterprises[0].id : selected_enterprise_id);
    const enterprise = enterprises?.rows.find(({ id }) => id === enterprise_id);

    const tag = enterprise?.type?.tag;

    if (tag === 'sugarcane') {
      return cropping_product_types.filter(product_type => product_type.tag === 'cane');
    }

    return cropping_product_types.filter(product_type => product_type.tag !== 'cane');
  }
  
  onRefresh() {
    this.toggleTab(this.props.cropping.activeTab);
  }

  onRemove(id) {
    const { modalType } = this.state;
    let removeMethod = noop;

    switch (modalType) {
      case 'transaction': {
        removeMethod = removeCroppingTransaction;
        break;
      }
      case 'value': {
        removeMethod = removeCroppingValue;
        break;
      }
      default: {
        break;
      }
    }
    return this.props.dispatch(removeMethod(id));
  }

  stripUnitObjects = (data) => {
    const fields = Object.keys(data);
    fields.forEach((fieldIdx) => {
      if (
        typeof data[fieldIdx] === 'object' &&
        data[fieldIdx] !== null &&
        data[fieldIdx].unit_type &&
        data[fieldIdx].value
      ) {
        delete data[fieldIdx].unit_type;
      }
    });
  };

  onSave(data, isNew) {
    const { modalType } = this.state;
    let saveData = cloneDeep(data);

    if (isNew) saveData = omit(saveData, ['id']);
    this.stripUnitObjects(saveData);

    let saveMethod = noop;
    switch (modalType) {
      case 'transaction': {
        saveMethod = isNew ? createCroppingTransaction : updateCroppingTransaction;
        break;
      }
      case 'value': {
        saveMethod = isNew ? createCroppingValue : updateCroppingValue;
        break;
      }
      default: {
        break;
      }
    }

    return this.props.dispatch(saveMethod(saveData));
  }

  async onValueChange(field, row) {
    // Create/update unit value on the closing date
    const { selectedRanges, selectedProperties } = this.props.enterprises;
    const { product, enterprise, property } = row;
    const value = row[field].value === '' ? 0 : strip(row[field].value);
    const data = {
      product_id: product.id,
      enterprise_id: enterprise.id,
      property_id: property.id,
      date: selectedRanges.to_date,
      value,
    };

    const success = await this.props.dispatch(createCroppingValue(data));
    if (success) {
      const { division_id } = enterprise;
      this.props.dispatch(
        fetchCropflows({
          division_id,
          property_ids: selectedProperties,
          ...selectedRanges,
        }),
      );
      this.props.dispatch(
        fetchCroppingSummaries({
          division_id,
          property_ids: selectedProperties,
          ...selectedRanges,
        }),
      );
    }
  }

  setModalOptions(type) {
    const { division_id, modalData } = this.state;
    let { transaction_types } = this.props.attributes;
    const { cropping_product_types, divisions } = this.props.attributes;
    const { enterprises } = this.props.enterprises;
    const { selectedProperties } = this.props.cropping;

    // Get current division to check if rules apply
    const currentDivision = divisions.find(({ id }) => id === division_id);

    // Division level filter for transaction types
    transaction_types = transaction_types.filter(
      ({ tag }) => !currentDivision?.rules?.denyTransactionTypes?.includes(tag),
    );

    const division_enterprises = enterprises?.rows
      ? enterprises.rows.filter((enterprise) => enterprise.division_id === division_id)
      : [];

    let options;
    switch (type) {
      case 'transaction': {
        const controls = cloneDeep(controlsTransaction);

        if (modalData) {
          // Set user entered fields for amount/quantity controls
          controls.amount.user_entry = true;
          controls.quantity_kg.user_entry = true;
        }

        options = {
          controls,
          iconName: 'handshake',
          options: {
            enterprise_id: division_enterprises,
            product_id: cropping_product_types,
            property_id: selectedProperties,
            transaction_type_id: transaction_types.filter(
              ({ tag }) =>
                tag !== 'adjustment' && tag !== 'agistment_income' && tag !== 'agistment_expense',
            ),
          },
          title: 'Sale/Purchase',
        };
        break;
      }
      case 'value': {
        const controls = cloneDeep(controlsValue);
        options = {
          controls,
          iconName: 'handshake',
          options: {
            enterprise_id: division_enterprises,
            product_id: cropping_product_types,
          },
          title: 'Value',
        };
        break;
      }
      default: {
        options = {
          iconName: 'handshake',
          controls: {},
          options: {},
          title: '',
        };
        break;
      }
    }

    return options;
  }

  toggleTab(tab) {
    // Update active tab in reducer
    if (this.props.cropping.activeTab !== tab) {
      this.props.dispatch({ type: 'SET_CROPPING_ACTIVE_TAB', payload: tab });
    }

    let { modalType } = this.state;
    const { division_id } = this.state;
    const { selectedRanges, selectedProperties } = this.props.enterprises;

    switch (tab) {
      case 'cropflows': {
        this.props.dispatch(
          fetchCropflows({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        break;
      }
      case 'values': {
        this.props.dispatch(
          fetchCroppingValues({
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        modalType = 'value';
        break;
      }
      case 'purchases': {
        this.props.dispatch(
          fetchCroppingTransactions('purchases', {
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            transaction_type_tag: 'purchase',
          }),
        );
        modalType = 'transaction';
        break;
      }
      case 'sales': {
        this.props.dispatch(
          fetchCroppingTransactions('sales', {
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            transaction_type_tag: 'sale',
          }),
        );
        modalType = 'transaction';
        break;
      }
      case 'adjustments': {
        this.props.dispatch(
          fetchCroppingTransactions('adjustments', {
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
            transaction_type_tag: 'adjustment',
          }),
        );
        break;
      }
      case 'transfers': {
        this.props.dispatch(
          fetchCroppingTransfers({
            ...selectedRanges,
            division_id,
            property_ids: selectedProperties,
          }),
        );
        break;
      }
      case 'grazings': {
        this.props.dispatch(
          fetchCroppingGrazings({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        break;
      }
      case 'costs': {
        this.props.dispatch(fetchAttributes({ type: 'cost_categories', parent_id: division_id }));
        this.props.dispatch(fetchAttributes({ type: 'transaction_intervals' }));
        this.props.dispatch(
          fetchDirectCosts({
            division_id,
            property_ids: selectedProperties,
            ...selectedRanges,
          }),
        );
        break;
      }
      default: {
        break;
      }
    }

    if (selectedRanges?.from_date)
      this.props.dispatch(
        fetchCroppingSummaries({
          division_id,
          property_ids: selectedProperties,
          ...selectedRanges,
        }),
      );

    this.setState({ modalType });
  }

  setAdjustmentModal(isAdjustmentModalOpen, id = null, showOpeningValueFields = false) {
    this.setState({
      id,
      isAdjustmentModalOpen,
      showOpeningValueFields,
    });
  }

  async setTransactionModal(isTransactionModalOpen, id = null) {
    let modalData;

    if (id) modalData = await this.props.dispatch(fetchCroppingTransaction(id));

    this.setState({
      id,
      isTransactionModalOpen,
      modalType: 'transaction',
      modalData,
    });
  }

  setDirectCostModal(isDirectCostModalOpen, id = null) {
    this.setState({
      id,
      isDirectCostModalOpen,
    });
  }

  setGrazingModal(isGrazingModalOpen, id = null) {
    this.setState({
      id,
      isGrazingModalOpen,
    });
  }

  setTransferModal(isTransferModalOpen, id = null) {
    this.setState({
      id,
      isTransferModalOpen,
    });
  }

  async setCroppingValueModal(isCroppingValueModalOpen, id = null) {
    let modalData;
    if (id) {
      modalData = await this.props.dispatch(fetchCroppingValue(id));
    }
    this.setState({
      isCroppingValueModalOpen,
      modalData,
      modalType: 'value',
      id,
    });
  }

  async setModal(isModalOpen, modalType, modalId) {
    let modalData;
    if (isModalOpen) {
      switch (modalType) {
        case 'transaction': {
          if (modalId) modalData = await this.props.dispatch(fetchCroppingTransaction(modalId));
          break;
        }
        default: {
          break;
        }
      }
    }

    const state = {
      isModalOpen,
      modalData,
    };
    if (modalType) state.modalType = modalType;
    this.setState(state);
  }

  addDistributionControls = (controls, rows) => {
    const { distribution_default } = controls;
    const updatedControls = cloneDeep(controls);

    // Check rows exist
    if (rows.rows?.length > 0) {
      // Check distributions exist and are populated
      rows.rows.forEach((row) => {
        if (row.distributions?.length > 0) {
          // Iterate through each distribution
          row.distributions.forEach((distribution, idx) => {
            // Add distribution control
            const enterpriseName = `${distribution.enterprise.name
              .toString()
              .toLowerCase()}_distribution`;

            updatedControls[enterpriseName] = {
              ...distribution_default,
              caption: `${distribution.enterprise.name} (%)`,
              fieldName: `distributions.${(idx++).toString()}.distribution_pcnt`,
              formattingRules: {
                asPercentage: true,
                includeDecimals: true,
                includePercentageSign: true,
              },
              name: `${enterpriseName}`,
              showInListview: true,
            };
          });
        }
      });
    }

    return updatedControls;
  };

  render() {
    const {
      division_id,
      id,
      isAdjustmentModalOpen,
      isDirectCostModalOpen,
      isGrazingModalOpen,
      isModalOpen,
      isTransferModalOpen,
      isTransactionModalOpen,
      isCroppingValueModalOpen,
      livestockDivisionIds,
      modalData,
      modalType,
      showOpeningValueFields,
    } = this.state;
    const {
      activeTab,
      adjustments,
      cropflows,
      grazings,
      purchases,
      responseMessage,
      sales,
      transfers,
      values,
    } = this.props.cropping;
    const { direct_costs } = this.props.direct_costs;
    const title = 'Cropping Enterprises';
    const iconName = 'seedling';

    const modalOptions = this.setModalOptions(modalType);

    return (
      <div className="p-0 h-100">
        <PageTitle title={title} iconName={iconName} />
        <CroppingEnterpriseCharts />
        <CroppingEnterpriseToolbar
          setModal={this.setModal}
          setAdjustmentModal={this.setAdjustmentModal}
          setDirectCostModal={this.setDirectCostModal}
          setGrazingModal={this.setGrazingModal}
          setTransferModal={this.setTransferModal}
          setTransactionModal={this.setTransactionModal}
          onRefresh={this.onRefresh}
        />
        <CroppingAdjustmentModal
          division_id={division_id}
          id={id}
          isNew={id === null}
          isOpen={isAdjustmentModalOpen}
          onRefresh={this.onRefresh}
          setModal={this.setAdjustmentModal}
          showOpeningValueFields={showOpeningValueFields}
          filterCroppingProductTypes={this.filterCroppingProductTypes}
        />
        <CroppingTransferModal
          division_id={division_id}
          id={id}
          isNew={id === null}
          isOpen={isTransferModalOpen}
          livestockDivisionIds={livestockDivisionIds}
          onRefresh={this.onRefresh}
          setModal={this.setTransferModal}
          filterCroppingProductTypes={this.filterCroppingProductTypes}
        />
        <CroppingGrazingModal
          division_id={division_id}
          id={id}
          isNew={id === null}
          isOpen={isGrazingModalOpen}
          livestockDivisionIds={livestockDivisionIds}
          onRefresh={this.onRefresh}
          setModal={this.setGrazingModal}
          filterCroppingProductTypes={this.filterCroppingProductTypes}
        />
        <DirectCostModal
          division_id={division_id}
          id={id}
          isNew={id === null}
          isOpen={isDirectCostModalOpen}
          onRefresh={this.onRefresh}
          setModal={this.setDirectCostModal}
        />
        <CroppingTransactionModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          data={modalData}
          iconName={modalOptions.iconName}
          isOpen={isTransactionModalOpen}
          modalTitle={modalOptions.title}
          onClose={this.onRefresh}
          onRemove={this.onRemove}
          onSave={this.onSave}
          responseMessage={responseMessage}
          setModal={this.setTransactionModal}
          filterCroppingProductTypes={this.filterCroppingProductTypes}
        />
        <CroppingValueModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          data={modalData}
          iconName={modalOptions.iconName}
          isOpen={isCroppingValueModalOpen}
          modalTitle={modalOptions.title}
          onClose={this.onRefresh}
          onRemove={this.onRemove}
          onSave={this.onSave}
          responseMessage={responseMessage}
          setModal={this.setCroppingValueModal}
        />
        <GenericModal
          controls={modalOptions.controls}
          controlOptions={modalOptions.options}
          data={modalData}
          iconName={modalOptions.iconName}
          isOpen={isModalOpen}
          modalTitle={modalOptions.title}
          onClose={this.onRefresh}
          onRemove={this.onRemove}
          onSave={this.onSave}
          responseMessage={responseMessage}
          setModal={this.setModal}
        />

        <Nav tabs className="mt-2">
          <FormTab
            caption="Cropflow"
            tabId="cropflows"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab caption="Values" tabId="values" activeTab={activeTab} toggle={this.toggleTab} />
          <FormTab
            caption="Purchases"
            tabId="purchases"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab caption="Sales" tabId="sales" activeTab={activeTab} toggle={this.toggleTab} />
          <FormTab
            caption="Adjustments"
            tabId="adjustments"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Transfers"
            tabId="transfers"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Grazings"
            tabId="grazings"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
          <FormTab
            caption="Direct Costs"
            tabId="costs"
            activeTab={activeTab}
            toggle={this.toggleTab}
          />
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="cropflows" className="mb-2 p-1">
            <CropflowLsv rows={cropflows} onValueChange={this.onValueChange} />
          </TabPane>
          <TabPane tabId="values" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No values found"
              onClick={(valueId) => {
                this.setCroppingValueModal(true, valueId);
              }}
              rows={values}
            />
          </TabPane>
          <TabPane tabId="purchases" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No purchases found"
              onClick={(purchaseId) => {
                this.setTransactionModal(true, purchaseId);
              }}
              rows={purchases}
            />
          </TabPane>
          <TabPane tabId="sales" className="mb-2 p-1">
            <GenericLsv
              controls={modalOptions.controls}
              iconName={modalOptions.iconName}
              emptyCaption="No sales found"
              onClick={(saleId) => {
                this.setTransactionModal(true, saleId);
              }}
              rows={sales}
            />
          </TabPane>
          <TabPane tabId="adjustments" className="mb-2 p-1">
            <GenericLsv
              controls={controlsAdjustment}
              iconName="handshake"
              emptyCaption="No adjustments found"
              onClick={(adjustmentId) => {
                this.setAdjustmentModal(true, adjustmentId);
              }}
              rows={adjustments}
            />
          </TabPane>
          <TabPane tabId="transfers" className="mb-2 p-1">
            <GenericLsv
              controls={controlsTransfer}
              iconName="handshake"
              emptyCaption="No transfers found"
              onClick={(transferId) => {
                this.setTransferModal(true, transferId);
              }}
              rows={transfers}
            />
          </TabPane>
          <TabPane tabId="grazings" className="mb-2 p-1">
            <GenericLsv
              controls={controlsGrazing}
              iconName="handshake"
              emptyCaption="No grazings found"
              onClick={(grazingId) => {
                this.setGrazingModal(true, grazingId);
              }}
              rows={grazings}
            />
          </TabPane>
          <TabPane tabId="costs" className="mb-2 p-1">
            <GenericLsv
              controls={this.addDistributionControls(controlsDirectCost, direct_costs)}
              iconName="handshake"
              emptyCaption="No direct costs found"
              onClick={(costId) => {
                this.setDirectCostModal(true, costId);
              }}
              rows={direct_costs}
              totalFormattingRules={{
                includeDollarSign: true,
                includeCommas: true,
              }}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStoreToProps = ({ animal_classes, attributes, cropping, direct_costs, enterprises }) => ({
  animal_classes,
  attributes,
  cropping,
  direct_costs,
  enterprises,
});

export default connect(mapStoreToProps)(withContainerError(CroppingEnterprise));
