import { useState } from 'react';
import { Progress} from 'reactstrap';

const AnalysisDashboardPeriodPercent = (props) => {
  const { header, lockEditing } = props;

  // Random
  const [value, setValue] = useState(Math.floor(Math.random() * 100));
  const [className, setClasses] = useState('text-primary');
  const [colour, setColour] = useState((value < 50 ? 'orange' : 'primary'));

  const handleChange = () => {
    if (lockEditing) return false;

    const newValue = value + 5;

    if (newValue < 50) setColour('orange');
    if (newValue >=50) setColour('primary');

    if (newValue >= 100) {
      setClasses('bg-success text-white');
      setColour('success');
      setValue(100);
      return false;
    }

    setValue(newValue);
  };

  let progressCaption = `${value}%`;
  if (value === 100) progressCaption = 'Done!';

  return (
    <>
      {!header.showIcon && (
        <div className={className} onClick={handleChange}>{value}%</div>
      )}

      {header.showIcon && (
        <Progress color={colour} style={{minWidth: 100}} value={value} onClick={handleChange}>{progressCaption}</Progress>
      )}
    </>
  );
};

export default AnalysisDashboardPeriodPercent;
