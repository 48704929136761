import { connect } from 'react-redux';
import {
  Row,
  Col
} from 'reactstrap';
import { updateControls, validateChange } from 'jsx/components/core/form/lib/validateForm';
import FormBase from '../../../core/form/components/FormBase';
import { controls as profileControls} from '../forms/profile';
import FormInput from '../../../core/form/components/FormInput';
import FormInputSelect from '../../../core/form/components/FormInputSelect';


import { fetchProperties } from '../../projects/actions/properties';
import { updateOrg } from '../../../manage/actions';

class Profile extends FormBase {
  constructor(props) {
    super(props);

    this.state = ({
      errorMessage: null,
      expandMap: false,
      showMiniMap: true,
      controls: profileControls,
      backlink: '/home/portal',
      mapSources: [],
      data: {}
    });

    this.onSave = this.onSave.bind(this);
  }

  async componentDidUpdate() {
    let {
      data,
      controls
    } = this.state;

    let {properties, fetching} = this.props.properties;

    if (this.props.data.id !== data.id && !fetching) {
      data =  this.props.data;
      controls = updateControls(controls, data);
  
      if (properties.rows.length === 0) properties = await this.props.dispatch(fetchProperties({ filter_org_id: data.id }));
      const noneSelected = [{id: null, name: 'None Selected'}];
      controls.default_property_id.options = noneSelected.concat(properties.rows.map(property => ({id: property.id, name: property.name})));

      this.setState({
        data,
        controls
      });
    }
  }

  handleChange(event) {
    const { data } = this.state;

    const controls = validateChange(event, this.state.controls);

    data.default_property_id = event.target.value;
    this.props.dispatch(updateOrg(data));

    this.setState({
      controls,
      formChanged: true,
    });
  }

  render() {
    const {
      controls
    } = this.state;
    const { properties } = this.props.properties;

    return (
      <Row>
        <Col sm={4}>
          <FormInput handleChange={this.handleChange} control={controls.name} />
          {properties.rows.length > 1 && (
            <FormInputSelect handleChange={({ value, label }) => this.handleChange({target: {value, label, name: 'default_property_id'}  })} control={controls.default_property_id} />
          )}
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = (store) => ({
  clients: store.clients,
  properties: store.properties
});

export default connect(mapStoreToProps)(Profile);
